export const CITY_COOKIE_NAME = 'selected_city';
export const DEFAULT_CITY_ID = 1;
export interface CityListResponseData {
  result: number;
  data: CityItem[];
}
export interface CityItem {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  is_default: number;
}
