import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'cstc-cinema-schedule',
  templateUrl: './cinema-schedule.component.html',
  styleUrls: ['./cinema-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class CinemaScheduleComponent implements OnInit {
  loaders = Array.from({ length: 4 });

  constructor() { }

  ngOnInit(): void {
  }

}
