import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { PageData } from '../../../../../services/page/page.model';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'cstc-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterModule
  ]
})
export class NewsCardComponent implements OnInit {

  @Input() isVertical: boolean = false;

  @Input() item!: PageData;

  @HostBinding( 'class.vertical' ) get isVerticalClass(){
    return this.isVertical;
  };

  @HostBinding( 'class.horizontal' ) get isHorizontalClass(){
    return !this.isVertical;
  };

  constructor() { }

  ngOnInit(): void {
  }

}
