import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Subject, takeUntil, tap } from 'rxjs';
import { KIOSK_LOCAL_STORAGE_LOCATION_KEY } from 'src/app/models/app.model';
import { ButtonComponent } from 'src/app/modules/shared/components/button/button.component';
import { SvgIconDirective } from 'src/app/modules/shared/directives/svg-icon.directive';
import { CityItem } from 'src/app/services/city/city.model';
import { CityService } from 'src/app/services/city/city.service';
import { DataService } from 'src/app/services/data/data.service';
import { IdleService } from 'src/app/services/idle.service';
import { Theatre } from 'src/app/services/schedule/schedule.model';
export interface KioskSavedConfig {
  city: CityItem;
  cinema: Theatre;
}
@Component({
  selector: 'cstc-cinema-select',
  templateUrl: './cinema-select.component.html',
  styleUrls: ['./cinema-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    SvgIconDirective,
  ]
})
export class CinemaSelectComponent implements OnInit, OnDestroy {

  citySvc = inject(CityService);
  dataSvc = inject(DataService);
  formBuilder = inject(FormBuilder);
  cdr = inject(ChangeDetectorRef);
  router = inject(Router);
  idleSvc = inject(IdleService);

  form: FormGroup = this.formBuilder.group({
    city: ['', Validators.required],
    cinema: ['', Validators.required]
  });
  // Получить список городов из CityService
  cityList = this.citySvc.cityList;
  cityIsSelected = signal(false);
  // Локальная переменная для хранения списка кинотеатров
  theatres: Theatre[] = [];
  // Переменная для показа состояния загрузки
  isLoading = toSignal(this.dataSvc.loading$);
  showDialog = signal(false);
  destroy$ = new Subject<void>();

  // Подписка на изменения списка кинотеатров
  theatreList$ = this.dataSvc.theatres$.pipe(
    tap(value => {
      if (value && value.length === 1) {
        this.form.patchValue({ cinema: value[0].id })
      }
      // Сохраняем список кинотеатров для использования в методах и геттерах
      this.theatres = value || [];
    })
  )


  ngOnInit(): void {
    // Загрузка сохранённых данных из localStorage
    const savedSelection = localStorage.getItem(KIOSK_LOCAL_STORAGE_LOCATION_KEY);
    if (savedSelection) {
      const selectedData = JSON.parse(savedSelection) as KioskSavedConfig;
      if(selectedData.cinema.uid) {
        this.runKioskStartPage(selectedData.cinema.uid);
      } else {
        this.form.patchValue({
          city: selectedData.city.id,
          cinema: selectedData.cinema.id
        });
        this.cityIsSelected.set(true);
      }
    } else {
      // Инициализация списка городов
      this.citySvc.initCityList();

      // Следим за изменениями поля формы с выбором города (выбран город или нет)
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(formValue => {
          const cityId = Number(formValue.city);
          if (cityId) {
            this.citySvc.setCity(cityId);
            this.cityIsSelected.set(true);
          }
        })
      }
  }

  /**
   * метод, который позволяет настраивать шаблон, чтоб не писать миллион букв
   * принимает значения true/false в зависимости от состояния формы invalid/valid
   */
  invalidField(name: string) {
    return this.form.get(name)?.invalid && this.form.get(name)?.touched;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Подтвердить сделанный выбор и перейти по ссылке ['/cinema', selectedCinema.uid] к выбранному кинотеатру
  isAgreed() {
    const selectedCinemaId = +this.form.value.cinema;
    const selectedCityId = String(this.form.value.city);
    const selectedCinema = this.theatres.find(cinema => cinema.id === selectedCinemaId);
    const selectedCity = this.cityList().find(city => {
      return String(city.id) === selectedCityId;
    });
    // Сохранение данных в localStorage
    if (selectedCity && selectedCinema) {
      const selectedData:KioskSavedConfig = {
        city: selectedCity,
        cinema: selectedCinema
      }
      localStorage.setItem('kioskLocation', JSON.stringify(selectedData));
    }

    if (selectedCinema && selectedCinema.uid) {
      // Сохраняем в сервис значение маршрута для выбранного при старте кинотеатра
      this.runKioskStartPage(selectedCinema.uid);
    }
  }
  runKioskStartPage(cinemaUID: string) {
    this.idleSvc.setSelectedCinemaUid(cinemaUID);
    this.router.navigate(['/cinema', cinemaUID]);
  }
  // Закрыть диалоговое окно
  isCanceled() {
    this.showDialog.set(false);
  }

  onSubmit(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
    }
    if (this.form.invalid) {
      // Отмечаем все контролы формы как touched
      this.form.markAllAsTouched();
      return;
    }
    this.showDialog.set(true);
  }

  // Геттер для названия выбранного города
  get selectedCityName(): string {
    const selectedCityId = String(this.form.value.city);
    const selectedCity = this.cityList().find(city => {
      return String(city.id) === selectedCityId;
    });
    return selectedCity ? selectedCity.name : '';
  }

  // Геттер для названия выбранного кинотеатра
  get selectedCinemaName(): string {
    const selectedCinemaId = String(this.form.value.cinema);
    const selectedCinema = this.theatres.find(cinema => {
      return String(cinema.id) === selectedCinemaId;
    });
    return selectedCinema ? selectedCinema.name : '';
  }
}
