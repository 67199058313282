import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class HashService {

  constructor() { }

  /**
   * Calculates SHA-512 hash from the given string.
   * The string is converted to lowercase, spaces are removed,
   * and non-Cyrillic characters are removed before hashing.
   *
   * @param value The input string
   * @returns SHA-512 hash of the processed string
   */
  calculateSha512Hash(value: string): string {
    // Remove spaces from the input string
    const noSpaces = value.replace(/\s+/g, '');

    // Convert the string to lowercase
    const lowercaseString = noSpaces.toLowerCase();

    // Remove characters not in the range [а-яё]
    const filteredString = lowercaseString.replace(/[^а-яё]/g, '');

    // Calculate SHA-512 hash of the filtered string
    const hash = CryptoJS.SHA512(filteredString);

    // Return the hash as a hexadecimal string
    return hash.toString(CryptoJS.enc.Hex);
  }
}
