import { computed, DestroyRef, effect, inject, Injectable, PLATFORM_ID, signal, untracked } from '@angular/core';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, map, Subject, switchMap, tap, catchError, interval, delayWhen } from 'rxjs';
import { Film, ScheduleFormat, ScheduleData, ScheduleResponse, ScheduleItem, Theatre, Schedule, ScheduleType } from './schedule.model';
import { CityService } from 'src/app/services/city/city.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { GeoService } from '../geo/geo.service';
import { ScheduleSession } from 'src/app/services/schedule/schedule.model';
import { environment } from 'src/environments/environment';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ENVIRONMENT_CONFIG } from 'src/app/tokens/environment-config.token';
import { FilmFilters } from 'src/app/models/app.model';
import { injectQueryParams } from 'ngxtension/inject-query-params';
import { isPlatformBrowser } from '@angular/common';
import { CstcStore } from 'src/app/store/state.store';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  private state = inject(CstcStore);
  private platformId = inject(PLATFORM_ID);
  router = inject(Router);
  private appConfig = inject(ENVIRONMENT_CONFIG);
  private baseUrl = this.appConfig.baseUrl;
  destroyRef = inject(DestroyRef);
  private citySvc = inject(CityService);
  private http = inject(HttpClient);
  private route = inject(ActivatedRoute);
  private geo = inject(GeoService);

  readonly loading$ = new BehaviorSubject<boolean>(true);
  readonly scheduleUpdate$ = new BehaviorSubject<boolean>(true);
  readonly error$ = new Subject<any>();
  readonly film$ = new BehaviorSubject<Film | false>(false);
  readonly theatre$ = new BehaviorSubject<Theatre | false>(false);



  readonly dates = signal<string[]>([]);
  readonly schedule = signal<ScheduleItem[]>([]);
  readonly location = toSignal(this.geo.location$);

  queryParams = injectQueryParams();

  dateSelected = computed(() => {
    const {date}  = this.queryParams();
    return date;
  });
  private type!: ScheduleType;
  updateScheduleInterval = environment.timers.updateSchedule * 1000;
  loading = toSignal(this.loading$, { initialValue: true });
  readonly scheduleFiltered = computed(()=>{
    const location = this.location();
    const schedule = this.schedule();
    const date = this.dateSelected();

    if(!date||!schedule) {
      return [];
    }
    let tmpData: ScheduleItem[] = [...schedule];

    if (location?.latitude && location.longitude) {
      tmpData = tmpData.map((item) => {
        if (item.theatre) {
          item.theatre.distance = this.geo.haversineDistance(item.theatre.latitude, item.theatre.longitude, location.latitude, location.longitude);
        }
        return item;
      }).sort(this.compareFn);
    }
    return tmpData.reduce((items: ScheduleItem[], item) => {
      const tmpItem: ScheduleItem = { ...item };
      if (tmpItem.formats.length > 0) {
        tmpItem.formats = tmpItem.formats.reduce((formats: ScheduleFormat[], format) => {
          const tmpFormat: ScheduleFormat = { ...format };
          if (tmpFormat.sessions.length > 0) {
            tmpFormat.sessions = tmpFormat.sessions.filter(session => session.business_date === date).sort(this.compareFnTime);
            if (tmpFormat.sessions.length) {
              formats.push(tmpFormat);
            }
          }
          return formats;
        }, []);
        if (tmpItem.formats.length) {
          items.push(tmpItem);
        }
      }
      return items;
    }, []);
  });
  startBackgroundUpdate() {
    this.scheduleUpdate$.next(false);
    interval(this.updateScheduleInterval).pipe(
      switchMap(() => combineLatest([
        this.route.params.pipe(
          map(params => params['id']),
          filter(id => !!id),
        ),
        this.citySvc.filteredCity$.pipe(
          filter(cityId => !!cityId)
        )
      ])),
      switchMap(([filmId, cityId]) => {
        return this.getData(filmId, cityId).pipe(
          catchError(() => {
            // console.log('Error fetching data');
            this.error$.next('Не удалось обновить данные расписания');
            return [];
          })
        )
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(response => {
      this.scheduleUpdate$.next(false);
      // console.log('Data received:', data);
      if (response && response.data) {
        this.setSchedule(this.type, response.data.schedule, this.appConfig.filters || false)

      }
    });
  }
  init(type: ScheduleType,id:number) {
    this.type = type;
    this.state.setScheduleType(type);
    this.citySvc.filteredCity$.pipe(
      distinctUntilChanged()
    ).pipe(
      takeUntilDestroyed(this.destroyRef), // Unsubscribe on destroy
      tap(() => {
        this.error$.next(undefined); // Reset error on each change
        this.scheduleUpdate$.next(true); // Trigger schedule update signal
      }),
      filter((cityId) => cityId !== null), // Proceed only if cityId is valid
      switchMap((cityId) => this.getData(id, cityId as number)), // Load data
      map(response => response?.data) // Instead of pluck('data'), safe access
    ).subscribe((data: ScheduleData | undefined) => {
      if (data) {
        // Proper checks and defaults
        this.film$.next(data.film ?? false);
        this.theatre$.next(data.theatre ?? false);
        this.setSchedule(type, data.schedule, this.appConfig.filters || false);
      } else {
        this.error$.next('Не удалось загрузить данные сеанса, попробуйте позже');
      }
      // Stop loading indicator
      this.loading$.next(false);
    });
  }
  /**
   * Sets and filters schedule with recalculated dates based on filters.
   *
   * @param type - Schedule type (e.g., 'film' or other)
   * @param schedule - Schedule data or false if none
   * @param filters - Film filters or false
   */
  setSchedule(type: ScheduleType, schedule: Schedule | false, filters: FilmFilters | false): void {
    // Step 1: If schedule is false, reset and exit early
    if (!schedule) {
      this.schedule.set([]);
      this.dates.set([]);
      return;
    }

    // Step 2: If no filters or type is 'film', use schedule as is
    if (!filters || type === 'film') {
      this.schedule.set(schedule.items);
      this.dates.set(schedule.dates);
      return;
    }
    // Step 3: Apply filtering by sale if provided
    let filteredItems = schedule.items;

    // Filter by film.sale if filters.sale exists
    if (filters.sale && Array.isArray(filters.sale) && filters.sale.length > 0) {
      filteredItems = filteredItems.filter(item =>
        item.film?.sale !== undefined && filters.sale.includes(item.film.sale)
      );
    }

    // Step 4: Recalculate available dates from filtered items' sessions
    const filteredDates = Array.from(
      new Set(
        filteredItems.flatMap(item =>
          item.formats.flatMap(format =>
            format.sessions.map(session => session.business_date) // collect all session dates
          )
        )
      )
    ).sort(); // Optional sort if chronological order is desired

    // Step 5: Push filtered items and dates
    this.schedule.set(filteredItems);
    this.dates.set(filteredDates);
  }


  compareFnTime = (a: ScheduleSession, b: ScheduleSession) => {
    if (!a.showtime || !b.showtime) {
      return 0;
    }
    const first = new Date(a.showtime);
    const second = new Date(b.showtime);
    if (first < second) {
      return -1;
    } else if (first > second) {
      return 1;
    } else {
      return 0;
    }
    // return 0;
  };
  compareFn = (a: ScheduleItem, b: ScheduleItem) => {
    if (a.theatre && b.theatre) {
      if (!a.theatre.distance || !b.theatre.distance) {
        return 0;
      }
      if (a.theatre.distance < b.theatre.distance)
        return -1;
      if (a.theatre.distance > b.theatre.distance)
        return 1;
      return 0;
    }
    if (a.film && b.film) {
      if (!a.film.ordering || !b.film.ordering) {
        return 0;
      }
      if (a.film.ordering < b.film.ordering)
        return -1;
      if (a.film.ordering > b.film.ordering)
        return 1;
      return 0;
    }
    return 0;
  };
  getData(id: number, cityId: number) {
    return this.http.get<ScheduleResponse>(
      `${this.baseUrl}/${this.type}/${id}?city_id=${cityId}`,
    );
  }
  setDate(newDate: string | undefined) {
    this.scheduleUpdate$.next(true);
    const currentDate = this.dateSelected();
    const allDates = this.dates();
    if (!allDates.length) return;
    const selectedDate: string = (newDate === undefined || allDates.indexOf(newDate) === -1) ? allDates[0] : newDate;
    if (selectedDate !== currentDate) {
      this.setDateRoute(selectedDate);
    }
  }
  onDateChange = effect(()=>{
    const date = this.dateSelected();
    const dates = this.dates();
    if(dates) {
      untracked(() => {
        if(!date) {
          this.setDate(dates[0])
        } else {
          this.scheduleUpdate$.next(false);
        }
      });
    }
  });
  setDateRoute(date: string): string {
    const currentParams = this.route.snapshot.queryParams;
    // SSR-safe scroll position handling
    const scrollPosition = isPlatformBrowser(this.platformId)
      ? window.scrollY || window.pageYOffset
      : 0;

    this.router.navigate([],
      {
        queryParams: { ...currentParams, date },
        replaceUrl: true,
        skipLocationChange: false,
        state: {
          preventScroll: true,
          scrollPosition
        }
      }
    );

    return date;
  }
  filtering$ = this.scheduleUpdate$.pipe(
    distinctUntilChanged(),
    delayWhen(processing => {
      return processing === false ? interval(1000) : interval(0);
    })
  );
  filtering = toSignal(this.filtering$, { initialValue: true });
}
