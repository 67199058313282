import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'cstc-events-skeleton',
  standalone: true,
  imports: [],
  templateUrl: './events-skeleton.component.html',
  styleUrl: './events-skeleton.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsSkeletonComponent {
  loadedCards = Array.from({ length: 4 });
}
