@let film = film$|async;
@if (loading()===false && film !== false) {
    <ng-container *ngIf="film">
        <div class="trailer-banner" [ngClass]="{mobileApp: isMobileApp()}">
            <div class="trailer-overlay" *ngIf="!isMobileApp()"></div>
            <!-- <article> -->
                <div class="trailer-btn-holder" *ngIf="film.media.trailer||film.media.video">
                    <a (click)="movieTrailerPopupSvc.open($event,film)" href="#" cstc-button="secondary">трейлер</a>
                </div>
            <!-- </article> -->
            <div class="trailer-poster placeholder">
                @if (imageLoaded()===false) {
                    <div class="img-placeholder">
                        <div class="loader"></div>
                    </div>
                }
                <img
                    [src]="film.media.placeholder?film.media.placeholder:'/assets/img/placeholders/film_placeholder.png'"
                    alt=""
                    (load)="imageLoaded.set(true)"
                    (error)="imageLoaded.set(true)"
                    [ngStyle]="{'display':imageLoaded()?'block':'none'}"
                >
            </div>
        </div>
        <section class="film-info-holder">
            <div class="film-info-bg">
                <div class="film-info-bg-overlay"></div>
                <img [src]="film.media.placeholder?film.media.placeholder:'/assets/img/placeholders/film_placeholder.png'" alt="">
            </div>
            <article class="film-info-wrapper">
                <div class="film-info">
                    <div class="movie-poster">
                        <img [src]="film.media.poster_vertical?film.media.poster_vertical:'/assets/img/placeholders/film_poster_placeholder.jpg'" alt="">
                    </div>
                    <div class="movie-info-wrapper">
                        <h2 adjustFont>{{ film.name }}</h2>
                        <div class="movie-genre">
                            <div
                            *ngIf="film.duration"
                            class="movie-duration"
                            >
                                <p>{{ film.duration | minToMh }}</p>
                            </div>
                            <div class="movie-category" *ngIf="film.genres">
                                <p>{{ film.genres.join(', ') }}</p>
                                <span class="movie-age">{{film.age_restriction}}+</span>
                            </div>
                        </div>
                    </div>
                    <div class="pushkin-label" *ngIf="film.is_pushkin===true">
                        <span class="svg" iconName="pushkin-label"></span>
                        <p>Доступна оплата Пушкинской картой</p>
                        <div class="pushkin-label-bg"></div>
                    </div>
                </div>
            </article>
        </section>
        <cstc-schedule></cstc-schedule>
        <article>
            <div class="movie-about-block">
                <div class="movie-description">
                    <h4>о фильме</h4>
                    <div [innerHtml]="film.description"></div>
                </div>
                <div class="movie-director">
                    <h4>Режиссер</h4>
                    <p>{{ film.director }}</p>
                </div>
                <div *ngIf="film.actors" class="movie-actors">
                    <h4>актеры</h4>
                    <p>{{film.actors}}</p>
                </div>
                <div *ngIf="film.production" class="movie-country">
                    <p>{{film.production}}&nbsp;<span *ngIf="film.year">&#10625;&nbsp;{{film.year}}</span></p>
                </div>
            </div>
        </article>
    </ng-container>
}@else {
    <cstc-film-skeleton/>
}

<cstc-banner type="bottom"></cstc-banner>
