<nav class="menu-wrapper">
  <ul class="menu-list" [ngClass]="{ hide: openMenu() === true }" [class.isStandalone]="isStandalone()">
    <li class="menu-item">
      <a
        [routerLink]="['/']"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        class="menu-item-link"
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill=""
          xmlns="http://www.w3.org/2000/svg"
          class="menu-item-icon"
        >
          <path
            d="M23.9145 18.6083C23.3177 18.6802 22.6712 18.7161 21.9635 18.7002C21.9597 18.7002 21.952 18.7002 21.9444 18.7002C19.6606 18.5963 16.9254 17.5055 15.3149 15.6676C15.3149 15.6676 19.0294 15.6676 22.6253 13.2384C23.1149 12.8987 23.5931 12.5152 24.0407 12.0757C24.2243 11.8799 24.3888 11.6841 24.5456 11.4923C25.1692 10.7092 25.5747 9.93411 25.831 9.19095C26.2365 8.0043 26.2441 6.90155 26.0988 5.98259C23.0423 1.52365 17.4342 -0.885612 11.6502 0.301041C3.92281 1.89124 -1.18795 9.31082 0.238933 16.8782C1.66581 24.4416 9.08713 29.2881 16.8145 27.6979C22.4187 26.5433 26.6458 22.324 28 17.2059C27.43 17.5694 26.6917 17.941 25.7583 18.2247C25.2113 18.3925 24.5954 18.5244 23.9145 18.6123V18.6083Z"
          />
        </svg>
        <span class="menu-item-name">Главная</span>
      </a>
    </li>
    <li class="menu-item">
      <a
        [routerLink]="['/films']"
        routerLinkActive="active"
        class="menu-item-link"
      >
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill=""
          xmlns="http://www.w3.org/2000/svg"
          class="menu-item-icon"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.2514 -1.40227e-05L0 15.2513L1.90643 17.1578C3.48576 15.5785 6.04635 15.5785 7.62567 17.1578C9.205 18.7371 9.205 21.2977 7.62569 22.877L9.5321 24.7834L24.7835 9.53208L22.877 7.62564C21.2977 9.20495 18.7371 9.20495 17.1578 7.62562C15.5784 6.0463 15.5784 3.48571 17.1577 1.90638L15.2514 -1.40227e-05Z"
          />
        </svg>
        <span class="menu-item-name">Билеты</span>
      </a>
    </li>
    <li class="menu-item">
      <a
        [routerLink]="['/cinemas']"
        routerLinkActive="active"
        class="menu-item-link"
      >
        <svg
          width="28"
          height="20"
          viewBox="0 0 28 20"
          fill=""
          xmlns="http://www.w3.org/2000/svg"
          class="menu-item-icon"
        >
          <rect x="2" width="24" height="14" />
          <path
            d="M0 18C0 16.8954 0.895431 16 2 16C3.10457 16 4 16.8954 4 18V20H0V18Z"
          />
          <path
            d="M6 18C6 16.8954 6.89543 16 8 16C9.10457 16 10 16.8954 10 18V20H6V18Z"
          />
          <path
            d="M12 18C12 16.8954 12.8954 16 14 16C15.1046 16 16 16.8954 16 18V20H12V18Z"
          />
          <path
            d="M18 18C18 16.8954 18.8954 16 20 16C21.1046 16 22 16.8954 22 18V20H18V18Z"
          />
          <path
            d="M24 18C24 16.8954 24.8954 16 26 16C27.1046 16 28 16.8954 28 18V20H24V18Z"
          />
        </svg>
        <span class="menu-item-name">Кинотеатры</span>
      </a>
    </li>
    <li class="menu-item">
      <a
        [routerLink]="['/stocks']"
        routerLinkActive="active"
        class="menu-item-link"
      >
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill=""
          xmlns="http://www.w3.org/2000/svg"
          class="menu-item-icon"
        >
          <path
            d="M12.3666 0.288452L16.3511 7.73033L24.6823 9.20162L18.8413 15.1722L19.9781 23.6234L12.3666 20.1335L4.7551 23.6234L5.89187 15.1722L0.05093 9.20162L8.38216 7.73033L12.3666 0.288452Z"
          />
        </svg>
        <span class="menu-item-name">Акции</span>
      </a>
    </li>
    <li class="menu-item">
      <a href="#" class="menu-item-link" (click)="openHiddenMenu($event)">
        <svg
          width="22"
          height="6"
          viewBox="0 0 22 6"
          fill=""
          xmlns="http://www.w3.org/2000/svg"
          class="menu-item-icon"
        >
          <rect width="6" height="6" rx="3" />
          <rect x="8" width="6" height="6" rx="3" />
          <rect x="16" width="6" height="6" rx="3" />
        </svg>
        <span class="menu-item-name">Еще</span>
      </a>
    </li>
  </ul>
  <div class="menu-hidden" [ngClass]="{ opened: openMenu() }">
    <div class="menu-hidden-logo">
      <!-- <svg width="28" height="28" viewBox="0 0 28 28" fill="" xmlns="http://www.w3.org/2000/svg" class="menu-item-icon">
                <path d="M23.9145 18.6083C23.3177 18.6802 22.6712 18.7161 21.9635 18.7002C21.9597 18.7002 21.952 18.7002 21.9444 18.7002C19.6606 18.5963 16.9254 17.5055 15.3149 15.6676C15.3149 15.6676 19.0294 15.6676 22.6253 13.2384C23.1149 12.8987 23.5931 12.5152 24.0407 12.0757C24.2243 11.8799 24.3888 11.6841 24.5456 11.4923C25.1692 10.7092 25.5747 9.93411 25.831 9.19095C26.2365 8.0043 26.2441 6.90155 26.0988 5.98259C23.0423 1.52365 17.4342 -0.885612 11.6502 0.301041C3.92281 1.89124 -1.18795 9.31082 0.238933 16.8782C1.66581 24.4416 9.08713 29.2881 16.8145 27.6979C22.4187 26.5433 26.6458 22.324 28 17.2059C27.43 17.5694 26.6917 17.941 25.7583 18.2247C25.2113 18.3925 24.5954 18.5244 23.9145 18.6123V18.6083Z"/>
            </svg> -->
      <div class="btn-holder position">
        <a href="#" (click)="openCityList($event)" cstc-button="secondary">{{
          currentCity()?.name
        }}</a>
      </div>
    </div>
    <a href="#" class="menu-hidden-close" (click)="closeHiddenMenu($event)">
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.0005 2L2.00049 24M2.00049 2L24.0005 24"
          stroke="white"
          stroke-width="4"
        />
      </svg>
    </a>
    <!-- <div class="btn-holder">
            <a [routerLink]="['/films']" routerLinkActive="active" cstc-button="primary" class="primary-btn" (click)="toggleHiddenMenu($event)">билеты</a>
        </div> -->
    <ul class="menu-hidden-list">
      <li class="menu-hidden-item">
        <a
          href="#"
          [routerLink]="['/events']"
          routerLinkActive="active"
          (click)="closeHiddenMenu($event)"
          >события</a
        >
        <!-- <a href="#" [routerLink]="['/cinemas']" routerLinkActive="active" (click)="toggleHiddenMenu($event)">кинотеатры</a> -->
      </li>
      <li class="menu-hidden-item">
        <!-- <a href="#" [routerLink]="['/stocks']" routerLinkActive="active" (click)="toggleHiddenMenu($event)">акции</a> -->
      </li>
      <li class="menu-hidden-item">
        <!-- <a href="#" [routerLink]="['/events']" routerLinkActive="active" (click)="toggleHiddenMenu($event)">события</a> -->
      </li>
    </ul>
    <!-- <div class="btn-holder position">
            <a href="#" (click)="toggleCityList($event)" cstc-button="secondary">{{currentCityName$ | async}}</a>
        </div> -->
  </div>
  <ng-container *ngIf="cityList()">
    <div
      class="location-list"
      trapScroll
      [ngClass]="{ active: cityService.cityListOpened() }"
    >
      <a [routerLink]="['/']" class="location-hidden-logo">
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill=""
          xmlns="http://www.w3.org/2000/svg"
          class="menu-item-icon"
        >
          <path
            d="M23.9145 18.6083C23.3177 18.6802 22.6712 18.7161 21.9635 18.7002C21.9597 18.7002 21.952 18.7002 21.9444 18.7002C19.6606 18.5963 16.9254 17.5055 15.3149 15.6676C15.3149 15.6676 19.0294 15.6676 22.6253 13.2384C23.1149 12.8987 23.5931 12.5152 24.0407 12.0757C24.2243 11.8799 24.3888 11.6841 24.5456 11.4923C25.1692 10.7092 25.5747 9.93411 25.831 9.19095C26.2365 8.0043 26.2441 6.90155 26.0988 5.98259C23.0423 1.52365 17.4342 -0.885612 11.6502 0.301041C3.92281 1.89124 -1.18795 9.31082 0.238933 16.8782C1.66581 24.4416 9.08713 29.2881 16.8145 27.6979C22.4187 26.5433 26.6458 22.324 28 17.2059C27.43 17.5694 26.6917 17.941 25.7583 18.2247C25.2113 18.3925 24.5954 18.5244 23.9145 18.6123V18.6083Z"
          />
        </svg>
      </a>
      <a (click)="closeCityList($event)" class="location-hidden-close">
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.0005 2L2.00049 24M2.00049 2L24.0005 24"
            stroke="white"
            stroke-width="4"
          />
        </svg>
      </a>
      <div class="location-holder">
        <a
          *ngFor="let item of cityList()"
          (click)="selectCity($event, item.id)"
          class="location-item"
          [ngClass]="{ active: currentCity()?.id === item.id }"
          >{{ item.name }}</a
        >
      </div>
    </div>
  </ng-container>
  <cstc-backdrop
    *ngIf="openMenu() === true || cityService.cityListOpened() === true"
    (click)="closeHiddenMenu($event)"
    trapScroll
  ></cstc-backdrop>
</nav>
