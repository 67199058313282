import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Session } from 'src/app/models/session.model';
import { TicketInfo } from 'src/app/models/ticket.model';

@Component({
  selector: 'cstc-ticket-result',
  templateUrl: './ticket-result.component.html',
  styleUrls: ['./ticket-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class TicketResultComponent implements OnInit {
  @Input() tickets!: TicketInfo[];
  @Input() session!: Session;
  constructor() { }

  ngOnInit(): void {
  }

}
