<div class="film-text">
  <ng-container *ngIf="session">
    <p class="film-name">{{session.film.length>maxNameLength?session.film.substring(0,maxNameLength)+'...':session.film}}</p>
  </ng-container>
  <ng-container *ngIf="theatre">
    <p class="metro">
      <span *ngFor="let metro of theatre.metro">
        {{metro.length>maxNameLength?metro.substring(0,maxNameLength)+'...':metro}}
      </span>
    </p>
  </ng-container>
  <ng-container *ngIf="session">
    <div class="title-block">
      <p class="date-holder">
        <span class="date">{{ session.showtime |toDate| date:'dd LLL yyyy':'ru'}}</span>
         • <span class="time">{{ session.showtime|toDate| date:"HH:mm"}}</span>
        • <span class="time">{{ session.format }}</span>
      </p>
    </div>
  </ng-container>
</div>
