import { inject } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { HallLevel, HallPlace, PaymentMethods, Product, Session, SessionPrice, Ticket } from 'src/app/models/session.model';
import { CookieService } from '../services/cookie/cookie.service';
import { StorageService } from '../services/storage/storage.service';
import { SaleConfig } from './session.model';
export interface CreateOrderRequestResponse {
  success: boolean;
  status: number;
  message?:string;
  data?: {
    redirect?:'string';
    bookingId?:'string';
    debug?:any;
  };
}
export interface RefundOrderRequestResponse {
  success: boolean;
  status: number;
  message:string;
}
export class ShoppingCart {
  public bookingId: string = '';
  public paymentUrl: string = '';
  public session: Session|undefined = undefined;
  public price: SessionPrice[]|undefined = undefined;
  public tickets: Ticket[] = new Array<Ticket>();
  public products: Product[] = new Array<Product>();
  public contacts: UntypedFormGroup|undefined;
  public params: SaleConfig|undefined;
  // public grossTotal: number = 0;
  // public ticketsTotal: number = 0;
  // public productsTotal: number = 0;
  public createdAt: Date | string = new Date();
  public updatedAt: Date | string = new Date();
  public cookie?: CookieService;
  public storage?: StorageService;

  /**
   * 0 - обычная продажа
   * 1 - пушкинская карта
   */
  public mode: 0|1 = 0;
  get ticketsTotal() {
    return this.tickets.reduce((accumulator: number, ticket: Ticket) => {
      return accumulator + ticket.price;
    }, 0)/100;
  }
  get productsTotal() {
    return this.products.reduce((accumulator: number, product: Product) => {
      return accumulator + product.price;
    }, 0)/100;
  }
  get grossTotal() {
    return this.ticketsTotal + this.productsTotal;
  }
  constructor(cookie: CookieService,storage: StorageService) {
    const date = new Date();
    this.cookie = cookie;
    this.tickets = [];
    this.products = [];
    this.storage = storage;
    this.contacts = this.initContactForm();
    this.createdAt = date;
    this.setContentUpdateTimer(date);
  }
  public setContentUpdateTimer(date: Date|undefined) {
    this.updatedAt = (typeof date === 'undefined')?new Date():date;
  }
  public updateFrom(src: ShoppingCart) {
    this.bookingId = src.bookingId;
    this.paymentUrl = src.paymentUrl;
    this.session = src.session;
    this.tickets = src.tickets;
    this.price = src.price;
    this.products = src.products;
    this.mode = src.mode;
    this.createdAt = new Date(src.createdAt);
    this.updatedAt = new Date(src.updatedAt);
  }
  setDefaultPaymentMethod() {
    this.contacts?.controls['payment'].setValue(this.getDefaultPaymentMethod());
  }
  getDefaultPaymentMethod() {
    const allowedMethods = this.params?.allowed;
    return Array.isArray(allowedMethods)?allowedMethods[0]:undefined;
  }
  initContactForm():UntypedFormGroup {
    const formGroup = new UntypedFormGroup({
      email: new UntypedFormControl(this.defaultEmail,
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
        ]
      ),
      phone: new UntypedFormControl(this.defaultPhone,[
        Validators.required,
        Validators.pattern("[0-9]{10}")
      ]),
      offer: new UntypedFormControl(false, [
        Validators.requiredTrue
      ]),
      payment: new UntypedFormControl(undefined,[
        Validators.required
      ]),
      buyer: new UntypedFormGroup({
        first_name: new UntypedFormControl('', [Validators.required, this.buyerNameValidator()]),
        middle_name: new UntypedFormControl('', this.buyerNameValidator()),
        last_name: new UntypedFormControl('', [Validators.required, this.buyerNameValidator()])
      })
    });
    // Add a listener to the payment control to dynamically set validators for the buyer control
    formGroup.get('payment')?.valueChanges.subscribe(paymentValue => {
      const buyerGroup = formGroup.get('buyer') as UntypedFormGroup;
      if (paymentValue === 'PUSHKIN') {
        buyerGroup.get('first_name')?.setValidators([Validators.required, this.buyerNameValidator()]);
        buyerGroup.get('last_name')?.setValidators([Validators.required, this.buyerNameValidator()]);
        buyerGroup.get('middle_name')?.setValidators(this.buyerNameValidator()); // middle_name remains optional
        buyerGroup.setValidators(this.buyerGroupValidator(true));
      } else {
        buyerGroup.get('first_name')?.clearValidators();
        buyerGroup.get('last_name')?.clearValidators();
        buyerGroup.get('middle_name')?.clearValidators();
        buyerGroup.setValidators(this.buyerGroupValidator(false));
      }
      buyerGroup.get('first_name')?.updateValueAndValidity();
      buyerGroup.get('last_name')?.updateValueAndValidity();
      buyerGroup.get('middle_name')?.updateValueAndValidity();
    });


    return formGroup;
  }
  private buyerNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null; // No error if the value is empty (handled by required validator if necessary)
      }
      const pattern = /^[а-яё\s]+$/i;
      return pattern.test(value) ? null : { invalidBuyerName: true };
    };
  }

  private buyerGroupValidator(required: boolean): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      const firstName = group.get('first_name')?.value;
      const lastName = group.get('last_name')?.value;
      if (required) {
        if (!firstName || !lastName) {
          return { buyerRequired: true };
        }
      }
      return null;
    };
  }
  getValueFromStorageOrCookieByName(name: string) {
    let fromCookie: string|undefined = undefined;
    let fromStorage: string|null = null;
    if (typeof this.cookie !== 'undefined') {
      fromCookie = this.cookie.getCookie(name);
    }
    if (typeof this.storage !== 'undefined') {
      fromStorage = this.storage.getItem(name);
    }
    return fromCookie || fromStorage ||  '';
  }
  get defaultEmail(): string {
    return this.getValueFromStorageOrCookieByName('email');
  }
  get defaultBuyer(): string {
    return this.getValueFromStorageOrCookieByName('buyer');
  }
  get defaultPhone(): string {
    return this.getValueFromStorageOrCookieByName('phone');
  }
  placeIsSelected(place:HallPlace) {
    return this.tickets.find(p => p.id===place.id)!==undefined;
  }
  get isEmpty():boolean {
    return !this.hasTickets()&&!this.hasProducts();
  }
  public hasProducts():boolean {
    return this.products.length>0;
  }
  public hasTickets():boolean {
    return this.tickets.length>0;
  }
  public clearTicketsForPushkin() {
    const ticket = this.tickets.length>1?this.tickets.splice(0,1):null;
    if(ticket) {
      this.tickets = [...ticket];
    }
  }
  public setTicket(place: HallPlace, level: HallLevel) {
    const price = (typeof this.price!=='undefined')?this.price.find(pr=>pr.id===place.type):undefined;
    const ticket = this.tickets.find(t=>t.id===place.id);



    if(price) {
      if(ticket) {
        this.removeTicket(ticket);
      } else {
        if(this.mode===1&&this.tickets.length>=1) {
          return;
        }
        this.tickets.push({
          id: place.id,
          type: place.type,
          row: place.row,
          column: place.place,
          level: level.id,
          fragment: place.fragment,
          price: price.price,
          discount: 0
        });
      }
    } else {
      // console.log('>>4',price,this.price,place)
    }
  }
  public removeTicket(ticket: Ticket) {
    const index = this.tickets.findIndex((t: Ticket)=> t.id===ticket.id);
    if(index > -1) {
      this.tickets.splice(index,1);
    }
  }
}
