import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy, inject, signal, HostBinding, } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { filter, map, Observable, Subject, } from 'rxjs';
import { BackdropComponent } from 'src/app/modules/shared/components/backdrop/backdrop.component';
import { ButtonComponent } from 'src/app/modules/shared/components/button/button.component';
import { SvgIconDirective } from 'src/app/modules/shared/directives/svg-icon.directive';
import { TrapScrollDirective } from 'src/app/modules/shared/directives/trap-scroll.directive';
import { CityService } from 'src/app/services/city/city.service';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  selector: 'cstc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    BackdropComponent,
    ButtonComponent,
    TrapScrollDirective,
    SvgIconDirective,
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  state = inject(CstcStore);
  cityService = inject(CityService);
  router = inject(Router);
  alive = true;
  isWideScreen = this.state.isWideScreen;
  city$ = this.cityService.city$.pipe(
    filter((cityId) => cityId !== null),
    map((cityId) => cityId as number)
  );
  cityList = this.cityService.cityList;
  currentCity = this.cityService.currentCity;
  menuOpen = signal(false);
  // cityListOpen=false;
  private destroy$ = new Subject<void>();

  @HostBinding('class.qr') get hostClass() {
    return this.router.url.includes('/qr')
  }

  menuItems = [
    // {
    //   name: 'билеты',
    //   url: '/films'
    // },
    {
      name: 'кинотеатры',
      url: '/cinemas',
    },
    {
      name: 'акции',
      url: '/stocks',
    },
    {
      name: 'события',
      url: '/events',
    },
  ];

  openMenu(event: MouseEvent) {
    event?.preventDefault();
    this.menuOpen.set(true);
  }
  openCityList(event: MouseEvent) {
    event?.preventDefault();
    this.menuOpen.set(false);
    this.cityService.openCityList();
  }
  closeCityList(event: MouseEvent) {
    event?.preventDefault();
    this.menuOpen.set(false);
    this.cityService.closeCityList();
  }
  selectCity(event: MouseEvent, id: number) {
    event.preventDefault();
    this.cityService.selectCity(id);
    this.cityService.cityConfirmed();
    this.closeCityList(event);
  }
  constructor() { }
  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.alive = false;
    this.destroy$.next();
    this.destroy$.complete();
  }
}
