@if (loading()===false) {
    <article class="event-list" *ngIf="items$|async as eventList">
        <cstc-event-item *ngFor="let event of eventList" [event]="event"></cstc-event-item>
        @if (loadMoreItems()===true) {
            <cstc-loader class="inline"></cstc-loader>
        }
        @if (page * per_page < totalItems) {
            <div class="btn-holder">
                <a cstc-button="secondary" (click)="showMoreItems()">Загрузить ещё</a>
            </div>
        }
    </article>
}
@else {
    <cstc-events-skeleton/>
}
<cstc-banner type="bottom"></cstc-banner>
