import { HttpClient } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, filter, map, pluck, switchMap, takeWhile, tap } from 'rxjs';
import { PageData, PageDataResponse } from './page.model';
import { ENVIRONMENT_CONFIG } from 'src/app/tokens/environment-config.token';

@Injectable()
export class PageService {
  private appConfig = inject(ENVIRONMENT_CONFIG);
  private baseUrl = this.appConfig.baseUrl;
  alive = true;
  loading$ = new BehaviorSubject(true);
  error$ = new BehaviorSubject(false);
  content$ = new BehaviorSubject<undefined|PageData>(undefined);

  constructor(
    private route: ActivatedRoute,
    private http:HttpClient,
  ) {
    this.route.params.pipe(
      filter(params=>{
        return typeof params['id'] !== 'undefined';
      }),
      map(params => params['id']),
      // tap(()=>{
      //   this.loading$.next(true);
      //   this.error$.next(false);
      // }),
      takeWhile(()=>this.alive),
      switchMap((newsId:string)=>{
        return this.getPageData(newsId);
      }),
      pluck('data')
    ).subscribe(result=>{
        this.content$.next(result);
    });
  }
  getPageData(newsId: string) {
    return this.http.get<PageDataResponse>(
      `${this.baseUrl}/page/${newsId}`,
    );
  }
  ngOnDestroy(): void {
    this.alive = false;
  }
}
