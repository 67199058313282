<div class="ticket-position">
    <p class="title">Зал</p>
    <ul>
      <li>{{session.hall}}</li>
    </ul>
</div>
<div class="ticket-position">
    <p class="title">РЯД</p>
    <ul>
        <li *ngFor="let item of tickets">{{item.row}}</li>
    </ul>
</div>
<div class="ticket-position">
    <p class="title">места</p>
    <ul>
        <li *ngFor="let item of tickets">{{item.column}}</li>
    </ul>
</div>
