import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, filter, map, pluck, Subject, switchMap, takeWhile, tap, distinctUntilChanged, catchError, of } from 'rxjs';
import { CityService } from 'src/app/services/city/city.service';
import { ActivatedRoute } from '@angular/router';
import { Film, Theatre } from 'src/app/services/schedule/schedule.model';
import { OrderService } from '../order/order.service';
import { PlaceData, PlaceRequestResponse } from 'src/app/models/session.model';

@Injectable({
  providedIn: 'root'
})
export class SessionService implements OnDestroy  {
  private alive = true;
  readonly loading$ = new BehaviorSubject<boolean>(true);
  readonly error$ = new BehaviorSubject<boolean>(false);
  readonly data$= new BehaviorSubject<PlaceData|undefined>(undefined);
  constructor(
    private httpClient: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private route: ActivatedRoute,
    private city: CityService,
    private order: OrderService
  ) {
    combineLatest([
      this.route.params.pipe(
        filter(params=>{
          return typeof params['id'] !== 'undefined';
        }),
        map(params => params['id']),
      ),
      this.city.city$.asObservable()
    ]).pipe(
      takeWhile(()=>this.alive),
      tap(()=>{
        /**Очищаем данные, не храним старый сеанс когда ушли со страницы */
        //this.clearData();
      }),
      switchMap(([sessionId,cityId])=>{
        return this.getData(sessionId);
      }),
      pluck('data'),
    ).subscribe((data:PlaceData|undefined)=>{
      this.loading$.next(false);
      if(data) {
        this.order.setSession((data?.session)?data.session:undefined);
        this.order.setPriceList((data?.price)?data.price:undefined);
        this.order.setHall((data?.hall)?data.hall:undefined);
        this.order.setSaleConfig((data?.sale)?data.sale:undefined);
        this.order.setMessages(data.messages);
      }
      // this.film$.next(typeof data.film !== 'undefined'?data.film:false);
      // this.theatre$.next(typeof data.theatre !== 'undefined'?data.theatre:false);
      // this.schedule$.next(data.schedule!==false?data.schedule.items:[]);
      // this.dates$.next(data.schedule!==false?data.schedule.dates:[]);
    });
  }
  clearData() {
    this.data$.next(undefined);
    this.order.clear();
    this.loading$.next(true);
    this.error$.next(false);
  }
  getData(sessionId: number) {
    return this.httpClient.get<PlaceRequestResponse>(
      `${this.baseUrl}/place/${sessionId}`,
    ).pipe(
      catchError((err: any)=>{
        this.error$.next(true);
        return of({data:undefined});
      }),
    );
  }
  ngOnDestroy(): void {
    this.alive = false;
    this.clearData();
  }
  getColorByCode(code: number|undefined): string {
    const colors = [
      '#14B3E7',
      '#E3357E',
      '#C6DC18',
      '#093581'
    ];
    return typeof code !== 'undefined'?((typeof colors[code]!==undefined)?colors[code]:colors[0]):colors[0];
  }
}
