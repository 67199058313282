<div class="toggle-holder" *ngIf="cart$|async as cart">
    <label class="switch">
    <input type="checkbox" [ngModel]="cart.mode" (ngModelChange)="pushkinToggle($event,cart.mode)">
        <span class="slider"></span>
        <img src="assets/img/toggle-off.svg" class="toggle-off" alt="">
        <img src="assets/img/toggle-on.svg" class="toggle-on" alt="">
    </label>
</div>
<img src="assets/img/Long_color.png" class="pushkin-img" alt="" *ngIf="(isWideScreen$ | async) || false;else pushkinMobile">
<ng-template #pushkinMobile>
    <img src="assets/img/Name_color 1.png" class="pushkin-img" alt="">
</ng-template>
