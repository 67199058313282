import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable, OnDestroy, signal } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService implements OnDestroy{
  isWideScreen$ = new Observable<boolean>();
  private windowWidthSubject: BehaviorSubject<number> = new BehaviorSubject<number>(window.innerWidth);
  windowWidth = signal<number|null>(null);

  constructor(
    public breakpointObserver: BreakpointObserver
  ) {
    this.isWideScreen$ = this.breakpointObserver.observe(['(min-width: 1024px)']).pipe( map (({ matches })=> matches ));
    this.onResize();
    window.addEventListener('resize', this.onResize.bind(this));
  }
  onResize() {
    this.windowWidth.set(window.innerWidth||null);
  }
  ngOnDestroy(): void {
    window.removeEventListener('resize', this.onResize.bind(this));
  }
}
