import { Component, OnInit, ChangeDetectionStrategy, HostListener, inject, Renderer2, ElementRef, signal, computed, DestroyRef, HostBinding, input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MonthFilterItem } from '../../../../../../../models/dates.model';
import { ScheduleService } from 'src/app/services/schedule/schedule.service';
import { IdleService } from 'src/app/services/idle.service';
import { ToDatePipe } from 'src/app/pipes/to-date.pipe';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  selector: 'cstc-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ToDatePipe
  ]
})
export class DateComponent {
  private state = inject(CstcStore);
  renderer = inject(Renderer2);
  element = inject(ElementRef);
  route = inject(ActivatedRoute);

  idleSvc = inject(IdleService);
  scheduleSvc = inject(ScheduleService);
  destroyRef = inject(DestroyRef);
  platformSvc = inject(PlatformService);

  dates = input.required<string[]>()
  kiosk = this.state.isKiosk;
  selectedDate = this.scheduleSvc.dateSelected;

  allowEmpty = signal(false);
  datesParsed = computed(() => {
    const dates = this.dates();
    return this.groupBy(
      dates,
      (item: string) => {
        return item.slice(0, 7) + '-01';
      }
    );
  });
  @HostBinding('class') get hostClass() {
    return {
      'kiosk': this.kiosk() === true
    }
  }
  scrolling = false;
  @HostListener('scroll', ['$event.target'])
  onScroll(target: HTMLElement) {
    const itemsElement = target as HTMLElement;
    const maxScrollLeft = itemsElement.scrollWidth - itemsElement.clientWidth;
    if (target.scrollLeft > 0) {
      this.renderer.addClass(this.element.nativeElement, 'scrolled');
    } else {
      this.renderer.removeClass(this.element.nativeElement, 'scrolled');
    }
  }



  valuesToArray(input: { [key: string]: MonthFilterItem }): MonthFilterItem[] {
    return input ? Object.keys(input)
      .map(function (key) {
        return input[key];
      }) : [];
  }

  groupBy(list: string[], keyGetter: Function): MonthFilterItem[] {
    const map: { [key: string]: MonthFilterItem } = {};
    if (Array.isArray(list) && list.length) {
      list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map[key];
        if (!collection) {
          map[key] = {
            month: key,
            dates: [item]
          };
        } else {
          collection.dates.push(item);
        }
      });
    }
    return this.valuesToArray(map);
  }
  toggleDate(event: MouseEvent, date: string) {
    this.scheduleSvc.setDate(date);
  }

}
