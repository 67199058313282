import { Directive, ElementRef, HostListener, Input, NgModule, NgZone, Renderer2 } from '@angular/core';
import { AnimationBuilder, style, animate, AnimationPlayer } from '@angular/animations';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'button[ripple], a[ripple]'
})
export class RippleDirective {

       /**
     * Sets/gets the ripple target.
     * ```html
     * <div  #rippleContainer class="div-1" igxRipple [igxRippleTarget] = "'.div-1'"></div>
     * ```
     * ```typescript
     * @ViewChild('rippleContainer', {read: IgxRippleDirective})
     * public ripple: IgxRippleDirective;
     * let rippleTarget = this.ripple.rippleTarget;
     * ```
     * Can set the ripple to activate on a child element inside the parent where igxRipple is defined.
     * ```html
     * <div #rippleContainer [igxRippleTarget] = "'#child"'>
     *  <button id="child">Click</button>
     * </div>
     * ```
     *
     * @memberof IgxRippleDirective
     */
    @Input()
    public rippleTarget = '';
    /**
     * Sets/gets the ripple color.
     * ```html
     * <button #rippleContainer [igxRipple] = "'red'" ></button>
     * ```
     * ```typescript
     * @ViewChild('rippleContainer', {read: IgxRippleDirective})
     * public ripple: IgxRippleDirective;
     * let ripple = this.ripple.ripple;
     * ```
     *
     * @memberof IgxRippleDirective
     */
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('ripple') set rippleColor(value: string) {
      this._color = value || this._color;
    };
    // @Input('ripple') set rippleColor(value: string) {
    //   this._color = value || this.rippleColor;
    // };

    /**
     * Sets/gets the ripple duration(in milliseconds).
     * Default value is `600`.
     * ```html
     * <button #rippleContainer igxRipple [igxRippleDuration] = "800"></button>
     * ```
     * ```typescript
     * @ViewChild('rippleContainer', {read: IgxRippleDirective})
     * public ripple: IgxRippleDirective;
     * let rippleDuration = this.ripple.rippleDuration;
     * ```
     *
     * @memberof IgxRippleDirective
     */
    @Input()
    public rippleDuration = 600;
    /**
     * Enables/disables the ripple to be centered.
     * ```html
     * <button #rippleContainer igxRipple [igxRippleCentered] = "true"></button>
     * ```
     *
     * @memberof IgxRippleDirective
     */
    @Input()
    public set centered(value: boolean) {
        this._centered = value || this.centered;
    }
    /**
     * Sets/gets whether the ripple is disabled.
     * Default value is `false`.
     * ```html
     * <button #rippleContainer igxRipple [igxRippleDisabled] = "true"></button>
     * ```
     * ```typescript
     * @ViewChild('rippleContainer', {read: IgxRippleDirective})
     * public ripple: IgxRippleDirective;
     * let isRippleDisabled = this.ripple.rippleDisabled;
     * ```
     *
     * @memberof IgxRippleDirective
     */
    @Input()
    public rippleDisabled = false;

    protected get nativeElement(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    private rippleElementClass = 'ripple__inner';
    private rippleHostClass = 'ripple';
    private _centered = false;
    private _color = 'white';
    private animationQueue:AnimationPlayer[] = [];

    constructor(
        protected builder: AnimationBuilder,
        protected elementRef: ElementRef,
        protected renderer: Renderer2,
        private zone: NgZone) { }

    /**
     * @hidden
     */
    @HostListener('mousedown', ['$event'])
    public onMouseDown(event: any) {
      this.zone.runOutsideAngular(() => this._rippleEvent({ event }));
    }

    private setStyles(rippleElement: HTMLElement, styleParams: any) {
        this.renderer.addClass(rippleElement, this.rippleElementClass);
        this.renderer.setStyle(rippleElement, 'width', `${styleParams.radius}px`);
        this.renderer.setStyle(rippleElement, 'height', `${styleParams.radius}px`);
        this.renderer.setStyle(rippleElement, 'top', `${styleParams.top}px`);
        this.renderer.setStyle(rippleElement, 'left', `${styleParams.left}px`);

        if (this._color) {
          this.renderer.setStyle(rippleElement, 'background-color', this._color);
        }
    }

    private _rippleEvent({ event }: { event: { clientX: number; clientY: number; }; }) {
        if (this.rippleDisabled) {
            return;
        }

        const target = (this.rippleTarget ? this.nativeElement.querySelector(this.rippleTarget) || this.nativeElement : this.nativeElement);

        const rectBounds = target.getBoundingClientRect();
        const radius = Math.max(rectBounds.width, rectBounds.height);
        let left = Math.round(event.clientX - rectBounds.left - radius / 2);
        let top = Math.round(event.clientY - rectBounds.top - radius / 2);

        if (this._centered) {
            left = top = 0;
        }

        const dimensions = {
            radius,
            top,
            left
        };

        const rippleElement = this.renderer.createElement('span');

        this.setStyles(rippleElement, dimensions);
        this.renderer.addClass(target, this.rippleHostClass);
        this.renderer.appendChild(target, rippleElement);

        const animation = this.builder.build([
            style({ opacity: 0.2, transform: 'scale(.3)' }),
            animate(this.rippleDuration, style({ opacity: 0, transform: 'scale(2)' }))
        ]).create(rippleElement);

        this.animationQueue.push(animation);

        animation.onDone(() => {
            this.animationQueue.splice(this.animationQueue.indexOf(animation), 1);
            target.removeChild(rippleElement);
            if (this.animationQueue.length < 1) {
                this.renderer.removeClass(target, this.rippleHostClass);
            }
        });

        animation.play();

    }
}
