<div class="cinema-banner" [ngClass]="{mobileApp: isMobileApp()}">
	<ng-container *ngIf="isWideScreen()===true; else mobileImg">
		<img [src]="cinema.media.image.big?cinema.media.image.big:'/assets/img/placeholders/cinema.jpg'" alt="">
	</ng-container>
	<!-- <ng-template #zoom>
		<img [src]="cinema.media.image.big?cinema.media.image.big:'/assets/img/placeholders/cinema-kinozoom.jpg'" alt=""
			*ngIf="isWideScreen; else mobileImg">
	</ng-template> -->
	<ng-template #mobileImg>
		<img [src]="cinema.media.image.small?cinema.media.image.small:'/assets/img/placeholders/cinema.jpg'" alt="">
	</ng-template>
</div>
<div class="cinema-banner bg">
	<img [src]="cinema.media.image.big?cinema.media.image.big:'/assets/img/placeholders/cinema.jpg'" alt="">
</div>
<div class="cinema-about-overlay"></div>
<article class="open-map">
	<a href="#" [ngClass]="{'active': openMap}" (click)="toggleMapBtn($event)" cstc-button="secondary">на карте</a>
</article>
<div class="map-wrapper" [ngClass]="{'opened': openMap}">
	<div class="ya-map" #mapContainer></div>
</div>
<article class="cinema-about">
	<div class="cinema-about-title">
		<h2>{{cinema.name}}</h2>
	</div>
	<div class="cinema-location-info">
		<p *ngIf="cinema.metro" class="metro"><i *ngFor="let metroItem of cinema.metro">м. {{metroItem}}</i></p>
		<!-- <p><i>м. "Название станции метро"</i></p> -->
		<p *ngIf="cinema.address">{{cinema.address}}</p>
		<!-- <a href="tel:" class="cinema-phone">"Номер телефона"</a> -->
		<p *ngIf="cinema.working_hours">{{cinema.working_hours}}</p>
		<!-- <a *ngIf="cinema.phone_support" href="tel:{{cinema.phone_support}}" class="cinema-phone">{{cinema.phone_support}}</a> -->
		<a href="tel:{{cinema.phone_support}}" class="cinema-phone">{{cinema.phone_support?cinema.phone_support:"Номер
			телефона"}}</a>
	</div>
	<ul *ngIf="cinema.options" class="cinema-options">
		<li *ngFor="let item of cinema.options">{{item}}</li>
	</ul>
</article>
