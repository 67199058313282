<article>
    <div class="refund-page">
        <div class="refund-film-img">
            <img src="assets/img/placeholders/film_poster_placeholder.jpg" alt="">
        </div>
        <div class="refund-info">
            <div class="refund-film-text">
                <p class="film-name">{{info.film.length>maxNameLength?info.film.substring(0,maxNameLength)+'...':info.film}}</p>
                <p class="metro">{{info.metro.length>maxNameLength?info.metro.substring(0,maxNameLength)+'...':info.metro}}</p>
                <div class="title-block">
                    <p class="date-holder"><span class="date">{{info.date}}</span> • <span class="time">{{info.time}}</span> • <span class="time">{{info.format}}</span></p>
                    <p class="format"></p>
                    <p class="hall">{{info.hall}}</p>
                </div>
            </div>
            <div class="refund-rule" *ngIf="lucky">
                <h2>Заказ возвращен</h2>
                <p class="refund-rule">Деньги поступят на карту, с которой проводилась оплата, в течение 10 банковских дней.</p>
            </div>
            <div class="refund-rule" *ngIf="!lucky">
                <h2>Возврат невозможен</h2>
                <p class="refund-rule">К сожалению, мы не можем вернуть заказ менее чем за 30 минут до сеанса. Пожалуйста, обратитесь в кассу кинотеатра.</p>
            </div>
        </div>
    </div>
</article>
