import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, computed, inject, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Film } from 'src/app/services/schedule/schedule.model';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  selector: 'cstc-movie-card',
  templateUrl: './movie-card.component.html',
  styleUrls: ['./movie-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterModule,
    CommonModule
  ]
})
export class MovieCardComponent {
  state = inject(CstcStore);
  isWideScreen = this.state.isWideScreen;
  item = input.required<Film>();
  @Input() type: string = 'small';

  maxWordWidth = 12;
  longestWord = computed<number>(() => {
    const item = this.item();
    const text: string = item.name_short || item.name;
    const words: string[] = text.split(/\s+/);
    const maxLength: number = Math.max(...words.map((word: string) => word.length));
    return maxLength;
  });
  scheduleRoute = computed((): (string | number)[] => {
    const item = this.item(); // Signal or method
    return ['/film', item.id];
  });

  scheduleQueryParams = computed((): Record<string, string> | null => {
    const item = this.item();
    const dates: string[] = item.dates || [];

    if (!dates.length) return null;
    const validDates = dates.filter((d): d is string => !!d && d.trim().length > 0);
    if (!validDates.length) return null;
    const minDate = validDates.reduce((min, curr) => (curr < min ? curr : min), validDates[0]);
    return { date: minDate };
  });
}
