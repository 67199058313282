import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PageData } from 'src/app/services/page/page.model';

@Component({
  selector: 'cstc-stock-card[item]',
  templateUrl: './stock-card.component.html',
  styleUrls: ['./stock-card.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    CommonModule
  ]
})
export class StockCardComponent implements OnInit {

  @Input() item!: PageData;

  constructor() { }

  ngOnInit(): void {
  }

}
