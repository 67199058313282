import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PageData } from 'src/app/services/page/page.model';

@Component({
  selector: 'cstc-event-item[event]',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class EventItemComponent {
  @Input() event!: PageData;
}
