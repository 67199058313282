import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './modules/website/components/pages/home/home.component';
import { KioskGaurd } from './services/platform/kiosk.guard';
import { WebsiteComponent } from './modules/website/website.component';
import { CinemaSelectComponent } from './modules/website/components/shared/cinema-select/cinema-select.component';
import { FilmsComponent } from './modules/website/components/pages/films/films.component';
import { FilmComponent } from './modules/website/components/pages/film/film.component';
import { CinemaHallComponent } from './modules/website/components/pages/cinema/cinema.component';
import { CinemasComponent } from './modules/website/components/pages/cinemas/cinemas.component';
import { PageComponent } from './modules/website/components/pages/page/page.component';
import { StocksComponent } from './modules/website/components/pages/stocks/stocks.component';
import { EventsComponent } from './modules/website/components/pages/events/events.component';
import { RefundComponent } from './modules/website/components/pages/refund/refund.component';
import { ResultComponent } from './modules/website/components/pages/result/result.component';
import { RefundResultComponent } from './modules/website/components/pages/refund/ux/refund-result/refund-result.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { QrPayComponent } from './modules/website/components/pages/qr-pay/qr-pay.component';

export const routes: Routes = [
  { path: '', outlet: 'cart', loadChildren: () => import('./modules/cart/cart.module').then(m => m.CartModule) },
  {
    path: 'order',
    loadChildren: () => import('./modules/order/order.module').then(m => m.OrderModule),
    data: {
      hideBranding: true
    },
  },
  {
    path: '',
    component: WebsiteComponent,
    children: [
      {
        path: "",
        component: HomeComponent,
        canActivate: [KioskGaurd],
        pathMatch: 'full'
      },
      {
        path: "kiosk",
        component: CinemaSelectComponent,
      },
      {
        path: "films",
        component: FilmsComponent,
      },
      {
        path: "film/:id",
        component: FilmComponent,
        data: {
          hideBranding: true
        }
      },
      {
        path: "cinema/:id",
        component: CinemaHallComponent,
        data: {
          hideBranding: true
        }
      },
      {
        path: "cinemas",
        component: CinemasComponent,
        data: {
          hideBranding: true
        }
      },
      {
        path: "privacy",
        redirectTo: 'page/politika-konfidencialnosti',
        pathMatch: 'full'
      },
      {
        path: "page/:id",
        component: PageComponent,
      },
      // {
      //   path: "stock/:id",
      //   component: StockComponent,
      // },
      {
        path: "stocks",
        component: StocksComponent,
      },
      {
        path: "events",
        component: EventsComponent,
      },
      // {
      //   path: "event/:id",
      //   component: EventComponent,
      // },
      {
        path: "refund/:id",
        component: RefundComponent,
        data: {
          hideBranding: true
        }
      },
      {
        path: "result/:id",
        component: ResultComponent,
        data: {
          hideBranding: true
        }
      },
      {
        path: "qr/:id",
        component: QrPayComponent,
        data: {
          hideBranding: true
        }
      },
      {
        path: "refund-result",
        component: RefundResultComponent
      },
      {
        path: '**',
        redirectTo: '404'
      },
      {
        path: '404',
        component: NotFoundComponent,

        // чтоб спрятать главный баннер на странице
        data: {
          hideBranding: true
        }
      }
    ]
  },
];
