<article>
    <div class="event-wrapper">
      <div class="event-poster">
        <div class="event-poster-img"></div>
        <div class="event-poster-advantage"></div>
      </div>
      <div class="text-wrapper">
        @for (item of textLines; track $index) {
            <div class="event-text"></div>
          }
      </div>
    </div>
  </article>
