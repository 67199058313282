@let order = info();
<div class="arrow-back-wrapper">
    <div class="arrow-back" *ngIf="kiosk()===false" (click)="openDialog()">
        <span iconName="arrow"></span>
    </div>
</div>
@if (initialLoading()===false && order!==undefined) {
    <div class="center">
        <div class="qr-wrapper">
            <cstc-ticket-qr [payment]="true" [order]="order" (isLoadedChanged)="onLoadedChange($event)"></cstc-ticket-qr>
        </div>
        <div class="content">
            <div class="content-top">
                <div class="sbp-icon">
                    <span iconName="sbp-color"></span>
                </div>
                <p class="sub-title">Отсканируйте этот qr-код через приложение вашего банка</p>
            </div>
            <div class="content-bottom" *ngIf="kiosk()===false">
                <!-- <div class="content-group">
                    <p class="sub-title">сумма</p>
                    <p class="reason">{{order.amount/100}}&nbsp;₽</p>
                </div> -->
                <!-- <a cstc-button="primary" class="primary-btn">оплатить картой</a> -->
            </div>
        </div>
    </div>
    @if (kiosk()===true) {
        <a class="cancel-order" cstc-button="outline" (click)="openDialog($event)">Отменить заказ</a>
    }
    <cstc-confirm-dialog/>
}
@else if (error()) {
    <p>{{error()}}</p>
}
@else {
    <cstc-qr-skeleton/>
}
