import { Component, DestroyRef, HostBinding, OnInit, inject, signal } from '@angular/core';
import { BehaviorSubject, delay } from 'rxjs';
import { LoaderComponent } from 'src/app/modules/shared/components/loader/loader.component';
import { PageListService } from 'src/app/services/page-list/page-list.service';
import { PageData } from 'src/app/services/page/page.model';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { BannerComponent } from '../../shared/banner/banner.component';
import { EventItemComponent } from '../../shared/event-item/event-item.component';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from 'src/app/modules/shared/components/button/button.component';
import { EventsSkeletonComponent } from 'src/app/modules/shared/components/skeleton-loader/events-skeleton/events-skeleton.component';
import { DataService } from 'src/app/services/data/data.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  selector: 'cstc-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  standalone: true,
  providers: [
    PageListService
  ],
  imports: [
    LoaderComponent,
    BannerComponent,
    EventItemComponent,
    CommonModule,
    ButtonComponent,
    EventsSkeletonComponent,
  ]
})
export class EventsComponent implements OnInit {
  state = inject(CstcStore);
  pageListSvc = inject(PageListService);
  platformSvc = inject(PlatformService);
  dataSvc = inject(DataService);
  destroyRef = inject(DestroyRef);
  loading = this.dataSvc.loading;
  loadMoreItems = signal(false);
  items$ = new BehaviorSubject<PageData[] | undefined>(undefined);
  loading$ = new BehaviorSubject(true);
  error$ = new BehaviorSubject(false);
  category = 2;//События(Новости)
  per_page: number = 50;
  page: number = 1;
  totalItems: number = 0;
  isStandalone = this.state.isStandalone;
  @HostBinding('class.isStandalone') hostClass() {
    return this.isStandalone();
  }

  ngOnInit(): void {
    this.setData();
  }
  setData() {
    this.dataSvc.setLoading(true);
    this.pageListSvc.getNews(this.per_page, this.page, this.category).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(({ data, totalCount }) => {
      // this.loading$.next(false);
      this.dataSvc.setLoading(false);
      if (!Array.isArray(data)) {
        this.error$.next(true);
      } else {
        this.totalItems = totalCount;
        this.items$.next(data);
      }
    });
  }

  showMoreItems() {
    // this.loading$.next(true);
    this.loadMoreItems.set(true);
    this.page++;
    this.pageListSvc.getNews(this.per_page, this.page, this.category).pipe(
      delay(2000)
    ).subscribe({
      next: ({ data, totalCount }) => {
        // this.loading$.next(false);
        this.loadMoreItems.set(false);
        if (!data || !Array.isArray(data)) {
          this.error$.next(true);
        } else {
          this.totalItems = totalCount;
          const currentItems = this.items$.getValue() || [];
          this.items$.next([...currentItems, ...data]);
        }
      },
      error: err => {
        console.error("Error fetching next page:", err);
        // this.loading$.next(false);
        this.loadMoreItems.set(false);
        this.error$.next(true);
      }
    });
  }
}
