import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, computed, signal, ViewChild, ElementRef, AfterViewInit, inject, Renderer2 } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, fromEvent, takeWhile } from 'rxjs';
import { PageListService } from 'src/app/services/page-list/page-list.service';
import { PageData } from 'src/app/services/page/page.model';
import { StockCardComponent } from '../stock-card/stock-card.component';

@Component({
  selector: 'cstc-stock-new-section',
  templateUrl: './stock-new-section.component.html',
  styleUrls: ['./stock-new-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    StockCardComponent
  ],
  providers: [
    PageListService,
  ]
})
export class StockNewSectionComponent implements OnInit, OnDestroy {
  renderer = inject(Renderer2);
  el = inject(ElementRef);
  alive = true;
  category = 1;//Акции
  per_page: number = 3;
  page: number = 1;
  totalItems:number = 0;
  items$ = new BehaviorSubject<PageData[] | undefined>(undefined);
  loading$ = new BehaviorSubject(true);
  error$ = new BehaviorSubject(false);
  @Input() isWideScreen$!: Observable<boolean>;
  resizeSubscription: Subscription | undefined;
  constructor(
    private pageListSvc: PageListService
  ) { }

  ngOnInit(): void {
    this.resizeSubscription = fromEvent(window, 'resize')
      .subscribe(() => {
        this.updatePerPage(window.innerWidth);
        this.fetchData(); // Fetch data whenever per_page changes
      });

    this.updatePerPage(window.innerWidth);
    this.fetchData();
  }
  updatePerPage(screenWidth: number) {
    if (screenWidth < 1380 && screenWidth >= 1040) {
      this.per_page = 3;
    } else if (screenWidth < 1040) {
      this.per_page = 2;
    } else {
      this.per_page = 4;
    }
  }
  fetchData() {
    // this.loading$.next(true);
    this.pageListSvc.getNews(this.per_page,this.page,this.category).pipe(
      takeWhile(()=>this.alive),
    ).subscribe(({data, totalCount}) => {
      this.loading$.next(false);
      if(!Array.isArray(data)) {
        this.error$.next(true);
      } else {
        this.totalItems = totalCount;
        this.items$.next(data);
      }
    });
  };
  ngOnDestroy(): void {
    this.alive = false;
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }
}
