import { Component, HostBinding, inject, Input } from '@angular/core';
import { ScheduleItem } from 'src/app/services/schedule/schedule.model';
import { ScheduleService } from 'src/app/services/schedule/schedule.service';
import { DateComponent } from '../filters/date/date/date.component';
import { CommonModule } from '@angular/common';
import { CinemaScheduleComponent } from 'src/app/modules/shared/components/skeleton-loader/cinema-schedule/cinema-schedule.component';
import { ScheduleCinemaCardComponent } from './components/schedule-cinema-card/schedule-cinema-card.component';
import { ScheduleFilmCardComponent } from './components/schedule-film-card/schedule-film-card.component';
import { ActivatedRoute, NavigationStart, Router, Scroll } from '@angular/router';
import { filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  selector: 'cstc-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DateComponent,
    CinemaScheduleComponent,
    ScheduleCinemaCardComponent,
    ScheduleFilmCardComponent
  ]
})
export class ScheduleComponent {
  state = inject(CstcStore);
  scheduleSvc = inject(ScheduleService);
  route = inject(ActivatedRoute);
  router = inject(Router);

  schedule = this.scheduleSvc.scheduleFiltered;
  dates = this.scheduleSvc.dates;
  // @Input() filtering!: boolean | null;

  filtering = this.scheduleSvc.filtering;
  isStandalone = this.state.isStandalone;

  private navigationState: { [key: string]: any } | undefined;

  @HostBinding('class') get hostClass() {
    return {
      'isStandalone': this.isStandalone() === true
    }
  }

  constructor() {
    this.startBackgroundUpdate();

    // Подписываемся на NavigationStart, чтобы сохранить state
    this.router.events.pipe(
      filter((event): event is NavigationStart => event instanceof NavigationStart),
      takeUntilDestroyed()
    ).subscribe((event: NavigationStart) => {
      const navigation = this.router.getCurrentNavigation();
      if (navigation?.extras?.state) {
        this.navigationState = navigation.extras.state;
        // console.log('Saved navigation state:', this.navigationState);
      }
    });

    // Подписываемся на Scroll, чтобы восстановить позицию скролла
    this.router.events.pipe(
      filter((event): event is Scroll => event instanceof Scroll),
      takeUntilDestroyed()
    ).subscribe((event: Scroll) => {
      if (this.navigationState?.['preventScroll']) {
        const scrollPosition = this.navigationState['scrollPosition'] || 0;
        // console.log('Restoring scroll position to:', scrollPosition);
        setTimeout(() => {
          window.scrollTo({ top: scrollPosition, behavior: 'auto' });
        }, 0);
      }
    });
  }

  startBackgroundUpdate() {
    this.scheduleSvc.startBackgroundUpdate();
  }
}
