import { Directive, ElementRef, HostListener, Renderer2, inject } from '@angular/core';

@Directive({
  selector: '[scrollDetection]',
  standalone: true,
})
export class ScrollDetectionDirective {
  element = inject(ElementRef);
  renderer = inject(Renderer2);
  private isScrolled = false;
  @HostListener('scroll', ['$event'])
  onScroll(event:any) {
    const element = this.element.nativeElement;
    if (element.scrollLeft > 0 && !this.isScrolled) {
      this.isScrolled = true;
      element.classList.add('scrolled');
    } else if (element.scrollLeft === 0 && this.isScrolled) {
      this.isScrolled = false;
      element.classList.remove('scrolled');
    }
  }
}
