@let cinemaItem = theatre$|async;
@if(loading()===true){
    <cstc-cinema-info-skeleton/>
    <article><cstc-date-skeleton/></article>
    <article><cstc-cinema-schedule/></article>
}
@if (cinemaItem) {
    <cstc-cinema-info [cinema]="cinemaItem" [ngClass]="{mobileApp: isMobileApp()}"></cstc-cinema-info>
    <cstc-schedule></cstc-schedule>
}
<cstc-banner type="bottom"></cstc-banner>
