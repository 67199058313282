import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CinemaScheduleComponent } from '../cinema-schedule/cinema-schedule.component';
import { DateSkeletonComponent } from '../date-skeleton/date-skeleton.component';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  selector: 'cstc-film-skeleton',
  standalone: true,
  imports: [
    CinemaScheduleComponent,
    DateSkeletonComponent
  ],
  templateUrl: './film-skeleton.component.html',
  styleUrl: './film-skeleton.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilmSkeletonComponent {
  platformSvc = inject(PlatformService);
  state = inject(CstcStore);
  isMobileApp = this.platformSvc.isMobileApp;
  isWideScreen = this.state.isWideScreen;
}
