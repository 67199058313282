<div class="center">
    <div class="qr-wrapper">
        <div class="ticket-qr"></div>
        <div class="ticket-number"></div>
        <div class="ticket-sum"></div>
    </div>
    <div class="content">
        <div class="content-top">
            <div class="sbp-icon"></div>
            <div class="sub-title"></div>
        </div>
    </div>
</div>
