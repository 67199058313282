import { Component, OnInit, inject, DestroyRef, HostBinding } from '@angular/core';
import { PageService } from '../../../../../services/page/page.service';
import { Observable } from 'rxjs';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { PageData } from '../../../../../services/page/page.model';
import { BannerComponent } from '../../shared/banner/banner.component';
import { CommonModule } from '@angular/common';
import { HtmlWithUrlPipe } from 'src/app/pipes/html-with-url.pipe';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DataService } from 'src/app/services/data/data.service';
import { PageSkeletonComponent } from 'src/app/modules/shared/components/skeleton-loader/page-skeleton/page-skeleton.component';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  providers: [PageService],
  standalone: true,
  imports: [
    CommonModule,
    BannerComponent,
    HtmlWithUrlPipe,
    PageSkeletonComponent
  ]
})
export class PageComponent implements OnInit {
  state = inject(CstcStore);
  destroyRef = inject(DestroyRef);
  dataSvc = inject(DataService);
  platformSvc = inject(PlatformService);

  loading = this.dataSvc.loading;
  isWideScreen = this.state.isWideScreen;
  isStandalone = this.state.isStandalone;
  content$ = new Observable<PageData | undefined>();

  @HostBinding('class') get hostClass() {
    return {
      'isStandalone': this.isStandalone() === true
    }
  }

  constructor(
    private pageSvc: PageService,
    private titleService: Title,
    private meta: Meta,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.content$ = this.pageSvc.content$;
    this.content$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((result) => {
      if (result?.title) {
        this.titleService.setTitle(result.title);
      }
      if (result?.meta) {
        this.meta.addTag({
          name: 'description',
          content: result?.meta?.description ? result.meta.description : '',
        });
      }
    });
  }
  // domSanitizer(value: string|undefined,url?: string|null) {

  //   return value?this.sanitizer.bypassSecurityTrustHtml(this.replaceWithButton(value,url)):'';
  // }
  // replaceWithButton(value: string,replace?:string|null) {
  //   if(!replace) {
  //     return value;
  //   }
  //   return value.replace('{{ google_form }}',`<a class="btn" target="_blank" href="${replace}">Заполнить форму *</a>`)
  // }
}
