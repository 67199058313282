import { ChangeDetectionStrategy, Component, computed, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { fromEvent, Subscription, take } from 'rxjs';

@Component({
  selector: 'cstc-stock-cards-skeleton',
  standalone: true,
  imports: [],
  templateUrl: './stock-cards-skeleton.component.html',
  styleUrl: './stock-cards-skeleton.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockCardsSkeletonComponent {
  loadedCards = Array.from({ length: 8 });
}
