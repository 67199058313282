<article>
    <div class="cinema-list-wrapper">
        <div class="cinema-list">
            @for (item of loadedCinemas; track $index) {
                <div class="cinema-item"></div>
            }
        </div>
        <div class="map-wrapper">
            <div class="map"></div>
            <div class="info"></div>
        </div>
    </div>
</article>
