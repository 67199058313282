import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, inject, HostBinding, input, computed, signal, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { OrderInfoData } from 'src/app/services/order/order.info.model';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  selector: 'cstc-ticket-qr',
  imports: [
    CommonModule
  ],
  templateUrl: './ticket-qr.component.html',
  styleUrls: ['./ticket-qr.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class TicketQrComponent {
  router = inject(Router);
  private state = inject(CstcStore);
  platformSvc = inject(PlatformService);

  kiosk = this.state.isKiosk;
  payment = input<boolean>(false);

  isLoaded = signal(false);
  @Output() isLoadedChanged = new EventEmitter<boolean>();

  @HostBinding('class.qr') get hostClass() {
    return this.payment() === true
  }

  order = input.required<OrderInfoData>();
  qrUrl = computed(() => {
    const order = this.order();
    const isPaymentPage = this.payment();
    let data = '';
    if (!order.id) {
      return null;
    }
    data = (isPaymentPage) ? (order.payment.url || '') : order.id;
    if (!data) {
      return null;
    }
    return `https://qr.cinemastar.ru/?data=${encodeURIComponent(data)}`;
  })

  setLoaded(value: boolean) {
    this.isLoaded.set(value);
    this.isLoadedChanged.emit(value);
  }
}
