import { Injectable, InjectionToken, Renderer2, computed, effect, inject, signal } from '@angular/core';
export const APP_PLATFORM = new InjectionToken<AppPlatform>('CstcPlatformToken');
export const APP_PLATFORM_VERSION = new InjectionToken<string>('CstcPlatformVersionToken');
export const APP_PLATFORM_STORE = new InjectionToken<AppStore>('CstcPlatformStoreToken');
// Declare cordova so TypeScript knows about it
declare const cordova: any;
export type AppPlatform = 'web'|'ios'|'android';

export type AppStore = 'default'|'ru';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  platform = signal<AppPlatform>(inject(APP_PLATFORM));
  versionCurrent = signal<string>(inject(APP_PLATFORM_VERSION));
  versionLatest = signal<string>(inject(APP_PLATFORM_VERSION));
  storeVersion = signal<AppStore>(inject(APP_PLATFORM_STORE));
  updateAvailable = computed(()=>{
    const currentParts = this.versionCurrent().split('.');
    const latestParts = this.versionLatest().split('.');

    for (let i = 0; i < Math.max(currentParts.length, latestParts.length); i++) {
      const currentPart = parseInt(currentParts[i] || '0');
      const latestPart = parseInt(latestParts[i] || '0');

      if (currentPart < latestPart) {
        return true;
      } else if (currentPart > latestPart) {
        return false;
      }
    }
    return false;
  });
  updateUrl = signal<string>('');
  setLatestVersionFromServer(version: string,url: string) {
    this.versionLatest.set(version);
    this.updateUrl.set(url);
  }
  isMobileApp = computed(()=>this.platform() !== 'web');
}
