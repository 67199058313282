import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SimpleGeoPosition } from './geo.model';



@Injectable({
  providedIn: 'root'
})
export class GeoService {
  isBrowser = false;
  location$ = new BehaviorSubject<SimpleGeoPosition|undefined>(undefined);
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }
  deg2rad = (deg: number) => {
    return deg * (Math.PI / 180);
  };
  square = (x: number) => {
    return Math.pow(x, 2);
  };
  haversineDistance = (
    lat1: number,
    lng1: number,
    lat2: number,
    lng2: number
  ) => {
    const r = 6371; // radius of the earth in km
    lat1 = this.deg2rad(lat1);
    lat2 = this.deg2rad(lat2);
    const lat_dif = lat2 - lat1;
    const lng_dif = this.deg2rad(lng2 - lng1);
    const a =
      this.square(Math.sin(lat_dif / 2)) +
      Math.cos(lat1) * Math.cos(lat2) * this.square(Math.sin(lng_dif / 2));
    const d = 2 * r * Math.asin(Math.sqrt(a));
    return Math.round((d + Number.EPSILON) * 100) / 100;
  };
  init(): void {
    if(this.isBrowser) {
      navigator.geolocation.getCurrentPosition((position: GeolocationPosition)=>{
      //navigator.geolocation.watchPosition((position: GeolocationPosition)=>{
        this.location$.next({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
      });
    }
  }
}
