export const menuBottom=[
    {
      name: 'фильмы',
      link: '/films'
    },
    {
      name: 'кинотеатры',
      link: '/cinemas'
    },
    {
      name: 'акции',
      link: '/stocks'
    },
    {
      name: 'вакансии',
      link: '/page/vakansii'
    },
    {
      name: 'для бизнеса',
      link: '/page/dla-biznesa'
    },
    {
      name: 'контакты',
      link: '/page/kontakty'
    },
    {
      name: 'О компании',
      link: '/page/o-kompanii'
    }

  ];
