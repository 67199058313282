import { ChangeDetectionStrategy, Component, HostBinding, inject } from '@angular/core';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  selector: 'cstc-cinema-info-skeleton',
  standalone: true,
  imports: [],
  templateUrl: './cinema-info-skeleton.component.html',
  styleUrl: './cinema-info-skeleton.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CinemaInfoSkeletonComponent {
  state = inject(CstcStore);
  platformSvc = inject(PlatformService);

  @HostBinding('class.kiosk') get kioskConfig() {
    return this.state.isKiosk();
  }
  isMobileApp = this.platformSvc.isMobileApp;
}
