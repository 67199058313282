// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://api.cstc.terrabo.org',
  businessDateShift: 6,
  ymId: 92869871,
  docs: {
    offer: 'dogovor-oferta',
    rules: 'uslovia-pokupki-elektronnogo-bileta',
  },
  defaultCity: {
    id: 1,
    name: 'Москва и МО',
    geo: {
      lat: 55.7743,
      lng: 37.6299,
    },
  },
  platform: 'web',
  store: 'default',
  version: '0.0.65',
  // platform: 'android',
  // store: 'default',
  // version: '1.0.2',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
