<ng-container *ngIf="loading()===false;else page">
  <cstc-films-selection
  [search]="true"
  [selection]="{
    on_sale: true
  }"
  ></cstc-films-selection>
  <cstc-banner type="bottom"></cstc-banner>
  <cstc-films-selection
  [title]="'Скоро в прокате'"
  [selection]="{
    is_future_date: true
  }"
  ></cstc-films-selection>
</ng-container>
<ng-template #page>
  <cstc-movie-card-skeleton/>
</ng-template>
