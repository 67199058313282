import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'cstc-cinema-skeleton',
  standalone: true,
  imports: [],
  templateUrl: './cinema-skeleton.component.html',
  styleUrl: './cinema-skeleton.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CinemaSkeletonComponent {
  loadedCinemas = Array.from({ length: 6 });
}
