import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2, } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from 'src/app/modules/shared/components/button/button.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { CityService } from 'src/app/services/city/city.service';

const SELECTED_ACTIVE_CITY_KEY = 'selectedActiveCity';
const EXPIRY_HOURS = 24;

@Component({
  selector: 'cstc-geolocation-permission',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ButtonComponent,
  ],
  templateUrl: './geolocation-permission.component.html',
  styleUrls: ['./geolocation-permission.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeolocationPermissionComponent implements OnInit, OnDestroy {
  @Output() activeChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  stop$ = new Subject<void>();
  currentCity = this.cityService.currentCity;

  constructor(
    private cityService: CityService,
    private cdr: ChangeDetectorRef,
    private el: ElementRef,
    private renderer: Renderer2
  ) { }
  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
  ngOnInit() {
    this.cityService.filteredCity$
      .pipe(takeUntil(this.stop$))
      .subscribe((currentCity) => {
        const selectedCity = this.cityService.loadSelectedActiveCity();
        if (selectedCity !== null) {
          if (selectedCity === currentCity) {
            this.cityService.saveSelectedActiveCity(currentCity); // Update timer
            this.el.nativeElement.classList.remove('active');
          } else {
            this.handlePermission();
          }
        } else {
          this.handlePermission();
        }
      });

    this.cityService.removeActiveClass$.subscribe(() => {
      this.el.nativeElement.classList.remove('active');
      this.cdr.detectChanges();
    });
  }

  changeCity(e: MouseEvent) {
    e.preventDefault();
    this.cityService.openCityList();
    this.el.nativeElement.classList.remove('active');
  }

  confirmCity(event: MouseEvent) {
    event.preventDefault();
    this.cityService.cityConfirmed();
    // this.el.nativeElement.classList.remove('active');
  }

  private handlePermission() {
    this.cityService
      .requestGeolocationPermission()
      .then((permissionGranted) => {
        if (permissionGranted) {
          this.cityService.updateCityWithCurrentPosition();
        } else {
          const selectedCity = this.cityService.loadSelectedActiveCity();
          if (selectedCity !== null) {
            this.cityService.setCity(selectedCity);
          } else {
            this.cityService.setCity(1); // Default city ID set to 1 when permission is denied
          }
        }
        this.cdr.detectChanges();
      });
  }
}
