import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, catchError, EMPTY, filter, map, switchMap, take, takeWhile, tap } from 'rxjs';
import { OrderInfoData } from 'src/app/services/order/order.info.model';
import { OrderService } from 'src/app/services/order/order.service';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { OrderInfoHeaderComponent } from '../../shared/order/order-info-header/order-info-header.component';
import { ButtonComponent } from 'src/app/modules/shared/components/button/button.component';

@Component({
  selector: 'cstc-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OrderInfoHeaderComponent,
    ButtonComponent,
  ]

})
export class RefundComponent implements OnInit {
  buttonActive = false;
  hideBtn = false;
  alive = true;
  pending$ = new BehaviorSubject(false);
  info$ = new BehaviorSubject<OrderInfoData | undefined>(undefined);
  orderId!: string;
  refundSuccess$ = new BehaviorSubject(false);
  error$ = new BehaviorSubject(false);
  errorMessage$ = new BehaviorSubject('');
  nextStep(event: MouseEvent) {
    event.preventDefault();
    this.hideBtn = !this.hideBtn;
  }
  form = new UntypedFormGroup({
    contact: new UntypedFormControl('',
      [
        Validators.required
        //Validators.pattern("^(?:\d{10}|\w+@\w+\.\w{2,3})$")
      ]
    )
  });;
  inProgress$ = new BehaviorSubject(true);
  inputValue: string = '';
  fieldActive(value: string) {
    this.inputValue = value;
  }
  renew(e: MouseEvent) {
    e.preventDefault();
    this.errorMessage$.next('');
    this.pending$.next(false);
  }
  doRefund(e: MouseEvent) {
    e.preventDefault();
    this.pending$.next(true);
    this.orderSvc.doRefundOrder(this.orderId, this.form.value).pipe(
      tap(() => {
        this.pending$.next(false);
      }),
      catchError(e => {
        this.errorMessage$.next('Не удалось выполнить возврат. Обратитесь в службу технической поддержки.');
        return EMPTY;
      })
    ).subscribe((result) => {
      if (result.success === true) {
        this.refundSuccess$.next(true);
      } else {
        this.errorMessage$.next(result.message);
      }
    })
  }
  maxNameLength = 25;

  constructor(
    private route: ActivatedRoute,
    private orderSvc: OrderService,
    private router: Router
  ) { }
  setResultWithStatusCode(code: number) {
    this.inProgress$.next(false);
    //Снять коммент при релизе
    // if(code!==5) {
    //   this.error$.next(true);
    // }
  }
  ngOnInit(): void {
    this.route.params.pipe(
      takeWhile(() => this.alive),
      filter(params => {
        return typeof params['id'] !== 'undefined';
      }),
      take(1),
      map(params => params['id']),
      switchMap((orderId: string) => {
        return this.orderSvc.getInfo(orderId);
      }),
      catchError(e => {
        this.router.navigate(['/404']);
        return EMPTY;
      })
    ).subscribe(result => {
      this.info$.next(result);
      this.orderId = result.id;
      this.setResultWithStatusCode(result.status);
    });
  }

}
