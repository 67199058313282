<li class="movie-time-item">
    <a *ngIf="!isExpired&&!session.disabled" [routerLink]="['/order/session',session.id]">
        <ng-container *ngTemplateOutlet="buttonInnerTemplate"></ng-container>
    </a>
    <span *ngIf="isExpired||session.disabled===true" class="expired">
      <ng-container *ngTemplateOutlet="buttonInnerTemplate"></ng-container>
    </span>
</li>
<ng-template #buttonInnerTemplate>
  <p class="movie-time">{{session.showtime|toDate| date:"HH:mm"}}</p>
  <p *ngIf="session.standard_price" class="movie-price">{{getItemPrice(session.standard_price)}} <span>₽</span></p>
</ng-template>
