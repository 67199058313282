import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input, signal, inject, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'cstc-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule
  ]
})
export class SearchComponent implements OnDestroy{
  @Input() isWideScreen!: boolean;
  searchSvc = inject(SearchService);
  searchInput = '';

  modelChanged(text: string) {
    this.searchSvc.search.set(text);
  }

  ngOnDestroy(): void {
    this.searchSvc.search.set('');
  }

}
