<div class="container">
    <div class="container-text">
        <p class="title"><span>Ваш город —</span></p>
        <p class="text">{{currentCity()?.name}}</p>
    </div>
    <div class="btn-holder other">
        <a href="#" cstc-button="secondary" (click)="changeCity($event)">другой</a>
    </div>
    <div class="btn-holder yes">
        <a href="#" cstc-button="primary" (click)="confirmCity($event)">да</a>
    </div>
</div>
