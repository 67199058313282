import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Input, HostBinding, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'a[cstc-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ButtonComponent implements OnInit {

  @Input() disabled = false;

  @Input('cstc-button') appButton: 'primary' | 'secondary' = 'secondary';

  @HostBinding('class.btn-primary') get primary(): boolean {
    return this.appButton === 'primary';
  }
  @HostBinding('class.btn-secondary') get secondary(): boolean {
    return this.appButton === 'secondary';
  }

  //**для перехода по ссылке */
  @Input() url = "";
  @Output() btnClick = new EventEmitter();

  click(event: MouseEvent) {
    if( this.url === ""){
      event.preventDefault();
    }
    this.btnClick.emit(event);
  }

  constructor() { }

  ngOnInit(): void {
  }

}
