<article>
  <ng-container *ngIf="banner$|async as banner">
    <div class="banner-holder">
      <a [href]="banner.url" (click)="openLink($event,banner.url)">
        <img [src]="banner.media.desktop" alt="" *ngIf="isWideScreen(); else mobile">
      </a>
    </div>
    <ng-template #mobile>
        <img class="bottom" [src]="banner.media.mobile" alt="">
    </ng-template>
  </ng-container>
</article>
