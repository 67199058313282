import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, inject } from '@angular/core';
import { Film, ScheduleFormat } from 'src/app/services/schedule/schedule.model';
import { TrailerPopupService } from 'src/app/services/trailer-popup/trailer-popup.service';
import { SessionScheduleComponent } from '../../../format-sheldule/format-sheldule.component';
import { RouterModule } from '@angular/router';
import { AdjustFontDirective } from 'src/app/modules/shared/directives/adjust-font.directive';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  selector: 'cstc-schedule-film-card',
  templateUrl: './schedule-film-card.component.html',
  styleUrls: ['./schedule-film-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SessionScheduleComponent,
    AdjustFontDirective,
  ]
})
export class ScheduleFilmCardComponent implements OnInit {
  private state = inject(CstcStore);

  maxNameLength = 22;
  @Input() film!: Film;
  @Input() formats!: ScheduleFormat[];

  kiosk = this.state.isKiosk;

  constructor(
    public movieTrailerPopupSvc: TrailerPopupService
  ) { }

  ngOnInit(): void {
  }

}
