<article *ngIf="info$|async as order">
    <div class="refund-page">
        <ng-container *ngIf="error$|async; else default">
          Не удалось найти заказ или он уже был отменён
        </ng-container>
        <ng-template #default>
          <div class="refund-film-img">
              <img [src]="order.session.poster?order.session.poster:'assets/img/placeholders/film_poster_placeholder.jpg'" alt="">
          </div>
          <div class="refund-info">
              <ng-container *ngIf="!(refundSuccess$|async);else successRefund">
                <cstc-order-info-header [session]="order.session" [theatre]="order.theatre"></cstc-order-info-header>
                <div class="refund-rule">
                    <h2>Возврат заказа</h2>
                    <p class="refund-rule">Вы собираетесь вернуть свой заказ. Это возможно не позднее 30 минут до начала киносеанса.</p>
                    <p class="refund-rule">Вам необходимо ввести E-mail или номер телефона для подтверждения возврата.</p>
                </div>

                <div class="error" *ngIf="errorMessage$|async as message; else formRefund">
                    <h2>Не удалось выполнить возврат</h2>
                    <p>{{message}}</p>
                    <div class="btn-holder">
                        <a href="#" cstc-button="secondary" (click)="renew($event)">Еще раз</a>
                    </div>
                </div>
                <ng-template #formRefund>
                  <form action="" class="form-holder" [ngClass]="{visible: hideBtn}" [formGroup]="form">
                    <div
                      [ngClass]="{
                        'has-error': !form.get('contact')?.valid && form.get('contact')?.touched,
                        'has-feedback': !form.get('contact')?.valid && form.get('contact')?.touched
                      }"
                    >
                      <input [ngClass]="{disabled: (pending$|async)||false}" type="text" formControlName="contact" placeholder="e-mail или телефон">
                      <div class="field-divider"></div>
                    </div>
                    <div class="btn-holder">
                        <a href="#refund" (click)="doRefund($event)" cstc-button="secondary" [ngClass]="{disabled: !form.valid,pending:pending$|async}">вернуть заказ</a>
                    </div>
                  </form>
                  <div class="btn-holder">
                      <a href="#" cstc-button="secondary" (click)="nextStep($event)" [ngClass]="{hidden: hideBtn}">продолжить</a>
                  </div>
                </ng-template>
              </ng-container>
              <ng-template #successRefund>
                <div class="error">
                  <h2>Заказ возвращен</h2>
                  <p class="refund-rule">Деньги поступят на карту, с которой проводилась оплата, в течение 10 банковских дней.</p>
                </div>
              </ng-template>
          </div>
        </ng-template>
    </div>
</article>
