import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'cstc-refund-result',
  templateUrl: './refund-result.component.html',
  styleUrls: ['./refund-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class RefundResultComponent implements OnInit {
  maxNameLength = 25;
  lucky: boolean = true;

  info = {
    film: 'Lorem ipsum dolor sit amet consectetur.',
    metro: 'Lorem ipsum dolor sit amet consectetur.',
    date: '30 сентября 2022',
    time: '23:23',
    format: 'atmos',
    hall: 'зал VIP'
  }

  constructor() { }

  ngOnInit(): void {
  }

}
