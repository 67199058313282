import { Component, OnInit, ChangeDetectionStrategy, inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { BackdropComponent } from 'src/app/modules/shared/components/backdrop/backdrop.component';
import { TrapScrollDirective } from 'src/app/modules/shared/directives/trap-scroll.directive';
import { TrailerPopupService } from 'src/app/services/trailer-popup/trailer-popup.service';

@Component({
  selector: 'cstc-movie-trailer-popup',
  templateUrl: './movie-trailer-popup.component.html',
  styleUrls: ['./movie-trailer-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    BackdropComponent,
    TrapScrollDirective,
  ]
})
export class MovieTrailerPopupComponent implements AfterViewInit {
  trailerPopupSvc = inject(TrailerPopupService);

  playButtonVisible: boolean = true;
  @ViewChild('playVideo', { static: false }) playVideo!: ElementRef<HTMLVideoElement>;

  ngAfterViewInit(): void {
    this.playVideo.nativeElement.addEventListener('ended', () => this.close());
  }

  playBtn(event: MouseEvent, player: any) {
    event.preventDefault();
    player.play();
  }
  hidePlay() {
    this.playButtonVisible = false;
  }

  close(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
    }
    this.trailerPopupSvc.close();
  }
}
