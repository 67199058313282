import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CinemasBannerService {
  private showBannerSubject = new BehaviorSubject<boolean>(true);
  showBanner$ = this.showBannerSubject.asObservable();
  toggleShowBanner() {
    this.showBannerSubject.next(!this.showBannerSubject.value);
  }
  updateShowBanner(value: boolean) {
    this.showBannerSubject.next(value);
  }
  showBanner(): void {
    this.showBannerSubject.next(true);
  }

  hideBanner(): void {
    this.showBannerSubject.next(false);
  }

  constructor() { }

}
