<form [formGroup]="form" (ngSubmit)="onSubmit()" class="form">
    <div class="city-wrapper">
        <label for="city" class="label">Выберите город:</label>
        <select id="city" formControlName="city" class="select">
            <option value="" disabled selected hidden class="option placeholder">Город</option>
            <option *ngFor="let city of cityList()" [value]="city.id" class="option">{{city.name}}</option>
        </select>
        <span *ngIf="invalidField('city')===true" class="error">Выберите город</span>
        <span class="icon-wrapper" iconName="arrow"></span>
    </div>
    <div class="cinema-wrapper" *ngIf="cityIsSelected()">
        <label for="cinema" class="label">Выберите кинотеатр:</label>
        <select id="cinema" formControlName="cinema" class="select" *ngIf="isLoading()===false;else loading">
            <option value="" disabled selected hidden class="option placeholder">Кинотеатр</option>
            <option *ngFor="let cinema of theatreList$|async" [value]="cinema.id" class="option">
                {{cinema.name}}</option>
        </select>
        <span *ngIf="invalidField('cinema')===true" class="error">Выберите кинотеатр</span>
        <ng-template #loading>
            <div class="loading">
                <div class="loader"></div>
            </div>
        </ng-template>
        <span class="icon-wrapper" iconName="arrow"></span>
    </div>
    <div class="button-wrapper">
        <a cstc-button="primary" class="primary-btn" (click)="onSubmit()">Сохранить</a>
    </div>
    <div class="dialog-wrapper" *ngIf="showDialog()===true">
        <div class="dialog">
            <p class="label">Вы уверены в своём выборе?</p>
            <p class="selected">Город: <span class="name">{{selectedCityName}}</span></p>
            <p class="selected">Кинотеатр: <span class="name">{{selectedCinemaName}}</span></p>
            <div class="button-wrapper">
                <a cstc-button="secondary" class="secondary-btn" (click)="isCanceled()">отменить</a>
                <a cstc-button="primary" class="primary-btn" (click)="isAgreed()">уверен(а)</a>
            </div>
        </div>
    </div>
</form>