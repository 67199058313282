import { CommonModule } from '@angular/common';
import { Component, HostBinding, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { BannerDirectoryItem } from 'src/app/services/data/data.model';
import { DataService } from 'src/app/services/data/data.service';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  selector: 'cstc-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class BannerComponent implements OnInit, OnDestroy {
  state = inject(CstcStore);
  stop$ = new Subject<void>();
  @Input() type: 'top' | 'bottom' = 'top';
  banner$!: Observable<BannerDirectoryItem | undefined>;
  isWideScreen = this.state.isWideScreen;
  constructor(
    private dataSvc: DataService,
    private platform: PlatformService,
    private router: Router,

  ) { }
  @HostBinding('class') get hostClass() {
    return {
      'kiosk': this.state.isKiosk(),
      'mobile': this.platform.isMobileApp(),
      'isStandalone': this.state.isStandalone(),
    }
  }
  isHttpUrl(url: string): boolean {
    return /^https?:\/\//i.test(url);
  }
  ngOnInit(): void {

    this.banner$ = ((this.type === 'bottom') ? this.dataSvc.bottom$ : this.dataSvc.top$).pipe(
      map(res => {
        if (typeof res === 'undefined') {
          return res;
        }
        return res[Math.floor(Math.random() * res.length)] as BannerDirectoryItem;
      })
    )
  }
  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
  openLink(event: MouseEvent, url: string) {
    event.preventDefault();
    if (!this.isHttpUrl(url)) {
      // Prevent the default click behavior for internal URLs
      this.router.navigateByUrl('/film/245');
    } else {
      window.open(url, '_blank');
    }
  }
  openURLInNewWindow(url: string) {
    window.open(url, '_blank'); // '_blank' opens the URL in a new tab or window
  }
}
