import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, computed, effect, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  BehaviorSubject,
  filter,
  map,
  distinctUntilChanged,
  tap,
  take,
  Subject,
  Observable,
} from 'rxjs';
import { cachedRequest } from 'src/app/decorators/cache.decorator';
import { CookieService } from '../cookie/cookie.service';

import {
  CityItem,
  CITY_COOKIE_NAME,
  DEFAULT_CITY_ID,
  CityListResponseData,
} from './city.model';

const SELECTED_ACTIVE_CITY_KEY = 'selectedActiveCity';
const EXPIRY_HOURS = 24;

@Injectable({
  providedIn: 'root',
})
export class CityService {
  city$ = new BehaviorSubject<number | null>(null);
  filteredCity$ = this.city$.pipe(
    filter((value) => value !== null),
    map((cityId) => cityId as number)
  );
  currentCityId = signal<number>(DEFAULT_CITY_ID);
  cityList = signal<CityItem[]>([]);
  currentCity = computed(() => {
    const currentCityId = this.currentCityId();
    const cityList = this.cityList();
    const currentCity = cityList.find((city) => city.id === currentCityId);
    const result = currentCity || null;
    return result;
  });
  selectedActiveCity$ = new BehaviorSubject<number | null>(null);
  detection$ = new BehaviorSubject(false);
  loading$ = new BehaviorSubject(true);
  toggleCityList$ = new Subject<void>();
  removeActiveClass$ = new Subject<void>();
  cityListOpened = signal(false);
  private isFirstNavigation = signal(true);

  constructor(
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    const currentSavedCity = this.cookieService.getCookie(CITY_COOKIE_NAME);
    const cityIdChangeEffect = effect(() => {
      const cityId = this.currentCityId();
      this.city$.next(cityId);
      // this.saveSelectedActiveCity(cityId);
    });
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => {
          return (event as NavigationEnd).url.replace(/ *\([^)]*\) */g, '');
        }),
        distinctUntilChanged()
      )
      .subscribe(() => {
        if (this.isFirstNavigation()) {
          this.setDefaultCity();
          this.isFirstNavigation.set(false);
        } else {
          this.cityConfirmed();
        }
      });

    // Initialize selectedActiveCity from local storage if available
    const selectedCity = this.loadSelectedActiveCity();
    if (selectedCity !== null) {
      this.selectedActiveCity$.next(selectedCity);
    }

    this.initCityList();
    this.initializeSelectedActiveCity();
  }

  currentCityCoords = computed(() => {
    const cityId = this.currentCityId();
    const cityList = this.cityList();
    if (!cityList) {
      return undefined;
    }
    const currentCity = cityList.filter((cityListItem) => {
      return cityListItem.id === cityId;
    })[0];
    if (!currentCity.longitude || !currentCity.latitude) {
      return undefined;
    }
    return {
      longitude: currentCity.longitude,
      latitude: currentCity.latitude,
    };
  });

  initCityList() {
    this.getCityList()
      .pipe(
        take(1),
        map(
          (res: HttpResponse<CityListResponseData>) =>
            res.body?.data as CityItem[]
        )
      )
      .subscribe((result: CityItem[]) => {
        this.loading$.next(false);
        this.cityList.set(result);
      });
  }

  @cachedRequest()
  getCityList() {
    return this.httpClient.get<CityListResponseData>(`${this.baseUrl}/city`, {
      observe: 'response',
    });
  }

  setCity(id: number, force = false) {
    if (id !== this.currentCityId() || force) {
      this.cookieService.setCookie({
        name: CITY_COOKIE_NAME,
        value: id.toString(),
        session: true,
        path: '/',
      });
      this.currentCityId.set(id);
      // this.saveSelectedActiveCity(id);
    }
  }

  initializeSelectedActiveCity() {
    const selectedCity = this.loadSelectedActiveCity();
    if (selectedCity !== null) {
      this.setCity(selectedCity, true);
      this.selectedActiveCity$.next(selectedCity);
    } else {
      this.setDefaultCity();
    }
  }

  setDefaultCity() {
    const urlSelectedCity = this.route.snapshot.queryParams['city'];
    const currentSavedCity = this.cookieService.getCookie(CITY_COOKIE_NAME);
    const selectedCity = this.loadSelectedActiveCity();

    if (selectedCity !== null) {
      this.setCity(selectedCity);
    } else if (urlSelectedCity) {
      this.setCity(parseInt(urlSelectedCity));
    } else if (currentSavedCity) {
      this.setCity(parseInt(currentSavedCity));
    } else {
      this.handleGeolocationPermission();
    }
  }

  private handleGeolocationPermission() {
    this.requestGeolocationPermission().then((permissionGranted) => {
      if (permissionGranted) {
        this.updateCityWithCurrentPosition();
      } else {
        const selectedCity = this.loadSelectedActiveCity();
        if (selectedCity !== null) {
          this.setCity(selectedCity, true);
        } else {
          this.setCity(DEFAULT_CITY_ID);
        }
      }
    });
  }

  requestGeolocationPermission(): Promise<boolean> {
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(true);
        },
        (error) => {
          resolve(false);
        }
      );
    });
  }

  updateCityWithCurrentPosition() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const closestCity = this.getClosestCityFromList(latitude, longitude);
        if (closestCity) {
          this.setCity(closestCity.id);
        }
      },
      (error) => {
        this.setCity(DEFAULT_CITY_ID);
      }
    );
  }

  getClosestCityFromList(
    latitude: number,
    longitude: number
  ): CityItem | undefined {
    let closestCity: CityItem | undefined;
    let minDistance = Number.MAX_VALUE;
    this.cityList().forEach((city) => {
      const distance = this.calculateDistance(
        latitude,
        longitude,
        city.latitude,
        city.longitude
      );
      if (distance < minDistance) {
        minDistance = distance;
        closestCity = city;
      }
    });
    return closestCity;
  }

  private calculateDistance(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ): number {
    const toRad = (value: number) => (value * Math.PI) / 180;
    const R = 6371; // km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  setLocalStorageWithExpiry(key: string, value: any, expiryHours: number) {
    const now = new Date();
    const expiryTime = now.getTime() + expiryHours * 60 * 60 * 1000; // Convert hours to milliseconds
    const item = {
      value: value,
      expiry: expiryTime,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  loadSelectedActiveCity(): number | null {
    const itemStr = localStorage.getItem(SELECTED_ACTIVE_CITY_KEY);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(SELECTED_ACTIVE_CITY_KEY);
      return null;
    }
    return item.value;
  }

  saveSelectedActiveCity(cityId: number) {
    this.setLocalStorageWithExpiry(
      SELECTED_ACTIVE_CITY_KEY,
      cityId,
      EXPIRY_HOURS
    );
    this.selectedActiveCity$.next(cityId);
  }

  openCityList() {
    this.cityListOpened.set(true);
  }
  closeCityList() {
    this.cityListOpened.set(false);
  }

  selectCity(id: number) {
    this.setCity(id);
    this.saveSelectedActiveCity(id);
  }

  cityConfirmed() {
    const currentCity = this.currentCityId();
    this.saveSelectedActiveCity(currentCity);
    this.removeActiveClass$.next();
  }
}
