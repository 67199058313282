import { ChangeDetectionStrategy, Component, DestroyRef, HostBinding, inject, input, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, EMPTY, filter, interval, startWith, switchMap, tap } from 'rxjs';
import { ButtonComponent } from 'src/app/modules/shared/components/button/button.component';
import { OrderInfoData } from 'src/app/services/order/order.info.model';
import { OrderService } from 'src/app/services/order/order.service';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { TicketQrComponent } from '../../shared/order/ticket-qr/ticket-qr.component';
import { CommonModule } from '@angular/common';
import { SvgIconDirective } from 'src/app/modules/shared/directives/svg-icon.directive';
import { QrSkeletonComponent } from 'src/app/modules/shared/components/skeleton-loader/qr-skeleton/qr-skeleton.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConfirmDialogService } from 'src/app/modules/shared/components/confirm-dialog/confirm-dialog.service';
import { ConfirmDialogComponent } from 'src/app/modules/shared/components/confirm-dialog/confirm-dialog.component';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  selector: 'cstc-qr-pay',
  templateUrl: './qr-pay.component.html',
  styleUrls: ['./qr-pay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TicketQrComponent,
    SvgIconDirective,
    QrSkeletonComponent,
    ButtonComponent,
    ConfirmDialogComponent,
  ]
})
export class QrPayComponent implements OnInit {
  platformSvc = inject(PlatformService);
  state = inject(CstcStore);
  route = inject(ActivatedRoute);
  orderSvc = inject(OrderService);
  destroyRef = inject(DestroyRef);
  router = inject(Router);
  confirmDialogSvc = inject(ConfirmDialogService);

  kiosk = this.state.isKiosk;
  isStandalone = this.state.isStandalone;
  id = input<string>()

  info = signal<OrderInfoData | undefined>(undefined);
  initialLoading = signal(true);
  error = signal<string | null>(null);

  loadingDelay = 500;
  isLoaded = signal(false);
  onLoadedChange(value: boolean) {
    this.isLoaded.set(value);
  }
  @HostBinding('class') get hostClass() {
    return {
      'kiosk': this.kiosk(),
      'isStandalone': this.isStandalone()
    }
  }

  ngOnInit(): void {
    const orderId = this.id();
    if (!orderId) {
      this.router.navigate(['/404']);
      return;
    }
    /**
     * Polling logic:
     * - Start polling every 10 seconds.
     * - Fetch order info.
     * - Stop polling when payment.status > 0.
     * - Redirect to result page when condition is met.
     */
    interval(10_000) // Poll every 10 seconds
      .pipe(
        startWith(0), // Trigger immediately on init
        /*
        takeUntilDestroyed(this.destroyRef), // Stop polling on component destroy
        */
        switchMap(() =>
          this.orderSvc.getInfo(orderId).pipe(
            catchError((error) => {
              this.error.set('Не удалось загрузить данные заказа');
              this.initialLoading.set(false);
              this.router.navigate(['/404']);
              return EMPTY;
            })
          )
        ),
        tap((orderInfo) => {
          this.info.set(orderInfo);
          setTimeout(() => this.initialLoading.set(false), this.loadingDelay)
        }), // Update state
        filter((orderInfo) => !!orderInfo && orderInfo.payment?.status > 0), // Check for valid payment status
        takeUntilDestroyed(this.destroyRef) // Ensure stream stops on destroy
      )
      .subscribe((orderInfo) => {
        // Redirect to result page when payment completed
        this.router.navigate(['/result', orderInfo!.id]);
      });
  }

  openDialog(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
    }
    this.confirmDialogSvc.open(
      false,
      'Отменить заказ',
      'Вы уверены что хотите отменить текущий заказ?',
      false,
      'Да',
      'Нет',
      true,
      'Окно будет закрыто через',
      30,
      () => {
        const order = this.info();
        if (!order) {
          console.log('ошибка заказа');
          return
        }
        this.orderSvc.cancelOrder(order.id).subscribe(() => {
          console.log('Заказ отменен');
          this.redirectToHome(order);
        })
      },
    )
  }

  redirectToHome(order: OrderInfoData, event?: MouseEvent) {
    if (event) {
      event.preventDefault();
    }
    this.router.navigate(['/order/session', order.session.id])
  }
}
