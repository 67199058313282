    <div class="order-film-img" *ngIf="img">
        <img src="{{session.poster}}" alt="">
        <div class="rating-holder">
            <p class="rating">{{session.age_restriction}}+</p>
        </div>
        <!-- <div class="order-film-img-overlay"></div> -->
    </div>
    <div class="order-film-text">
        <p class="title">{{session.film.length>maxNameLength?session.film.substring(0,maxNameLength)+'...':session.film}}</p>
        <div class="info-block">
            <p class="cinema">{{session.theatre.length>maxNameLength?session.theatre.substring(0,maxNameLength)+'...':session.theatre}}</p>

            <!-- <cstc-price-legend></cstc-price-legend> -->


            <!-- <div class="rating-holder">
                <p class="rating">{{session.age_restriction}} +</p>
            </div> -->
        </div>
        <div class="title-block">
            <p class="date-holder"><span class="date">{{session.showtime| toDate | date:'dd LLL yyyy':'ru'}}</span> • <span class="time">{{session.showtime|toDate| date:"HH:mm"}}</span></p>
            <p class="format">{{session.format}}</p>
            <p class="hall">зал {{session.hall}}</p>
        </div>
        <ng-container *ngIf="!inner">
          <cstc-price-legend *ngIf="priceList$|async as price" [price]="price" scrollDetection></cstc-price-legend>
        </ng-container>
    </div>
