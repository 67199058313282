import { AfterViewInit, Directive, ElementRef, OnDestroy, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Directive({
  selector: '[adjustFont]',
  standalone: true,
})
export class AdjustFontDirective implements AfterViewInit, OnDestroy{
  el = inject(ElementRef);
  responsiveSvc = inject(ResponsiveService);
  private isMobileSubscription: Subscription | undefined;
  ngAfterViewInit(): void {
    this.adjustFontSize();
  }
  adjustFontSize(): void {
    this.isMobileSubscription = this.responsiveSvc.isWideScreen$.subscribe((isWideScreen: boolean) => {
      if (!isWideScreen) {
        const element: HTMLElement = this.el.nativeElement;
        const textContent: string = element.textContent || '';

        if (textContent.trim() !== '') {
          const words: string[] = textContent.split(' ');

          if (words.length >= 6 || textContent.length >= 11) {
            element.style.fontSize = '16px';
            element.style.lineHeight = '120%';
          }
        }
      }
    });
  }
  ngOnDestroy(): void {
    if (this.isMobileSubscription) {
      this.isMobileSubscription.unsubscribe();
    }
  }
}
