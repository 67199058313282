@if (loading()===false) {
    <article *ngIf="items$|async as stockList">
        <div class="stock-card-list" #list
            [style.grid-template-columns]="stockList.length < per_row ?
            'repeat(auto-fit, minmax(311px,' + (list.offsetWidth - (per_row - 1)*32)/4 + 'px))' :
            'repeat(auto-fit, minmax(311px, 1fr))'"
        >
            <ng-container *ngIf="stockList">
                <ng-container *ngFor="let item of stockList">
                    <cstc-stock-card [item]="item" #item
                        [style.max-width]="stockList.length < per_row ?
                        (list.offsetWidth - (per_row - 1)*32)/4 + 'px' : ''"
                    ></cstc-stock-card>
                </ng-container>
            </ng-container>
        </div>
        @if (loadMoreItems()===true) {
            <cstc-loader class="inline"></cstc-loader>
        }
        @if (page * per_page < totalItems) {
            <div class="btn-holder">
                <a (click)="showMoreItems()" cstc-button="secondary">Загрузить ещё</a>
            </div>
        }
    </article>
}@else {
    <cstc-stock-cards-skeleton/>
}

<cstc-banner type="bottom"></cstc-banner>
