@let film = item();
<div class="movie-card">
    <a
      [routerLink]="scheduleRoute()"
      [queryParams]="scheduleQueryParams()"
      class="movie-img {{type}}"
    >
        <div class="poster-wrapper">
            <div class="movie-img-holder">
                <img src="{{ film.media.poster_vertical }}" alt="">
                <!-- <ng-container *ngIf="film.is_pushkin===true">
                    <div class="img-overlay"></div>
                </ng-container> -->
                <ng-container *ngIf="film.is_pushkin===true||film.sticker">
                    <div class="movie-card-hover" [ngClass]="{default: !!film.sticker}">
                        <!-- <img src="assets/img/Name_color 1.png" alt=""> -->
                        <p *ngIf="film.sticker; else pushkinSticker" class="sticker">
                            <span class="kinozoom">{{film.sticker}}</span>
                            <span>билеты только онлайн</span>
                        </p>
                        <ng-template #pushkinSticker>
                          <p>Пушкинская&nbsp;карта</p>
                        </ng-template>
                        <!-- <div class="movie-card-overlay"></div> -->
                    </div>
                </ng-container>
            </div>
            <div class="movie-age">
                <span>{{film.age_restriction}}+</span>
            </div>
        </div>
        <p class="movie-name" [style.hyphens]="(longestWord()>maxWordWidth)&&isWideScreen()===false?'auto':'none'">{{film.name_short?film.name_short:film.name}}</p>
        <div class="movie-category">
            <p>{{film.genres.join(', ')}}</p>
        </div>
    </a>
</div>
