<div class="bar-info-block">
    <div class="title-block">
        <p class="table-title">Бар</p>
        <div class="title-img-wrapper">
            <img src="assets/img/bar-children.png" alt="" class="title-img">
        </div>
    </div>
    <div class="table-divider"></div>
    <div class="bar-info-table">
        <ul>
            <li *ngFor="let item of bar">{{item.name}}
                <span>{{item.amount}}</span>
            </li>
        </ul>
    </div>
</div>

