import { ChangeDetectionStrategy, Component, HostBinding, inject } from '@angular/core';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  selector: 'cstc-qr-skeleton',
  standalone: true,
  imports: [],
  templateUrl: './qr-skeleton.component.html',
  styleUrl: './qr-skeleton.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrSkeletonComponent {
  state = inject(CstcStore);

  @HostBinding('class') get hostClass() {
    return {
      'kiosk': this.state.isKiosk()
    }
  }
}
