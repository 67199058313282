import { Component, OnInit, OnDestroy, inject, HostBinding } from '@angular/core';
import { DataService } from '../../../../../services/data/data.service';
import { CityService } from 'src/app/services/city/city.service';
import { Router } from '@angular/router';
import { CinemasBannerService } from 'src/app/services/cinema/cinemas-banner.service';
import { AngularYandexMapsModule } from 'angular8-yandex-maps';
import { CommonModule } from '@angular/common';
import { CinemaListItemComponent } from './components/cinema-list-item/cinema-list-item.component';
import { BannerComponent } from '../../shared/banner/banner.component';
import { ButtonComponent } from 'src/app/modules/shared/components/button/button.component';
import { CinemaSkeletonComponent } from 'src/app/modules/shared/components/skeleton-loader/cinema-skeleton/cinema-skeleton.component';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  selector: 'cstc-cinemas',
  templateUrl: './cinemas.component.html',
  styleUrls: ['./cinemas.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CinemaListItemComponent,
    CinemaSkeletonComponent,
    AngularYandexMapsModule,
    BannerComponent,
    ButtonComponent,
  ]
})
export class CinemasComponent implements OnInit, OnDestroy {
  state = inject(CstcStore);
  private cityService = inject(CityService);
  private dataSvc = inject(DataService);
  private router = inject(Router);
  alive = true;
  city$ = this.cityService.filteredCity$;
  currentCityCoords = this.cityService.currentCityCoords;
  loading = this.dataSvc.loading;
  isWideScreen = this.state.isWideScreen;
  cinema$ = this.dataSvc.theatres$;
  @HostBinding('class.isStandalone') hostClass() {
    return this.state.isStandalone();
  }

  opened = false;
  openMap(event: MouseEvent) {
    event.preventDefault();
    this.opened = !this.opened;
  }

  cinemaSvc = inject(CinemasBannerService);
  toggleState: boolean = false;

  showBanner$ = this.cinemaSvc.showBanner$;
  hideBannerText: string = 'Скрыть баннеры';
  showBannerText: string = 'Показать баннеры';

  constructor(

  ) { }

  ngOnInit(): void {
    this.cinemaSvc.showBanner$.subscribe((isChecked) => {
      this.toggleState = isChecked;
    });
  }
  showBanner(event: MouseEvent) {
    event.preventDefault();
    this.cinemaSvc.toggleShowBanner();
  }
  onToggleChange(isChecked: boolean) {
    if (isChecked) {
      this.cinemaSvc.showBanner();
    } else {
      this.cinemaSvc.hideBanner();
    }
  }

  goToCinema(cinemaId: number | string) {
    this.router.navigate(['/cinema', cinemaId]);
  }
  ngOnDestroy(): void {
    this.alive = false;
  }
}
