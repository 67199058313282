<div class="ticket-item">
    <div class="qr-block" [class.kiosk]="kiosk()===true">
        <div class="qr-placeholder" [class.hidden]="isLoaded()"></div>
        <img [src]="qrUrl()" alt="" (load)="setLoaded(true)" (error)="setLoaded(false)" [class.hidden]="!isLoaded()">
    </div>
    <div class="ticket-number">
        <p class="ticket-number-title">заказ</p>
        <p class="ticket-number">{{ order().id }}</p>
    </div>
    <!-- <div class="wallet-btn">
        <a href="#">
            <img src="assets/img/apple-wallet.svg" alt="">
        </a>
    </div> -->
</div>
<div class="sum-block">
    <p class="sum-title">сумма</p>
    <p class="sum-item">{{ order().amount/100 }}&nbsp;₽</p>
</div>
<!-- http://localhost:4200/result/D27-647596019 -->