<cstc-backdrop trapScroll></cstc-backdrop>
<div class="trailer-wrapper" (click)="close($event)">
  <a href="#" (click)="close($event)" class="close">
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.0005 2L2.00049 24M2.00049 2L24.0005 24" stroke="white" stroke-width="4" />
    </svg>
  </a>
  <div class="video-wrapper">
    <!-- <video [src]="movieTrailerPopupSvc.trailerUrl" [autoplay]="play? value : !value" controls></video> -->
    <video #playVideo controls poster="{{trailerPopupSvc.coverUrl}}" (play)="hidePlay()">
      <source [src]="trailerPopupSvc.trailerUrl" />
      К сожалению на данный момент видео недоступно
    </video>
    <!-- <div class="btn-wrapper" (click)="playBtn($event, playVideo)" *ngIf="playButtonVisible">
      <div class="play-btn">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.05 36.05C18.3833 36.4833 17.708 36.508 17.024 36.124C16.3413 35.7413 16 35.15 16 34.35V13.65C16 12.85 16.3413 12.258 17.024 11.874C17.708 11.4913 18.3833 11.5166 19.05 11.95L35.35 22.3C35.95 22.7 36.25 23.2666 36.25 24C36.25 24.7333 35.95 25.3 35.35 25.7L19.05 36.05Z" fill="white"/>
        </svg>        
      </div>
      <div class="btn-bg"></div>
    </div> -->
  </div>
</div>