<div class="film-poster-wrapper">
    <a (click)="movieTrailerPopupSvc.open($event,film)" href="#">
        <img [src]="film.media.poster_vertical?film.media.poster_vertical:'/assets/img/placeholders/film_poster_placeholder.jpg'"
            alt="" class="film-poster">
        <div class="play-btn" *ngIf="film.media.trailer?.video||film.media.video">
            <div class="play-icon"></div>
        </div>
        <span class="film-age">{{film.age_restriction}}+</span>
    </a>
</div>
<div class="film-info">
    <a [routerLink]="kiosk()===true ? null: ['/film', film.id]">
        <!-- <span class="film-name">{{film.name.length>maxNameLength?film.name.substring(0,maxNameLength)+'...':film.name}}</span> -->
        <span class="film-name" adjustFont>{{film.name_short?film.name_short:film.name}}</span>
    </a>
    <i>
        <p class="film-genres">{{film.genres.join(', ')}}</p>
    </i>
    <div class="film-more">
        <ng-container *ngIf="film.is_pushkin||!!film.sticker">
            <div class="pushkin-wrapper" [ngClass]="{default: !!film.sticker}">
                <p *ngIf="film.sticker; else pushkinSticker" class="pushkin-card">{{film.sticker}}</p>
                <ng-template #pushkinSticker>
                    <p class="pushkin-card">Пушкинская&nbsp;карта</p>
                </ng-template>
            </div>
        </ng-container>
    </div>
</div>
<div class="time-wrapper" [ngClass]="{filmTimeWrapper: true}">
    <cstc-format-sheldule *ngFor="let item of formats" [format]="item" class="format-schedule"></cstc-format-sheldule>
</div>
