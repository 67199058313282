import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild, computed, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { UrlHandlerDirective } from 'src/app/modules/shared/directives/urlHandler.directive';
import { BannerDirectoryItem } from 'src/app/services/data/data.model';
import { DataService } from 'src/app/services/data/data.service';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { CstcStore } from 'src/app/store/state.store';
@Component({
  selector: 'cstc-carousel',
  standalone: true,
  imports: [
    CommonModule,
    CarouselModule,
    UrlHandlerDirective,
  ],
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit, OnDestroy {
  state = inject(CstcStore);
  banners = signal<BannerDirectoryItem[] | null>(null);

  options = computed(() => {
    let options = this.customOptions;
    const banners = this.banners();
    if (Array.isArray(banners) && banners.length <= 1) {
      options.autoplay = false;
      options.loop = false;
      options.mouseDrag = false;
      options.touchDrag = false;
      options.pullDrag = false;
      options.dots = false;
    }
    return options;
  });

  isWide = this.state.isWideScreen;
  private dataSvc = inject(DataService);
  private platform = inject(PlatformService);
  private router = inject(Router);
  stop$ = new Subject<void>();
  banners$ = this.dataSvc.top$;
  @Input() isWideScreen$!: Observable<boolean>;

  isMobileApp = this.platform.isMobileApp;
  @HostBinding('class.mobile') get insideMobile() {
    return this.isMobileApp();
  }

  @ViewChild('owlCarousel', { static: false }) owlCarousel!: ElementRef<HTMLElement>;

  autoPlayTimeout = 10000;
  autoPlayTimer: any;
  /** */
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 1000,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false,
    autoplay: this.autoPlayTimeout > 0,
    autoplayTimeout: this.autoPlayTimeout,
    autoplayHoverPause: true,
    autoplayMouseleaveTimeout: this.autoPlayTimeout,
    slideTransition: 'cubic-bezier(0.42, 0, 0.25, 1.0)',
    smartSpeed: 1500
  }
  customOptionsSetup(items: BannerDirectoryItem[] | undefined) {

  }
  ngOnInit(): void {
    this.banners$.pipe(takeUntil(this.stop$)).subscribe(data => {
      this.banners.set(Array.isArray(data) ? data : null);
    })

  }
  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
