<article class="what-new">
        <h2>
          <a [routerLink]="['/events']">
            <ng-container *ngIf="isWideScreen(); else shotTitle">
              <span>Что нового в нашей сети</span>
            </ng-container>
            <ng-template #shotTitle>
              <span>Наши новости</span>
            </ng-template>
          </a>
        </h2>
        <div class="title-divider"></div>
        <div class="what-new-list" *ngIf="items$|async as newsItem">
          <ng-container *ngIf="(isWideScreen()) || false;else newsMobile">
            <div class="left-list-column">
                <cstc-news-card *ngFor="let item of newsItem | slice:0:2"
            [isVertical]="false"
            [item]="item"
            class="horizontal"
           ></cstc-news-card>
            </div>
           <div class="right-list-column">
            <cstc-news-card *ngFor="let item of newsItem | slice:2:3"
            [isVertical]="true"
            [item]="item"
            class="vertical"
           ></cstc-news-card>
           </div>
        </ng-container>
        <ng-template #newsMobile>
          <cstc-news-card *ngFor="let item of newsItem"
            [isVertical]="false"
            [item]="item"
            class="horizontal"
          ></cstc-news-card>
        </ng-template>
        </div>
</article>


