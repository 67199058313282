<article *ngIf="info$|async as order" class="result" [class.hidden]="kiosk()===true">
  <div class="result-page" *ngIf="notFound$|async;else found">
    <p>Заказ не найден или уже был отменен</p>
  </div>
  <ng-template #found>
    <div class="result-page">
      <div class="qr-holder">
        <div class="order-film-img">
          <img [src]="order.session.poster?order.session.poster:'assets/img/placeholders/film_poster_placeholder.jpg'"
            alt="">
          <div class="rating-holder">
            <p class="rating">{{order.session.age_restriction}}+</p>
          </div>
        </div>
        <ng-container *ngIf="long$|async; else default">
          <div class="in-progress">
            <p>
              <span class="status">Истекло время ожидания</span>
            </p>
            <a href="#" (click)="renew($event)">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.3846 12C19.3846 10.1339 18.7564 8.32222 17.6011 6.85677C16.4458 5.39132 14.8308 4.35749 13.0163 3.92186C11.2018 3.48624 9.29348 3.67419 7.59882 4.45544C5.90417 5.23669 4.52189 6.56572 3.67471 8.2284C2.82753 9.89108 2.5648 11.7906 2.92886 13.6208C3.29291 15.451 4.26254 17.1053 5.6815 18.3172C7.10047 19.5291 8.88613 20.228 10.7508 20.3013C12.6154 20.3746 14.4504 19.8179 15.9601 18.7211"
                  stroke-width="3" stroke-linecap="round" />
                <path d="M21.6592 9.23077L19.813 12.4284L16.6154 10.5822" stroke-width="3" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              <span>Обновить</span>
            </a>
          </div>
        </ng-container>
        <ng-template #default>
          <ng-container *ngIf="(inProgress$|async)===true;else result">
            <div class="in-progress">
              <cstc-loader class="inline reverse"></cstc-loader>
              <span class="status loader">Подождите <br> мы проверяем заказ</span>
            </div>
          </ng-container>
        </ng-template>
        <ng-template #result>
          <ng-container *ngIf="error$|async; else success">
            <div class="in-progress">
              <p>
                <span class="error-mark">
                  <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12 8V12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12 16H12.01" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
              </p>
              <span class="status">Не удалось оформить заказ и он был отменён
                <ng-container *ngIf="errorMessage$|async">
                  <br><span>{{errorMessage$|async}}</span>
                </ng-container>
              </span>
            </div>

          </ng-container>
          <ng-template #success>
            <cstc-ticket-qr [order]="order"></cstc-ticket-qr>
          </ng-template>
        </ng-template>
      </div>
      <div class="result-info">
        <cstc-order-info-header [session]="order.session" [theatre]="order.theatre"></cstc-order-info-header>
        <cstc-ticket-result [session]="order.session" [tickets]="order.tickets"></cstc-ticket-result>
        <cstc-bar-result *ngIf="order.products.length" [bar]="order.products"></cstc-bar-result>
        <cstc-cinema-result [cinema]="order.theatre"></cstc-cinema-result>
      </div>
      <!-- <h3>Билет будет отправлен Вам на почту</h3> -->
    </div>
  </ng-template>
  <!-- <cstc-result-kiosk [order]="order" *ngIf="kiosk===true"></cstc-result-kiosk> -->
</article>
<article class="kiosk" [class.hidden]="kiosk()===false">
  <div class="result-wrapper" *ngIf="info$|async as order">
    <div class="title" [class.hidden]="error$|async">
      <h2>Сфотографируйте это окно</h2>
      <p class="title-sign"><i>Для того чтобы сохранить номер заказа</i></p>
    </div>
    <div class="content-wrapper">
      <div class="content">
        <ng-container *ngIf="error$|async;else success">
          <div class="content-group">
            <p class="sub-title">статус заказа</p>
            <p class="status">Отказ</p>
          </div>
          <div class="content-group">
            <p class="sub-title">причина</p>
            <p class="reason">Не удалось оформить заказ и он был отменён</p>
            <p *ngIf="errorMessage$|async">{{errorMessage$|async}}</p>
          </div>
        </ng-container>
        <ng-template #success>
          <div class="content-group">
            <p class="sub-title">статус заказа</p>
            <p class="status">Оформлен</p>
          </div>
          <div class="content-group">
            <p class="sub-title">номер заказа</p>
            <p class="reason code">{{order.id}}</p>
          </div>
        </ng-template>
        <div class="content-group tickets">
          <cstc-ticket-result [session]="order.session" [tickets]="order.tickets"></cstc-ticket-result>
        </div>
        <div class="content-group">
          <p class="sub-title">сумма</p>
          <p class="reason">{{order.amount/100}}&nbsp;₽</p>
        </div>
      </div>
      <a cstc-button="primary" class="primary-btn" (click)="redirectToHome(order,$event)">новый заказ</a>
    </div>
    <p class="title-sign"><i>Через 30 секунд это окно будет автоматически закрыто и начнется новый заказ</i></p>
    <h3>Билет будет отправлен Вам на почту</h3>
  </div>
</article>
<!-- success: result/D27-1568324324 -->
<!-- fail: result/D27-62645379 -->
<!-- <cstc-qr-pay></cstc-qr-pay> -->
