import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ScheduleFormat } from 'src/app/services/schedule/schedule.model';
import { SessionTimeComponent } from '../session-time/session-time.component';

@Component({
  selector: 'cstc-format-sheldule',
  templateUrl: './format-sheldule.component.html',
  styleUrls: ['./format-sheldule.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SessionTimeComponent
  ]
})
export class SessionScheduleComponent implements OnInit {

  @Input() format!: ScheduleFormat;

  constructor() { }

  ngOnInit(): void {
  }

}
