import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FilterDateItem } from 'src/app/models/schedule.model';
import { ScheduleFormat, Theatre } from 'src/app/services/schedule/schedule.model';
import { CommonModule } from '@angular/common';
import { SessionScheduleComponent } from '../../../format-sheldule/format-sheldule.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'cstc-schedule-cinema-card',
  templateUrl: './schedule-cinema-card.component.html',
  styleUrls: ['./schedule-cinema-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SessionScheduleComponent
  ]
})
export class ScheduleCinemaCardComponent {
  maxNameLength = 30;
  @Input() cinema!: Theatre;
  @Input() formats!: ScheduleFormat[];



  openMap=false;

  toggleMapBtn() {
    this.openMap=!this.openMap;
  }

  cinemaDateInfo: FilterDateItem[] = [];

}
