export const socialLinks=[
    {
        link: 'https://t.me/cinemastarofficial',
        icon: 'assets/img/telegram.svg',
    },
    {
        link: 'https://vk.com/cinemastar',
        icon: 'assets/img/vk.svg',
    },
    {
        link: 'https://ok.ru/cinemastar',
        icon: 'assets/img/tiktok.svg',
    }
];
