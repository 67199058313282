<article>
    <div class="notfound-wrapper">
        <div class="notfound-img-holder">
            <img src="assets/img/404.png" alt="">
            <div class="img-shadow"></div>
        </div>
        <div class="notfound-text-holder">
            <h2>Страница не найдена</h2>
            <p>Возможно, вам дали неправильную ссылку, или мы уже удалили эту страницу с сайта.</p>
            <div class="btn-holder">
                <a href="#" cstc-button="secondary">вернуться на главную</a>
            </div>
        </div>
    </div>
</article>
