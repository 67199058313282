import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { ShoppingCartService } from 'src/app/services/order/shopping-cart-service.service';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'cstc-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleComponent implements OnInit {
  isWideScreen$ = this.responsive.isWideScreen$;
  cart$ = this.cartSvc.cart$;
  pushkinToggle(event: boolean, mode:number){
    this.cartSvc.togglePushkinMode(mode);
  }

  constructor(
    public cartSvc: ShoppingCartService,
    private responsive:ResponsiveService
  ) { }

  ngOnInit(): void {
  }

}
