import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding, inject, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AngularYandexMapsModule } from 'angular8-yandex-maps';
import { FilterDateItem } from 'src/app/models/schedule.model';
import { ButtonComponent } from 'src/app/modules/shared/components/button/button.component';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { Theatre } from 'src/app/services/schedule/schedule.model';
import { CstcStore } from 'src/app/store/state.store';
import { MAP_CONFIG } from 'src/app/tokens/map-config.token';

@Component({
  selector: 'cstc-cinema-info',
  templateUrl: './cinema-info.component.html',
  styleUrls: ['./cinema-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AngularYandexMapsModule,
    ButtonComponent
  ]
})
export class CinemaInfoComponent implements OnInit, OnDestroy {
  state = inject(CstcStore);
  platformSvc = inject(PlatformService);
  mapConfig = inject(MAP_CONFIG);

  @Input() cinema!: Theatre;
  cinemaDateInfo: FilterDateItem[] = [];
  openMap = false;
  private map: any;

  isMobileApp = this.platformSvc.isMobileApp;
  isWideScreen = this.state.isWideScreen;

  @HostBinding('class.kiosk') get kioskConfig() {
    return this.state.isKiosk() === true;
  }

  @ViewChild('mapContainer') mapContainer!: ElementRef<HTMLDivElement>;

  async loadYandexMap() {
    if (this.openMap && this.mapContainer) {
      try {
        await this.loadScript(`https://api-maps.yandex.ru/2.1/?apikey=${this.mapConfig.apikey}&lang=${this.mapConfig.lang}`);
        const ymaps = (window as any).ymaps;
        await ymaps.ready();

        this.map = new ymaps.Map(this.mapContainer.nativeElement, {
          center: [this.cinema.latitude, this.cinema.longitude],
          zoom: 10,
          controls: [],
        });

        const placemark = new ymaps.Placemark([this.cinema.latitude, this.cinema.longitude]);
        this.map.geoObjects.add(placemark);

        this.updateMapSize();
      } catch (error) {
        // console.error('Failed to load Yandex Map:', error);
      }
    }
  }

  loadScript(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error('Failed to load Yandex Maps script'));
      document.head.appendChild(script);
    });
  }

  updateMapSize() {
    const banner = document.querySelector('.cinema-banner');
    if (banner) {
      // Скрываем карту, чтобы пересчитать высоту баннера
      if (this.map && this.mapContainer) {
        this.mapContainer.nativeElement.style.display = 'none';
      }

      // Пересчитываем высоту баннера
      const height = banner.getBoundingClientRect().height;

      // Показываем карту с новой высотой
      if (this.map && this.mapContainer) {
        const mapElement = this.mapContainer.nativeElement as HTMLElement;
        mapElement.style.display = 'block';
        mapElement.style.height = `${height}px`;
        mapElement.style.width = '100%';
        this.map.container.fitToViewport();
      }
    }
  }

  toggleMapBtn(event: MouseEvent) {
    event.preventDefault();
    this.openMap = !this.openMap;
    if (this.openMap) {
      this.loadYandexMap();
    } else if (this.map) {
      this.map.destroy();
      this.map = null;
    }
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.destroy();
    }
    window.removeEventListener('resize', this.updateMapSize.bind(this));
  }

  ngOnInit(): void {
    window.addEventListener('resize', this.updateMapSize.bind(this));
  }
}
