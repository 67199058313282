<a [routerLink]="['/page', item.id]">
    <div class="stock-img-holder">
        <img [src]="item.image?item.image:'/assets/img/placeholders/stock_placeholder.png'" alt="">
    </div>
    <div class="stock-card-text-holder">
        <div class="stock-card-info">
            <!-- <h3 [ngStyle]="textStyle">{{item.title}}</h3> -->
            <h3>{{item.title}}</h3>
            <p class="stock-card-text">{{item.description_short}}</p>
            <div class="stock-card-date-holder" *ngIf="item.relevant_from&&item.relevant_from!==null">
                <p class="stock-card-date">{{item.relevant_from}}</p>
            </div>
        </div>
        <img class="stock-card-info-bg" [src]="item.image?item.image:'/assets/img/placeholders/stock_placeholder.png'" alt="">
        <div class="stock-card-bg-overlay"></div>
    </div>
</a>
