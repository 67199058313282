<a [routerLink]="['/cinema', cinema.uid]" class="cinema-about-wrapper" [ngClass]="{show_img: showBanner$|async, hide_img: !(showBanner$|async)}">
    <div class="cinema-about-bg">
        <div class="cinema-banner">
            <img [src]="cinema.image?cinema.image:'/assets/img/placeholders/cinema_item_placeholder.jpg'" alt="">
        </div>
        <div class="cinema-about-overlay"></div>
        <div class="cinema-about-holder">
            <div class="cinema-about">
                <h2>{{cinema.name}}</h2>
                <div class="cinema-location">
                    <p *ngFor="let metroItem of cinema.metro"><i>м. {{metroItem}}</i></p>
                    <p>{{cinema.address}}</p>
                </div>
                <ul class="cinema-options">
                    <li *ngFor="let item of cinema.options">{{item}}</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="cinema-banner bottom" *ngIf="cinema.image">
        <ng-container *ngIf="(isWideScreen()); else mobileImg">
            <img src="{{cinema.image}}" alt="">
        </ng-container>
        <ng-template #mobileImg>
            <img src="{{cinema.image}}" alt="">
        </ng-template>
    </div>
</a>
