import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { PlatformService } from '../../../../../services/platform/platform.service';
import { ButtonComponent } from 'src/app/modules/shared/components/button/button.component';
declare var cordova: any;
@Component({
  selector: 'cstc-notifications',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ButtonComponent,
  ],
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent {
  platformSvc = inject(PlatformService)
  updateUrl = this.platformSvc.updateUrl;
  inAppBrowserRef: any;
  notifications = [
    {
      title: 'Ваш город',
      text: 'Санкт-Петербург'
    }
  ]
  update(e: MouseEvent) {
    e.preventDefault();
    const url = this.updateUrl();
    if (url) {
      cordova.InAppBrowser.open(url, '_system');
    }
  }
}
