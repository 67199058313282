import { CommonModule } from '@angular/common';
import { Component, HostBinding, inject } from '@angular/core';
import { DataService } from 'src/app/services/data/data.service';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { FilmsSelectionComponent } from '../../shared/films-selection/films-selection.component';
import { BannerComponent } from '../../shared/banner/banner.component';
import { MovieCardSkeletonComponent } from "../../../../shared/components/skeleton-loader/movie-card-skeleton/movie-card-skeleton.component";
import { CstcStore } from 'src/app/store/state.store';

@Component({
  templateUrl: './films.component.html',
  styleUrls: ['./films.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FilmsSelectionComponent,
    BannerComponent,
    MovieCardSkeletonComponent
  ]
})
export class FilmsComponent {
  state = inject(CstcStore);
  platformSvc = inject(PlatformService);
  private dataSvc = inject(DataService);
  loading = this.dataSvc.loading;
  isMobileApp = this.platformSvc.isMobileApp;
  isStandalone = this.state.isStandalone;

  @HostBinding('class')
  get hostClass() {
    return {
      'mobileApp': this.isMobileApp(),
      'isStandalone': this.isStandalone(),
    }
  }
}
