<div class="what-new-item">
    <a [routerLink]="['/page', item.id]">
        <div class="what-new-img">
            <img [src]="item.image?item.image:'/assets/img/placeholders/event_placeholder.png'" alt="">
            <div class="what-new-img-overlay"></div>
        </div>
        <div class="what-new-item-txt">
            <h3>{{item.title}}</h3>
            <p>{{item.description_short}}</p>
            <p class="what-new-item-txt-date">{{item.relevant_from}}</p>
        </div>
    </a>
</div>
