import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Session } from 'src/app/models/session.model';
import { OrderService } from 'src/app/services/order/order.service';

@Component({
  selector: 'cstc-order-film',
  templateUrl: './order-film.component.html',
  styleUrls: ['./order-film.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderFilmComponent implements OnInit {
  @Input() inner = false;
  maxNameLength = 25;
  priceList$ = this.order.priceList$;
  @Input() session!: Session;
  @Input() img: boolean = true;

  constructor(
    private order: OrderService,
  ) { }

  ngOnInit(): void {
  }

}
