import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToDatePipe } from 'src/app/pipes/to-date.pipe';
import { ScheduleSession } from 'src/app/services/schedule/schedule.model';

@Component({
  selector: 'cstc-session-time',
  templateUrl: './session-time.component.html',
  styleUrls: ['./session-time.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ToDatePipe
  ],
  animations: [
    trigger('goals', [
      state('in', style({transform: 'translateX(0)'})),
      transition('void => *', [
       animate('.6s ease-in' , keyframes([
          style({opacity:0 , transform:'translateY(-75%)' , offset:0}),
          style({opacity:.5 , transform:'translateY(35px)' , offset:.3}),
          style({opacity:1 , transform:'translateY(0)' , offset:1}),
        ]))
      ]),
      transition('* => void', [
        animate('.6s ease-in', keyframes([
            style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
            style({ opacity: .5, transform: 'translateY(35px)', offset: .3 }),
            style({ opacity: 0, transform: 'translateY(-75%)', offset: 1 }),
          ]))
      ])
    ])
  ]
})
export class SessionTimeComponent implements OnInit {

  @Input() session!: ScheduleSession;
  get isExpired() {
    const expireDate = new Date(this.session.close_date.toString().replace(' ', 'T'));
    return expireDate < new Date();
  }
  // функция переводит полученную в копейках цену в рубли
  getItemPrice(price:number):number {
    return price/100;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
