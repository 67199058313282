import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CinemasBannerService } from 'src/app/services/cinema/cinemas-banner.service';
import { Theatre } from 'src/app/services/schedule/schedule.model';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  selector: 'cstc-cinema-list-item',
  templateUrl: './cinema-list-item.component.html',
  styleUrls: ['./cinema-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class CinemaListItemComponent {
  state = inject(CstcStore);
  @Input() cinema!: Theatre;
  isWideScreen = this.state.isWideScreen;
  cinemaSvc = inject(CinemasBannerService);
  showBanner$ = this.cinemaSvc.showBanner$;
}
