import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { CstcStore } from "src/app/store/state.store";

@Injectable({
    providedIn: 'root'
})
export class KioskGaurd implements CanActivate {
    private state = inject(CstcStore);
    router = inject(Router);

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.state.isKiosk()) {
            this.router.navigate(['/kiosk'])
            return false;
        }
        return true;
    }
}
