import { enableProdMode } from '@angular/core';

import { environment } from './environments/environment';

import 'hammerjs';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => {
  bootstrapApplication(AppComponent, appConfig)
    .catch((err) => console.error(err));
};

if (typeof window.cordova !== "undefined") {
  document.addEventListener(
    "deviceready",
    () => {
      bootstrap();
    },
    false
  );
} else {
  bootstrap();
}
