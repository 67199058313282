import { Component, OnDestroy, inject, effect, Renderer2, computed, HostBinding, signal, } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';
import { DataService } from 'src/app/services/data/data.service';
import { TrailerPopupService } from '../../services/trailer-popup/trailer-popup.service';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { CityService } from 'src/app/services/city/city.service';
import { IdleService } from 'src/app/services/idle.service';
import { FooterComponent } from './components/shared/footer/footer.component';
import { MovieTrailerPopupComponent } from './components/shared/movie-trailer-popup/movie-trailer-popup.component';
import { FooterMobileComponent } from './components/shared/footer-mobile/footer-mobile.component';
import { CommonModule } from '@angular/common';
import { CinemaSelectComponent } from './components/shared/cinema-select/cinema-select.component';
import { CarouselComponent } from './components/shared/carousel/carousel.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { GeolocationPermissionComponent } from './components/shared/geolocation-permission/geolocation-permission.component';
import { CstcStore } from 'src/app/store/state.store';
import { NotificationsComponent } from './components/shared/notifications/notifications.component';
import { BannerSkeletonComponent } from '../shared/components/skeleton-loader/banner-skeleton/banner-skeleton.component';

@Component({
  selector: 'cstc-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FooterComponent,
    FooterMobileComponent,
    MovieTrailerPopupComponent,
    CinemaSelectComponent,
    CarouselComponent,
    HeaderComponent,
    GeolocationPermissionComponent,
    NotificationsComponent,
    BannerSkeletonComponent,
  ]
})
export class WebsiteComponent implements OnDestroy {
  /**Injections start */
  state = inject(CstcStore);
  platformSvc = inject(PlatformService);
  cityService = inject(CityService);
  idleSvc = inject(IdleService);
  /**Injections end */
  loading = this.data.loading;
  isStandalone = this.state.isStandalone;
  isMobileApp = this.platformSvc.isMobileApp;
  showNewVersion = computed(() => {
    const platform = this.platformSvc.platform();
    const updateAvailable = this.platformSvc.updateAvailable();
    return platform !== 'web' && updateAvailable === true;
  });
  trailerPopOpened$ = this.movieTrailerPopupSvc.isOpened$;
  isWideScreen = this.state.isWideScreen;
  destroy$ = new Subject<void>();
  showBranding = true;
  kiosk = this.state.isKiosk;
  isKiosk?: boolean;
  showMobileAppMenu = this.platformSvc.showMobileAppMenu;
  openBackdrop = signal(false); //переменная для отображения backdrop'а
  onBackdropOpened() {
    // this.openBackdrop = isActive;
    if (this.cityService.cityListOpened() === false) {
      this.openBackdrop.set(true);
    }
  }
  @HostBinding('class')
  get hostClass() {
    return {
      mobileApp: this.platformSvc.isMobileApp(),
    };
  }
  dataChange = effect(() => {
    const body = document.getElementsByTagName('body')[0];
    const platform = this.platformSvc.platform();
    const platformVersion = this.platformSvc.versionCurrent();
    this.renderer.setAttribute(body, 'data-platform', platform);
    this.renderer.setAttribute(body, 'data-version', platformVersion);
  });
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private data: DataService,
    private movieTrailerPopupSvc: TrailerPopupService,
    private renderer: Renderer2
  ) {
    router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.showBranding = this.route.snapshot.firstChild?.data['hideBranding']
          ? false
          : true;
      });

    this.isKiosk = this.route.snapshot.data['isKiosk'];
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
