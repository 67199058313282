import { CommonModule } from '@angular/common';
import { Component, HostBinding, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { menuBottom } from 'src/app/models/menu-bottom.model';
import { socialLinks } from 'src/app/models/social-links.model';

@Component({
  selector: 'cstc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class FooterComponent implements OnInit {
  router = inject(Router);

  menuBottom = menuBottom;

  socialLinks = socialLinks;

  @HostBinding('class.qr') get hostClass() {
    return this.router.url.includes('/qr')
  }

  constructor() { }

  ngOnInit(): void {
  }
  get date() {
    return (new Date()).getFullYear();
  }
}
