@let page = content$ | async;
@if (loading()===false && page !== undefined) {
  <article *ngIf="page">
    <div class="event-wrapper">
      <div class="event-poster">
        <div *ngIf="page.image" class="event-poster-img">
          <img src="{{ page.image }}" alt="" />
        </div>
        <div class="event-poster-advantage">
          <h2>{{ page.title }}</h2>
          <img
            *ngIf="page.image"
            class="event-poster-bg"
            src="{{ page.image }}"
            alt=""
          />
          <div class="event-poster-overlay"></div>
        </div>
      </div>

      <div
        class="event-text htmlOutput"
        [innerHTML]="page.content | htmlWithUrl : page.google_form"
      ></div>
    </div>
  </article>
}@else {
  <cstc-page-skeleton/>
}
<cstc-banner type="bottom"></cstc-banner>
