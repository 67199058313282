import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, signal, computed, inject, OnDestroy, HostBinding } from '@angular/core';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { DataService } from 'src/app/services/data/data.service';
import { Film } from 'src/app/services/schedule/schedule.model';
import { SearchService } from 'src/app/services/search.service';
import { SearchComponent } from '../filters/search/search.component';
import { MovieCardComponent } from '../movie-card/movie-card.component';
import { CinemasFilterPipe } from 'src/app/pipes/cinema-filter.pipe';
import { CstcStore } from 'src/app/store/state.store';
import { MovieCardSkeletonComponent } from 'src/app/modules/shared/components/skeleton-loader/movie-card-skeleton/movie-card-skeleton.component';

@Component({
  selector: 'cstc-films-selection',
  templateUrl: './films-selection.component.html',
  styleUrls: ['./films-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SearchComponent,
    MovieCardComponent,
    CinemasFilterPipe,
    MovieCardSkeletonComponent,
  ]
})
export class FilmsSelectionComponent implements OnInit, OnDestroy {
  state = inject(CstcStore);
  stop$ = new Subject<void>();
  public searchSvc = inject(SearchService);

  loadedCards = Array.from({ length: 14 });
  films$!: Observable<Film[] | undefined>;
  isEmpty = signal(false);
  @Input() selection: any = {
    on_sale: true
  }
  @Input() search = false;
  @Input() title?: string;
  dataSvc = inject(DataService);
  loading = this.dataSvc.loading;
  leadItemsCnt = computed(() => {
    if (this.searchSvc.search()) {
      return 0;
    }
    return 2 * this.cardsCount(this.state.windowWidth()) - 1;
  });
  readmore = false;
  showMore(event: MouseEvent) {
    event.preventDefault();
    this.readmore = !this.readmore;
  }
  searchText = "";
  setInputSearch(text: string) {
    this.searchText = text;
  }
  isWideScreen = this.state.isWideScreen;

  cardsCount(windowWidth: number | null) {
    if (windowWidth === null) {
      return 0;
    }
    switch (true) {
      case (windowWidth > 1539):
        return 7;
        break;
      case (windowWidth >= 1320):
        return 6;
        break;
      case (windowWidth >= 1100):
        return 5;
        break;
      case (windowWidth >= 1000):
        return 4;
        break;
      case (windowWidth >= 832):
        return 4;
        break;
      case (windowWidth >= 800):
        return 3;
        break;
      case (windowWidth >= 628):
        return 3;
        break;
      case (windowWidth >= 480):
        return 2;
        break;
      case (windowWidth >= 320):
        return 2;
        break;
      default:
        return 0;
        break;
    }
  }
  cardsGap = computed(() => {
    return (this.state.windowWidth() || 0) > 1024 ? (this.leadItemsCnt() - 1) * 32 + 32 : (this.leadItemsCnt() - 1) * 16 + 32;
  })
  cardWidth = computed(() => {
    return Math.round((this.state.windowWidth() || 0) - (this.cardsGap() / 2)) / Math.ceil(this.leadItemsCnt() / 2);
  })
  cardsGrid = computed(() => {
    return 'repeat(auto-fit, minmax(188px,' + this.cardWidth() + 'px))';
  })

  @HostBinding('class.is_empty') get insideMobile() {
    return this.isEmpty();
  }
  ngOnInit(): void {
    this.films$ = this.dataSvc.getFilteredFilms(this.selection);
    this.films$.pipe(takeUntil(this.stop$), take(1)).subscribe(result => {
      if (result?.length === 0) {
        this.isEmpty.set(true);
      }
    })
  }
  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
