import { Component, HostBinding, inject, OnDestroy } from '@angular/core';
import { OrderService } from 'src/app/services/order/order.service';
import { filter, map, switchMap, take, BehaviorSubject, catchError, EMPTY, Subject, takeUntil } from 'rxjs';
import { OrderInfoData } from 'src/app/services/order/order.info.model';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { LoaderComponent } from 'src/app/modules/shared/components/loader/loader.component';
import { CommonModule } from '@angular/common';
import { TicketResultComponent } from '../../shared/order/ticket-result/ticket-result.component';
import { OrderInfoHeaderComponent } from '../../shared/order/order-info-header/order-info-header.component';
import { BarResultComponent } from '../../shared/order/bar-result/bar-result.component';
import { CinemaResultComponent } from 'src/app/modules/order/components/shared/cinema-result/cinema-result.component';
import { TicketQrComponent } from '../../shared/order/ticket-qr/ticket-qr.component';
import { ButtonComponent } from 'src/app/modules/shared/components/button/button.component';
import { CstcStore } from 'src/app/store/state.store';


@Component({
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    LoaderComponent,
    TicketResultComponent,
    OrderInfoHeaderComponent,
    BarResultComponent,
    CinemaResultComponent,
    TicketQrComponent,
    ButtonComponent,
  ]
})
export class ResultComponent implements OnDestroy {
  platformSvc = inject(PlatformService);
  state = inject(CstcStore);
  kiosk = this.state.isKiosk;
  isStandalone = this.state.isStandalone;
  destroy$ = new Subject<void>();
  error$ = new BehaviorSubject(false);
  long$ = new BehaviorSubject(false);
  notFound$ = new BehaviorSubject(false);
  inProgress$ = new BehaviorSubject(true);
  errorMessage$ = new BehaviorSubject('');
  info$ = new BehaviorSubject<OrderInfoData | undefined>(undefined);
  requestDelay = 10000;
  requestCounter = 0;
  requestLimit = 12;
  orderId!: string;

  @HostBinding('class') get hostClass() {
    return {
      'kiosk': this.kiosk(),
      'isStandalone': this.isStandalone()
    }
  }

  constructor(
    private orderSvc: OrderService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.pipe(
      takeUntil(this.destroy$),
      filter(params => {
        return typeof params['id'] !== 'undefined';
      }),
      take(1),
      map(params => params['id']),
      switchMap((orderId: string) => {
        return this.orderSvc.getInfo(orderId);
      }),
      catchError(e => {
        this.router.navigate(['/404'])
        return EMPTY;
      })
    ).subscribe(result => {
      this.info$.next(result);
      this.orderId = result.id;
      this.setResultWithStatusCode(result.status, result?.error);
    });
  }

  getStatus() {
    this.orderSvc.getInfo(this.orderId).pipe(
      take(1),
      takeUntil(this.destroy$)
    ).subscribe((order: OrderInfoData) => {
      if (order) {
        this.setResultWithStatusCode(order.status);
      }
    });

  }
  renew(e: MouseEvent) {
    e.preventDefault();
    this.long$.next(false);
    this.requestCounter = 0;
    this.getStatus();
  }
  setResultWithStatusCode(code: number, errorMessage?: string) {
    this.requestCounter++;
    if (code >= 3) {
      if (code != 5) {
        this.error$.next(true);
      }
      if (code === 6) {
        this.notFound$.next(true);
      }
      this.inProgress$.next(false);
      if (errorMessage) {
        this.errorMessage$.next(errorMessage);
      }
    } else {
      if (this.requestCounter <= this.requestLimit) {
        window.setTimeout(this.getStatus.bind(this), this.requestDelay);
      } else {
        this.long$.next(true);
      }
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  redirectToHome(order: OrderInfoData, event?: MouseEvent) {
    if (event) {
      event.preventDefault();
    }
    this.router.navigate(['/cinema', order.theatre.uid])
  }

}
