import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  { path: '', outlet: 'cart', loadChildren: () => import('./modules/cart/cart.module').then(m => m.CartModule) },
  { path: 'order',
    loadChildren: () => import('./modules/order/order.module').then(m => m.OrderModule),
    data: {
      hideBranding: true
    },
  },
  { path: '', loadChildren: () => import('./modules/website/website.module').then(m => m.WebsiteModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    // scrollPositionRestoration: "enabled",
    // scrollOffset: [0, 0],
    // Enable scrolling to anchors
    anchorScrolling: "enabled",
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
