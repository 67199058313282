import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Session } from 'src/app/models/session.model';
import { ToDatePipe } from 'src/app/pipes/to-date.pipe';
import { Theatre } from 'src/app/services/schedule/schedule.model';

@Component({
  selector: 'cstc-order-info-header',
  templateUrl: './order-info-header.component.html',
  styleUrls: ['./order-info-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ToDatePipe
  ]
})
export class OrderInfoHeaderComponent {
  maxNameLength = 25;
  @Input() session!: Session;
  @Input() theatre!: Theatre;
}
