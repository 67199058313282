import { Component, HostBinding, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonComponent } from 'src/app/modules/shared/components/button/button.component';
import { IdleService } from 'src/app/services/idle.service';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  selector: 'cstc-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
  ]
})
export class NotFoundComponent {
  state = inject(CstcStore);
  platformSvc = inject(PlatformService);
  idleSvc = inject(IdleService);
  router = inject(Router);

  selectedCinemaUid = this.idleSvc.getSelectedCinemaUid();
  kiosk = this.state.isKiosk;
  buttonActive = false;

  @HostBinding('class') get hostClass() {
    return {
      'kiosk': this.kiosk()
    }
  }

  redirectToHome(event?: MouseEvent) {
    if (event) {
      event.preventDefault()
    }
    if (this.kiosk()) {
      this.router.navigate(['/cinema', this.selectedCinemaUid]);
    } else {
      this.router.navigate(['/']);
    }
  }
}
