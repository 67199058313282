<!-- <cstc-banner [type]="'top'" [isWideScreen$]="isWideScreen$" *ngIf="showBranding"></cstc-banner> -->
<cstc-notifications *ngIf="isWideScreen()===false" [ngClass]="{active: showNewVersion()}"></cstc-notifications>
<cstc-geolocation-permission *ngIf="isWideScreen()===false&&showNewVersion()===false"
  [class.active]="true"></cstc-geolocation-permission>
<!-- чтобы показать уведомление нужно добавить класс "active" -->
<ng-container *ngIf="!isMobileApp()" >
  <cstc-header
    [class.kiosk]="kiosk()===true"
    [class.isStandalone]="isStandalone()&&isWideScreen()===false"
  ></cstc-header>
</ng-container>
<article *ngIf="showBranding" [class.carousel]="isMobileApp()" [class.isTandalone]="isStandalone()">
  @if (loading()===false) {
    <cstc-carousel [class.kiosk]="kiosk()===true"></cstc-carousel>
  }@else {
    <cstc-banner-skeleton/>
  }
</article>

<ng-container *ngIf="isKiosk">
  <cstc-cinema-select></cstc-cinema-select>
</ng-container>
<!-- <ng-template #default> -->
<router-outlet></router-outlet>
<!-- </ng-template> -->
<cstc-footer-mobile *ngIf="showMobileAppMenu()"></cstc-footer-mobile>
<cstc-footer *ngIf="showMobileAppMenu()===false" [class.kiosk]="kiosk()===true"></cstc-footer>

<cstc-movie-trailer-popup *ngIf="trailerPopOpened$|async"></cstc-movie-trailer-popup>
