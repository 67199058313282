<div class="trailer-banner" [class.mobileApp]="isMobileApp()">
    <div class="trailer-poster"></div>
</div>
<section class="film-info-holder">
    <article class="film-info-wrapper">
        <div class="film-info">
            <div class="movie-poster"></div>
            <div class="movie-info-wrapper"></div>
            <div class="pushkin-label" ></div>
        </div>
    </article>
</section>
<article><cstc-date-skeleton/></article>
@if (isWideScreen()===false) {
    <article>
        <div class="cinema-schedule"></div>
        <div class="cinema-schedule"></div>
        <div class="cinema-schedule"></div>
        <div class="cinema-schedule"></div>
    </article>
}@else {
    <article><cstc-cinema-schedule/></article>
}
