import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cstc-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  buttonActive = false;

  constructor() { }

  ngOnInit(): void {
  }

}
