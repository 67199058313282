import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { Observable, catchError, map, of, pluck } from 'rxjs';
import { cachedRequest } from 'src/app/decorators/cache.decorator';
import { PageData, PageDataResponse, PageListDataResponse } from '../page/page.model';
import { ENVIRONMENT_CONFIG } from 'src/app/tokens/environment-config.token';
export interface PageLoadData {
  data?: PageData[],
  totalCount: number
}
@Injectable()
export class PageListService {
  private appConfig = inject(ENVIRONMENT_CONFIG);
  private baseUrl = this.appConfig.baseUrl;
  private httpClient = inject(HttpClient);

  @cachedRequest()
  getNews(perPage:number = 10, page:number =1, category: number = 0) {
    let params = new HttpParams();
    if(perPage) {
      params = params.append('per-page', perPage.toString());
    }
    if(page>0) {
      params = params.append('page', page.toString());
    }
    if(category>0) {
      params = params.append('page_category_id', category.toString());
    }
    // params = params.append('sort','-id');
    return this.httpClient.get<PageDataResponse>(
      `${this.baseUrl}/page`,
      {
        params,
        observe: 'response'
      }
    ).pipe(
      map( (res) => {
        // console.log('API Response:', res);
        const totalCount = parseInt(res.headers.get('X-Pagination-Total-Count') || '0');
        const data = res.body ? res.body.data : [];
        // console.log('totalCount: ', totalCount);
        return { data, totalCount };
      }),
      catchError(error => {
        return of({ data: [], totalCount: 0 });
      }),
    );
  }
}
