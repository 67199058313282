import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { SearchService } from 'src/app/services/search.service';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  selector: 'cstc-movie-card-skeleton',
  standalone: true,
  imports: [],
  templateUrl: './movie-card-skeleton.component.html',
  styleUrl: './movie-card-skeleton.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MovieCardSkeletonComponent {
  searchSvc = inject(SearchService);
  state = inject(CstcStore);

  loadedCards = Array.from({ length: 14 });

  leadItemsCnt = computed(() => {
    if (this.searchSvc.search()) {
      return 0;
    }
    return 2 * this.cardsCount(this.state.windowWidth()) - 1;
  });

  cardsCount(windowWidth: number | null) {
    if (windowWidth === null) {
      return 0;
    }
    switch (true) {
      case (windowWidth > 1539):
        return 7;
        break;
      case (windowWidth >= 1320):
        return 6;
        break;
      case (windowWidth >= 1100):
        return 5;
        break;
      case (windowWidth >= 1000):
        return 4;
        break;
      case (windowWidth >= 832):
        return 4;
        break;
      case (windowWidth >= 800):
        return 3;
        break;
      case (windowWidth >= 628):
        return 3;
        break;
      case (windowWidth >= 480):
        return 2;
        break;
      case (windowWidth >= 320):
        return 2;
        break;
      default:
        return 0;
        break;
    }
  }

  cardsGap = computed(() => {
    return (this.state.windowWidth() || 0) > 1024 ? (this.leadItemsCnt() - 1) * 32 + 32 : (this.leadItemsCnt() - 1) * 16 + 32;
  })
  cardWidth = computed(() => {
    return Math.round((this.state.windowWidth() || 0) - (this.cardsGap() / 2)) / Math.ceil(this.leadItemsCnt() / 2);
  })
}
