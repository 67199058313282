import { Directive, ElementRef, Input, OnInit, Renderer2, inject } from '@angular/core';
import { PlatformService } from 'src/app/services/platform/platform.service';

@Directive({
  selector: '[safeArea]',
  standalone: true,
})
export class SafeAreaDirective implements OnInit {
  el = inject(ElementRef);
  renderer = inject(Renderer2);
  platformSvc = inject(PlatformService);
  
  // Input properties to control padding on each side
  @Input() top: boolean = false;
  @Input() bottom: boolean = false;
  @Input() left: boolean = false;
  @Input() right: boolean = false;

  ngOnInit(): void {
    this.adjustPaddingForSafeArea();
  }

  private adjustPaddingForSafeArea(): void {
    // Check for CSS env() support (mainly for iOS devices)
    if (this.platformSvc.platform()==='ios') {
      // Apply padding based on input parameters
      if (this.top) {
        this.renderer.setStyle(this.el.nativeElement, 'padding-top', 'env(safe-area-inset-top)');
      }
      if (this.bottom) {
        this.renderer.setStyle(this.el.nativeElement, 'padding-bottom', 'env(safe-area-inset-bottom)');
      }
      if (this.left) {
        this.renderer.setStyle(this.el.nativeElement, 'padding-left', 'env(safe-area-inset-left)');
      }
      if (this.right) {
        this.renderer.setStyle(this.el.nativeElement, 'padding-right', 'env(safe-area-inset-right)');
      }
    } else if(this.platformSvc.platform()==='android') {
      // Fallback for devices that do not support CSS env()
      if (this.top) {
        this.renderer.setStyle(this.el.nativeElement, 'padding-top', '38px'); // Example top inset
      }
      if (this.bottom) {
        this.renderer.setStyle(this.el.nativeElement, 'padding-bottom', '24px'); // Example bottom inset
      }
    } else {
      if (this.top) {
        this.renderer.setStyle(this.el.nativeElement, 'padding-top', '0'); // Example top inset
      }
      if (this.bottom) {
        this.renderer.setStyle(this.el.nativeElement, 'padding-bottom', '0'); // Example bottom inset
      }
    }
  }
}
