import { Component, DestroyRef, inject, input, Input, OnInit, signal } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { filter, take } from 'rxjs';
import { ScheduleService } from 'src/app/services/schedule/schedule.service';
import { TrailerPopupService } from 'src/app/services/trailer-popup/trailer-popup.service';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { CommonModule } from '@angular/common';
import { MinToMhPipe } from 'src/app/pipes/min-to-mh.pipe';
import { BannerComponent } from '../../shared/banner/banner.component';
import { ScheduleComponent } from '../../shared/schedule/schedule.component';
import { ButtonComponent } from 'src/app/modules/shared/components/button/button.component';
import { AdjustFontDirective } from 'src/app/modules/shared/directives/adjust-font.directive';
import { SvgIconDirective } from 'src/app/modules/shared/directives/svg-icon.directive';
import { FilmSkeletonComponent } from 'src/app/modules/shared/components/skeleton-loader/film-skeleton/film-skeleton.component';
import { DataService } from 'src/app/services/data/data.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CstcStore } from 'src/app/store/state.store';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './film.component.html',
  styleUrls: ['./film.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MinToMhPipe,
    BannerComponent,
    ScheduleComponent,
    ButtonComponent,
    AdjustFontDirective,
    SvgIconDirective,
    FilmSkeletonComponent
  ],
  providers: [
    ScheduleService
  ]
})
export class FilmComponent implements OnInit {
  private scheduleSvc = inject(ScheduleService);
  private titleService = inject(Title);
  private meta = inject(Meta);
  public movieTrailerPopupSvc = inject(TrailerPopupService);
  private platformSvc = inject(PlatformService);
  state = inject(CstcStore);
  dataSvc = inject(DataService);
  destroyRef = inject(DestroyRef);
  loading = this.dataSvc.loading;
  imageLoaded = signal(false);
  isMobileApp = this.platformSvc.isMobileApp;

  error$ = this.scheduleSvc.error$;
  loading$ = this.scheduleSvc.loading$;
  film$ = this.scheduleSvc.film$;
  filtering = this.scheduleSvc.filtering;
  id = input.required<number>();
  ngOnInit(): void {
    this.scheduleSvc.init('film',this.id());
    this.film$.pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(data => data !== undefined && data !== null),
      take(1),
    ).subscribe((data) => {
      if (data !== false) {
        this.titleService.setTitle(data.name);
      }
    });
  }
}
