import { isPlatformBrowser, Location } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Metrika } from 'ng-yandex-metrika';
import { BehaviorSubject, combineLatestWith, filter, take, takeWhile, withLatestFrom } from 'rxjs';
import { CityService } from './services/city/city.service';
import { NavigationService } from './services/navigation/navigation.service';
import { ResponsiveService } from './services/responsive.service';
import { TrackingService } from './services/tracking.service';

@Component({
  selector: 'cstc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Синема Стар';
  static isBrowser = new BehaviorSubject<boolean|null>(null);
  isWideScreen$ = this.responsive.isWideScreen$;
  alive = true;
  showBranding = true;

  constructor(
    private responsive:ResponsiveService,
    private router: Router,
    private route: ActivatedRoute,
    private cityService: CityService,
    @Inject(PLATFORM_ID) private platformId: any,
    private navigation: NavigationService,
    private metrika: Metrika,
    private location: Location,
    private trackingSvc: TrackingService
  ) {
    trackingSvc.initTracking();
    let prevPath = this.location.path();
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));
    const event$ = router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
    );
    trackingSvc.tracking$.pipe(
      takeWhile(() => this.alive),
      filter(value=>value===true),
      take(1),
    ).subscribe(()=>{
      
    })
    event$.pipe(
      combineLatestWith(this.trackingSvc.tracking$),
      takeWhile(() => this.alive),
    ).subscribe(
      ([routerEvent,tracking]) => {
        const newPath = this.location.path();
        if(tracking) {
          this.metrika.hit(newPath, {
            referer: prevPath,
          });
        }
        prevPath = newPath;
        this.showBranding = (this.route.snapshot.firstChild?.data['hideBranding'])?false:true;
      });

  }

  ngOnInit(): void {
    this.cityService.initCityList();
  }
  ngOnDestroy(): void {
    this.alive = false;
  }
}

