<ng-container *ngIf="films$ |async as filmsList">
  <ng-container *ngIf="filmsList.length">
    <h2 *ngIf="title">{{title}}</h2>
    <cstc-search
      *ngIf="search"
      [isWideScreen]="isWideScreen()"
    ></cstc-search>
    <div class="title-divider" *ngIf="search||title"></div>
    @if (loading()===false) {
      <div
      [ngClass]="{
        'films-list': isWideScreen(),
        'films-list-mobile': (isWideScreen() === false)
      }"
      [style.grid-template-columns]="(selection.is_future_date===true)&&isWideScreen() ? 'repeat(auto-fit, minmax(188px,' + cardWidth() + 'px))' : ''"
    >
        <cstc-movie-card
          *ngFor="let item of filmsList| slice:0:(readmore||leadItemsCnt()===0?filmsList.length:(filmsList.length>leadItemsCnt())?leadItemsCnt():filmsList.length) | cinemasFilter: this.searchSvc.search()" [item]="item" [type]="'small'"
          [style.max-width]="cardWidth() + 'px'"
        >
        </cstc-movie-card>
        <div
          *ngIf="leadItemsCnt()!==0&&leadItemsCnt()>0&&!readmore&&filmsList.length-leadItemsCnt()>0&&!searchSvc.search()"
         class="movie-card">
          <a href="#" class="movie-next-page" (click)="showMore($event)">
              <p class="movie-name">и еще
                <ng-container [ngPlural]="filmsList.length-leadItemsCnt()">
                  <ng-template ngPluralCase="=0">{{filmsList.length-leadItemsCnt()}} фильмов</ng-template>
                  <ng-template ngPluralCase="=1">{{filmsList.length-leadItemsCnt()}} фильм</ng-template>
                  <ng-template ngPluralCase="few">{{filmsList.length-leadItemsCnt()}} фильма</ng-template>
                  <ng-template ngPluralCase="other">{{filmsList.length-leadItemsCnt()}} фильмов</ng-template>
                </ng-container>
              </p>
              <svg class="more-movie" width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 14.002H27" stroke="white" stroke-width="4"/>
                  <path d="M15 2.00195L27 13.502L15 25.002" stroke="white" stroke-width="4"/>
              </svg>
              <div class="ellipse"></div>
          </a>
        </div>
    </div>
    }@else {
      <div
        [ngClass]="{
          'films-list': isWideScreen(),
          'films-list-mobile': (isWideScreen() === false)
        }"
        [style.grid-template-columns]="(selection.is_future_date===true)&&isWideScreen() ? 'repeat(auto-fit, minmax(188px,' + cardWidth() + 'px))' : ''"
      >
        @for (item of loadedCards; track $index) {
          <cstc-movie-card-skeleton [style.max-width]="cardWidth() + 'px'"/>
        }
      </div>
      
    }
  </ng-container>
</ng-container>
