import { registerLocaleData, ViewportScroller } from '@angular/common';
import { NgModule, LOCALE_ID } from '@angular/core';
import localeRu from '@angular/common/locales/ru';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { environment } from '../environments/environment';
import { SharedModule } from './modules/shared/shared.module';
import { Router, Scroll, Event, NavigationEnd, RouterEvent, NavigationSkipped } from '@angular/router';
import { filter, pairwise } from 'rxjs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MetrikaModule } from 'ng-yandex-metrika';
import { AngularYandexMapsModule, YaConfig } from 'angular8-yandex-maps';
import { APP_PLATFORM, APP_PLATFORM_VERSION, APP_PLATFORM_STORE } from './services/platform/platform.service';
// import { HtmlWithUrlPipe } from './pipes/html-with-url.pipe';
registerLocaleData(localeRu);
export const mapConfig: YaConfig = {
  apikey: 'c6e30b9b-47bb-4b45-a94c-f659d2815f3e',
  lang: 'ru_RU',
};
@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    // HtmlWithUrlPipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    AngularYandexMapsModule.forRoot(mapConfig),
    MetrikaModule.forRoot(
      {
        id: environment.ymId,
        webvisor: true,
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        ecommerce:"dataLayer"}
    ),
    HammerModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "ru-RU" },
    {
      provide: 'BASE_URL',
      useFactory: () => environment.baseUrl,
      deps: [],
    },
    { provide: APP_PLATFORM, useValue: environment.platform },
    { provide: APP_PLATFORM_VERSION, useValue: environment.version },
    { provide: APP_PLATFORM_STORE, useValue: environment.store },
    {
      provide: 'DOCS_FILES',
      useFactory: () => environment.docs,
      deps: [],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private router: Router, private viewportScroller: ViewportScroller) {
    // Disable automatic scroll restoration to avoid race conditions
    this.viewportScroller.setHistoryScrollRestoration('manual');

    this.handleScrollOnNavigation();
  }
  /**
   * When route is changed, Angular interprets a simple query params change as "forward navigation" too.
   * Using the pairwise function allows us to have both the previous and current router events, which we can
   * use to effectively compare the two navigation events and see if they actually change route, or only
   * the route parameters (i.e. selections stored in query params).
   *
   * Related to: https://github.com/angular/angular/issues/26744
   */
  private handleScrollOnNavigation(): void {
    this.router.events.pipe(
      // Event | RouterEvent due to separation in Angular update
      filter((e: Event | RouterEvent): e is Scroll => e instanceof Scroll),
      pairwise(),
      filter(([previous, current]) =>
        current.routerEvent instanceof NavigationEnd || current.routerEvent instanceof NavigationSkipped)
    ).subscribe((e: [Scroll, Scroll]) => {
      const previous = e[0];
      const current = e[1];
      if (current.position) {
        // Backward navigation
        this.viewportScroller.scrollToPosition(current.position);
      } else if (current.anchor) {
        // Anchor navigation
        this.viewportScroller.scrollToAnchor(current.anchor);
      } else {
        // Check if routes match, or if it is only a query param change
        // As NavigationSkipped does not have urlAfterRedirects, we need to add a condition
        if (current.routerEvent instanceof NavigationEnd && previous.routerEvent instanceof NavigationEnd) {
          if (this.getBaseRoute(previous.routerEvent.urlAfterRedirects) !== this.getBaseRoute(current.routerEvent.urlAfterRedirects)) {
            // Routes don't match, this is actual forward navigation
            // Default behavior: scroll to top
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }
      }
    });
  }

  private getBaseRoute(url: string): string {
    // return url without query params
    return url.split('?')[0];
  }
}
