<div class="month-holder" >
  <div class="date-holder" *ngFor="let month of datesParsed()">
    <div class="label">
      <span>
        {{ month.month |toDate| date:'LLLL'}}
      </span>
    </div>
    <div class="items">
      <button
        *ngFor="let day of month.dates"
        type="button"
        class="movie-date"
        (click)="toggleDate($event,day)"
        [ngClass]="{active: selectedDate()&&selectedDate()===day}"
      >
      <span class="movie-date-day">{{day |toDate| date:'dd'}}</span>
      <span class="movie-date-week">{{day|toDate| date:'E'}}</span>
    </button>
    </div>
  </div>
</div>
<!-- <div class="title-divider"></div> -->
