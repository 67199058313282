<ng-container *ngIf="banners() as banners">
  <owl-carousel-o [options]="options()">
    @for (slide of banners; track $index) {
      <ng-template carouselSlide>
        <a href="#" urlHandler="{{slide.url}}">
          @if (isWide()) {
            <img [src]="slide.media.desktop">
          } @else {
            <img [src]="slide.media.mobile">
          }
        </a>
      </ng-template>
    }
  </owl-carousel-o>
</ng-container>
