@if (dates()) {
  <div class="schedule-filters">
    <cstc-date [dates]="dates()"></cstc-date>
</div>
}
<article>
  <div class="cinema-schedule-list">
    <ng-container *ngIf="filtering()===false;else loading">
      <div class="schedule-row" *ngFor="let row of schedule()">
        <cstc-schedule-cinema-card class="schedule-row-cinema" *ngIf="row.theatre" [cinema]="row.theatre" [formats]="row.formats"></cstc-schedule-cinema-card>
        <cstc-schedule-film-card class="schedule-row-film" *ngIf="row.film" [film]="row.film" [formats]="row.formats"></cstc-schedule-film-card>
      </div>
    </ng-container>
    <ng-template #loading>
      <cstc-cinema-schedule></cstc-cinema-schedule>
    </ng-template>
  </div>
</article>
