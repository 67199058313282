<div class="event-item">
    <p class="event-item-date" *ngIf="event.relevant_from&&event.relevant_from!==null">{{event.relevant_from}}</p>
    <a [routerLink]="['/page', event.id]">
        <div class="event-img">
            <img [src]="event.image?event.image:'/assets/img/placeholders/event_placeholder.png'" alt="">
            <div class="event-img-overlay"></div>
        </div>
        <div class="event-item-txt">
            <h3>{{event.title}}</h3>
            <p>{{event.description_short}}</p>
        </div>
    </a>
</div>
