<article>
    <div class="film-list">
        @for (item of loadedCards; track $index) {
            <div class="movie-img" [style.max-width]="cardWidth() + 'px'">
                <div class="poster-wrapper">
                </div>
                <div class="movie-category"></div>
            </div>
        }
    </div>
</article>
