<article class="events-list" #list>
    @for (item of loadedCards; track $index) {
        <div class="event-card-wrapper" >
            <div class="event-card">
                <div class="img"></div>
                <div class="text"></div>
            </div>
        </div>
    }
</article>
