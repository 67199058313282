import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFilterPipe'
})
export class DateFilterPipe implements PipeTransform {
  transform(value: string) {
    const date = new Date(value);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() + 1);
    const result = (new DatePipe("ru-RU")).transform(value, 'dd MMMM');


    if (date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate())
       return "Сегодня, "+result;
     else if (date.getFullYear() === yesterday.getFullYear() && date.getMonth() == yesterday.getMonth() && date.getDate() == yesterday.getDate())
       return "Завтра, "+result;
    else{
      return result;
    }
 }
}
