<article class="movie-stock" *ngIf="items$|async as stockItem">
  <h2>
    <a [routerLink]="['/stocks']">
      <ng-container *ngIf="isWideScreen$|async; else shotTitle">
        <span>Акции в наших кинотеатрах</span>
      </ng-container>
      <ng-template #shotTitle>
        <span>Наши акции</span>
      </ng-template>
    </a>
  </h2>
  <div class="title-divider"></div>
  <section class="stock-card-list" #list
    [style.grid-template-columns]="stockItem.length < per_page ?
    'repeat(auto-fit, minmax(311px,' + (list.offsetWidth - (per_page - 1)*32)/4 + 'px))' :
    'repeat(auto-fit, minmax(311px, 1fr))'"
  >
      <cstc-stock-card *ngFor="let item of stockItem" [item]="item" 
        [style.max-width]="stockItem.length < per_page ? 
        (list.offsetWidth - (per_page - 1)*32)/4 + 'px' : ''"
      ></cstc-stock-card>
  </section>
</article>
