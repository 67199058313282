import { Component, DestroyRef, HostBinding, inject, OnInit, signal } from '@angular/core';
import { LoaderComponent } from 'src/app/modules/shared/components/loader/loader.component';
import { DataService } from 'src/app/services/data/data.service';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { FilmsSelectionComponent } from '../../shared/films-selection/films-selection.component';
import { StockNewSectionComponent } from '../../shared/stock-new-section/stock-new-section.component';
import { NewsNewSectionComponent } from '../../shared/news-new-section/news-new-section.component';
import { BannerComponent } from '../../shared/banner/banner.component';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { MovieCardSkeletonComponent } from 'src/app/modules/shared/components/skeleton-loader/movie-card-skeleton/movie-card-skeleton.component';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LoaderComponent,
    FilmsSelectionComponent,
    StockNewSectionComponent,
    NewsNewSectionComponent,
    BannerComponent,
    MovieCardSkeletonComponent,
  ]
})
export class HomeComponent implements OnInit {
  state = inject(CstcStore);
  platformSvc = inject(PlatformService);
  dataSvc = inject(DataService);
  router = inject(Router);
  destroyRef = inject(DestroyRef);

  loading = this.dataSvc.loading;

  isMobileApp = this.platformSvc.isMobileApp;
  isStandalone = this.state.isStandalone;

  isFirstLoad = signal(true);

  @HostBinding('class')
  get hostClass() {
    return {
      'mobileApp': this.isMobileApp(),
      'isStandalone': this.isStandalone()
    }
  }

  ngOnInit(): void {
    const isVisited = sessionStorage.getItem('visited');

    if (!isVisited) {
      sessionStorage.setItem('visited', 'true');
      this.isFirstLoad.set(true);
    } else {
      this.isFirstLoad.set(false);
    }
  }
}
