import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Theatre } from 'src/app/services/schedule/schedule.model';


@Component({
  selector: 'cstc-cinema-result',
  templateUrl: './cinema-result.component.html',
  styleUrls: ['./cinema-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterModule
  ]
})
export class CinemaResultComponent implements OnInit {
  @Input() cinema!: Theatre;

  constructor() { }

  ngOnInit(): void {
  }

}
