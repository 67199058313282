import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnInit, inject, input } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { filter, take } from 'rxjs';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { ScheduleService } from 'src/app/services/schedule/schedule.service';
import { BannerComponent } from '../../shared/banner/banner.component';
import { ScheduleComponent } from '../../shared/schedule/schedule.component';
import { CinemaInfoComponent } from './components/cinema-info/cinema-info.component';
import { CinemaInfoSkeletonComponent } from 'src/app/modules/shared/components/skeleton-loader/cinema-info-skeleton/cinema-info-skeleton.component';
import { DateSkeletonComponent } from 'src/app/modules/shared/components/skeleton-loader/date-skeleton/date-skeleton.component';
import { CinemaScheduleComponent } from 'src/app/modules/shared/components/skeleton-loader/cinema-schedule/cinema-schedule.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  templateUrl: './cinema.component.html',
  styleUrls: ['./cinema.component.scss'],
  providers: [
    ScheduleService
  ],
  standalone: true,
  imports: [
    CommonModule,
    BannerComponent,
    CinemaInfoComponent,
    ScheduleComponent,
    CinemaInfoSkeletonComponent,
    DateSkeletonComponent,
    CinemaScheduleComponent,
  ]
})
export class CinemaHallComponent implements OnInit {
  platformSvc = inject(PlatformService);
  destroyRef = inject(DestroyRef);
  private scheduleSvc = inject(ScheduleService);
  private titleService = inject(Title);
  private meta = inject(Meta);
  openMap = false;
  toggleMapBtn() {
    this.openMap = !this.openMap;
  }
  isMobileApp = this.platformSvc.isMobileApp;
  error$ = this.scheduleSvc.error$;
  loading$ = this.scheduleSvc.loading$;
  theatre$ = this.scheduleSvc.theatre$;
  filtering = this.scheduleSvc.filtering;
  loading = this.scheduleSvc.loading;
  id = input.required<number>();

  ngOnInit(): void {
    this.scheduleSvc.init('theatre',this.id());
    this.theatre$.pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(data => typeof data !== 'undefined'),
      take(1)
    ).subscribe((data) => {
      if (data !== false) {
        this.titleService.setTitle(data.name);
      }
    });
  }
}
