import { HttpClient } from '@angular/common/http';
import { Component, OnInit, inject, signal, DestroyRef, HostBinding } from '@angular/core';
import { BehaviorSubject, Subscription, delay, fromEvent } from 'rxjs';
import { PageListService } from 'src/app/services/page-list/page-list.service';
import { PageData } from 'src/app/services/page/page.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LoaderComponent } from 'src/app/modules/shared/components/loader/loader.component';
import { BannerComponent } from '../../shared/banner/banner.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StockCardComponent } from '../../shared/stock-card/stock-card.component';
import { ButtonComponent } from 'src/app/modules/shared/components/button/button.component';
import { StockCardsSkeletonComponent } from 'src/app/modules/shared/components/skeleton-loader/stock-cards-skeleton/stock-cards-skeleton.component';
import { DataService } from 'src/app/services/data/data.service';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    LoaderComponent,
    StockCardComponent,
    BannerComponent,
    ButtonComponent,
    StockCardsSkeletonComponent,
  ],
  providers: [
    PageListService,
  ]
})
export class StocksComponent implements OnInit {
  pageListSvc = inject(PageListService);
  state = inject(CstcStore);
  http = inject(HttpClient);
  dataSvc = inject(DataService);
  destroyRef = inject(DestroyRef);
  loading = this.dataSvc.loading;
  loadMoreItems = signal(false);
  items$: BehaviorSubject<PageData[]> = new BehaviorSubject<PageData[]>([]);
  loading$ = new BehaviorSubject(true);
  error$ = new BehaviorSubject(false);
  category = 1;//Акции
  per_row: number = 50;
  per_page: number = 50;
  page: number = 1;
  totalItems: number = 0;
  resizeSubscription: Subscription | undefined;
  @HostBinding('class.isStandalone') hostClass() {
    return this.state.isStandalone();
  }
  getListWidth() {
    const element = document.getElementsByClassName('stock-card-list');
  }

  ngOnInit(): void {
    this.resizeSubscription = fromEvent(window, 'resize').pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      this.updatePerPage(window.innerWidth);
      this.fetchData(); // Fetch data whenever per_row changes
    });

    this.updatePerPage(window.innerWidth);
    this.fetchData();
    this.getListWidth();
  }
  updatePerPage(screenWidth: number) {
    if (screenWidth < 1380 && screenWidth >= 1040) {
      this.per_row = 3;
    } else if (screenWidth < 1040) {
      this.per_row = 2;
    } else {
      this.per_row = 4;
    }
  }
  fetchData() {
    // this.loading$.next(true);
    this.dataSvc.setLoading(true);
    this.pageListSvc.getNews(this.per_page, this.page, this.category).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(({ data, totalCount }) => {
      // this.loading$.next(false);
      this.dataSvc.setLoading(false);
      if (!Array.isArray(data)) {
        this.error$.next(true);
      } else {
        this.totalItems = totalCount;
        this.items$.next(data);
      }
    });
  };

  showMoreItems() {
    // this.loading$.next(true);
    this.loadMoreItems.set(true);
    this.page++;
    this.pageListSvc.getNews(this.per_page, this.page, this.category).pipe(
      delay(2000)
    ).subscribe({
      next: ({ data, totalCount }) => {
        // this.loading$.next(false);
        this.loadMoreItems.set(false);
        if (!data || !Array.isArray(data)) {
          this.error$.next(true);
        } else {
          this.totalItems = totalCount;
          const currentItems = this.items$.getValue() || [];
          this.items$.next([...currentItems, ...data]);
        }
      },
      error: err => {
        console.error("Error fetching next page:", err);
        // this.loading$.next(false);
        this.loadMoreItems.set(false);
        this.error$.next(true);
      }
    });
  }
}
