<div class="cinema-banner" [class.mobileApp]="isMobileApp()">
	<div class="img"></div>
</div>
<article class="cinema-about">
	<div class="cinema-about-title">
		<h2></h2>
	</div>
	<div class="cinema-location-info"></div>
	<div class="cinema-options"></div>
</article>
