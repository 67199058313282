<div class="cinema-info">
    <p class="title">кинотеатр</p>
    <div class="cinema-block">
        <div class="img-holder">
            <img [src]="cinema.image?cinema.image:'/assets/img/placeholders/cinema_item_placeholder.jpg'" alt="">
            <div class="img-overlay"></div>
        </div>
        <div class="text-holder">
            <p class="name"><a [routerLink]="['/cinema', cinema.id]">{{cinema.name}}</a></p>
            <p class="address">{{cinema.address}}</p>
            <p class="subway">{{cinema.metro}}</p>
        </div>
    </div>
</div>
