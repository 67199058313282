@if (loading()===false) {
  <cstc-films-selection
    [selection]="{
      is_future_date: false,
      on_sale: true
    }"
  ></cstc-films-selection>
  <cstc-films-selection
    class="future"
    [title]="'Скоро в прокате'"
    [selection]="{
      is_future_date: true
    }"
  ></cstc-films-selection>

  <!-- <div class="movie-set-wrapper" *ngIf="isWideScreen$ | async">
    <img class="movie-set-bg" src="assets/img/Rectangle48.jpg" alt="">
    <article class="movie-set">
      <h2><a href="#">Кино к 8 марта</a></h2>
          <div class="title-divider"></div>
          <div class="movie-set-list">
              <cstc-movie-card *ngFor="let item of todayItemsBig | slice:5:8" [item]="item" [type]="'medium'"></cstc-movie-card>
          </div>
  </article>
</div> -->
<cstc-stock-new-section></cstc-stock-new-section>
<cstc-news-new-section></cstc-news-new-section>
<cstc-banner type="bottom"></cstc-banner>
}
@else {
  @if (isFirstLoad()===false) {
    <cstc-movie-card-skeleton />
  }@else {
    <cstc-loader/>
  }
}
