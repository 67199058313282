import { Component, Input, OnInit, OnDestroy, ChangeDetectionStrategy, inject } from '@angular/core';
import { BehaviorSubject, Observable, takeWhile } from 'rxjs';
import { NewsCardItem } from 'src/app/models/news-card.model';
import { PageListService } from 'src/app/services/page-list/page-list.service';
import { PageData } from 'src/app/services/page/page.model';
import { NewsCardComponent } from '../news-card/news-card.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CstcStore } from 'src/app/store/state.store';


@Component({
  selector: 'cstc-news-new-section',
  templateUrl: './news-new-section.component.html',
  styleUrls: ['./news-new-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NewsCardComponent
  ],
  providers: [
    PageListService
  ]
})
export class NewsNewSectionComponent implements OnInit, OnDestroy {
  state = inject(CstcStore);
  private pageListSvc = inject(PageListService);
  alive = true;
  category = 2;//Новости(События)
  per_page: number = 3;
  page: number = 1;
  totalItems: number = 0;
  items$ = new BehaviorSubject<PageData[] | undefined>(undefined);
  loading$ = new BehaviorSubject(true);
  error$ = new BehaviorSubject(false);
  isWideScreen = this.state.isWideScreen;

  ngOnInit(): void {
    this.pageListSvc.getNews(this.per_page,this.page,this.category).pipe(
      takeWhile(()=>this.alive),
    ).subscribe(({data, totalCount}) => {
      this.loading$.next(false);
      if(!Array.isArray(data)) {
        this.error$.next(true);
      } else {
        this.totalItems = totalCount;
        this.items$.next(data);
      }
    });
  }
  ngOnDestroy(): void {
    this.alive = false;
  }
}
