import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cinemasFilter',
  standalone: true
})
export class CinemasFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, attributeName: string = 'name'): any[] {
    if (!items) { return []; }
    if (!searchText || typeof searchText !== 'string') { return items; }
    searchText = searchText.toLowerCase();
    return items.filter( it => {
      return it[attributeName].replace(/ё/g, 'е').toLowerCase().includes(searchText.replace(/ё/g, 'е'));
    });
  }
}
