import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { SessionPrice } from '../../../../models/session.model';
import { SessionService } from '../../../../services/session/session.service';

@Component({
  selector: 'cstc-price-legend',
  templateUrl: './price-legend.component.html',
  styleUrls: ['./price-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceLegendComponent implements OnInit {
  @Input() price!: SessionPrice[];
  constructor(
    private sessionSvc: SessionService
  ) { }

  ngOnInit(): void {
  }
  findColor(price: SessionPrice) {
    return this.sessionSvc.getColorByCode(price.color);
  }
}
