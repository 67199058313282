import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { BarInfo } from 'src/app/models/bar-info.model';

@Component({
  selector: 'cstc-bar-result',
  templateUrl: './bar-result.component.html',
  styleUrls: ['./bar-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class BarResultComponent implements OnInit {
  @Input() bar!: BarInfo[];

  constructor() { }

  ngOnInit(): void {
  }

}
