<article>
    <div class="header-wrapper">
        <a [routerLink]="['/']" class="logo-holder">
            @if(isWideScreen()){
                <img class="logo-img" src="assets/img/logo.svg" alt="">
            }@else {
                <img class="logo-mini-img" src="assets/img/logo-mini.svg" alt="">
            }
        </a>
        <div class="btn-holder">
            <a [routerLink]="['/films']" routerLinkActive="active" cstc-button="primary" class="primary-btn">билеты</a>
        </div>
        <a class="menu-burger" (click)="openMenu($event)">
            <span iconName="menu"></span>
        </a>
        <ng-container [ngTemplateOutlet]="menu" *ngIf="isWideScreen()"></ng-container>
        <div class="user-block">
            <div class="user-location-holder">
                @if (isWideScreen()) {
                    <div class="btn-holder">
                        <!-- <a (click)="toggleCityList()" class="secondary-btn"><span>{{currentCityName$ | async}}</span><div class="btn-bg"></div></a> -->
                        <a href="#" (click)="openCityList($event)" cstc-button="secondary">{{currentCity()?.name}}</a>
                    </div>
                }@else {
                    <div class="geotag-holder">
                        <a href="#" (click)="openCityList($event)" cstc-button="secondary">
                            <span iconName="geotag"></span>
                        </a>
                    </div>
                }
            </div>
            <a href="#" class="user-profile-link">
                <img src="assets/img/user.png" alt="user">
                <img src="assets/img/user-hover.png" alt="user">
            </a>
        </div>
    </div>
    <ng-container *ngIf="cityList().length>0">
        <div class="location-list" trapScroll [ngClass]="{'active': cityService.cityListOpened()}">
            <a [routerLink]="['/']" class="logo-holder mini">
                <img class="logo-mini-img" src="assets/img/logo-mini.svg" alt="">
            </a>
            <div class="location-holder">
                <a
                *ngFor="let item of cityList()"
                (click)="selectCity($event,item.id)"
                class="location-item"
                [ngClass]="{active: (city$|async)===item.id}"
                >{{item.name}}</a>
            </div>
            <a (click)="closeCityList($event)" class="location-close">
                <span iconName="close"></span>
            </a>
        </div>
    </ng-container>
</article>
<ng-container [ngTemplateOutlet]="menu" *ngIf="!isWideScreen()"></ng-container>
<cstc-backdrop *ngIf="menuOpen() || cityService.cityListOpened()" (click)="closeCityList($event)" trapScroll></cstc-backdrop>
<ng-template #menu>
    <nav class="main-nav-list-wrapper" trapScroll [ngClass]="{'opened': menuOpen()}">
        @if (!isWideScreen()) {
            <a [routerLink]="['/']" class="logo-holder mini">
                <img class="logo-mini-img" src="assets/img/logo-mini.svg" alt="">
            </a>
        }
        <div class="btn-holder">
            <a [routerLink]="['/films']" routerLinkActive="active" cstc-button="primary" class="primary-btn">билеты</a>
        </div>
        <ul class="main-nav-list">
            <li class="main-nav-item" *ngFor="let item of menuItems">
                <a [routerLink]="item.url" routerLinkActive="active" (click)="closeCityList($event)" class="nav-link">{{ item.name }}</a>
            </li>
        </ul>
        <a href="#" (click)="openCityList($event)" cstc-button="secondary">{{currentCity()?.name}}</a>
        <a (click)="closeCityList($event)" class="location-close">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.0005 2L2.00049 24M2.00049 2L24.0005 24" stroke="white" stroke-width="4"/>
            </svg>
        </a>
    </nav>
</ng-template>
