import { CommonModule, registerLocaleData } from '@angular/common';
import { LOCALE_ID, APP_INITIALIZER, importProvidersFrom, Optional, provideExperimentalZonelessChangeDetection, inject, Provider, EnvironmentProviders } from '@angular/core';
import localeRu from '@angular/common/locales/ru';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { provideHttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { provideRouter, withInMemoryScrolling, withViewTransitions, withComponentInputBinding } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularYandexMapsModule } from 'angular8-yandex-maps';
import { PlatformService } from './services/platform/platform.service';
import { routes } from './app.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MetrikaModule } from 'ng-yandex-metrika';
import { NgxMaskConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { provideServiceWorker } from '@angular/service-worker';
import { ENVIRONMENT, ENVIRONMENT_CONFIG } from './tokens/environment-config.token';
import { UpdateService } from './services/update.service';
import { MAP_CONFIG, mapConfig } from './tokens/map-config.token';
import { EnvironmentConfigResolved } from './models/app.model';

registerLocaleData(localeRu);

export const environmentConfig = {
  provide: ENVIRONMENT_CONFIG,
  useValue: ENVIRONMENT
};

export function initializeApp(
  config: EnvironmentConfigResolved,
  updateService?: UpdateService
) {
  return () => {
    if (config.pwa) {
      updateService?.startUpdateCheck();
    }
  };
}

const maskConfig: Partial<NgxMaskConfig> = {
  validation: false,
};

export const appConfig = {
  providers: [
    importProvidersFrom(
      AngularYandexMapsModule.forRoot(mapConfig),
      BrowserModule, BrowserAnimationsModule, CommonModule, ReactiveFormsModule, FormsModule, BrowserAnimationsModule,
      HammerModule,
      MetrikaModule.forRoot([
        {
          id: environment.ymId,
          webvisor: true,
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          ecommerce: "dataLayer"
        }
      ]),
    ),
    { provide: MAP_CONFIG, useValue: mapConfig },
    provideExperimentalZonelessChangeDetection(),
    provideHttpClient(),
    provideRouter(routes, withViewTransitions(), withInMemoryScrolling({
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    }), withComponentInputBinding()),
    provideEnvironmentNgxMask(maskConfig),
    { provide: LOCALE_ID, useValue: "ru-RU" },
    environmentConfig,
    PlatformService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ENVIRONMENT_CONFIG, [new Optional(), UpdateService]],
      multi: true,
    },
    provideServiceWorker('ngsw-worker.js', {
      enabled: !!environment.pwa, // ✅ Ensure Service Worker is only registered if PWA is enabled
      registrationStrategy: environment.pwa?.swRegistrationStrategy ?? 'registerWhenStable:30000'
    })
  ],
};
