<div class="cinema-poster-holder">
    <a [routerLink]="['/cinema', cinema.id]">
        <div class="cinema-poster-wrapper">
            <img [src]="cinema.media.image.small?cinema.media.image.small:'/assets/img/placeholders/cinema_item_placeholder.jpg'" alt="" class="cinema-poster">
        </div>
        <div class="cinema-poster-overlay"></div>
    </a>
</div>
<div class="cinema-wrapper">
    <div class="cinema-about-wrapper">
        <div class="cinema-about">
            <h2>
                <a [routerLink]="['/cinema', cinema.uid]">{{cinema.name.length>maxNameLength?cinema.name.substring(0,maxNameLength)+'...':cinema.name}}</a>
            </h2>
            <div class="cinema-location-info">
                <p *ngIf="cinema.address">{{cinema.address}}</p>
            </div>
        </div>
        <ul *ngIf="cinema.options" class="cinema-description">
            <li *ngFor="let item of cinema.options">{{item}}</li>
        </ul>
    </div>
    <div class="time-wrapper" [ngClass]="{filmTimeWrapper: true}">
        <cstc-format-sheldule *ngFor="let item of formats" [format]="item" class="format-schedule"></cstc-format-sheldule>
    </div>
</div>
