import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackdropComponent } from './components/backdrop/backdrop.component';
import { ButtonComponent } from './components/button/button.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from './components/loader/loader.component';
import { MinToMhPipe } from 'src/app/pipes/min-to-mh.pipe';
import { DateFilterPipe } from 'src/app/pipes/date-filter.pipe';
import { CinemaScheduleComponent } from './components/skeleton-loader/cinema-schedule/cinema-schedule.component';
import { RippleDirective } from './directives/ripple/ripple.directive';
import { TrapScrollDirective } from './directives/trap-scroll.directive';
import { OrderFilmComponent } from './components/order-film/order-film.component';
import { PriceLegendComponent } from './components/price-legend/price-legend.component';
import { ToDatePipe } from 'src/app/pipes/to-date.pipe';
import { ScrollDetectionDirective } from './directives/scroll-detection.directive';
import { AdjustFontDirective } from './directives/adjust-font.directive';
import { SafeAreaDirective } from './directives/safe-area.directive';
import { UrlHandlerDirective } from './directives/urlHandler.directive';


@NgModule({
  declarations: [
    BackdropComponent,
    ButtonComponent,
    ToggleComponent,
    LoaderComponent,
    DateFilterPipe,
    MinToMhPipe,
    ToDatePipe,
    CinemaScheduleComponent,
    RippleDirective,
    TrapScrollDirective,
    OrderFilmComponent,
    PriceLegendComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ScrollDetectionDirective,
    AdjustFontDirective,
    SafeAreaDirective,
    UrlHandlerDirective,
  ],
  exports: [
    BackdropComponent,
    ButtonComponent,
    ToggleComponent,
    LoaderComponent,
    DateFilterPipe,
    ToDatePipe,
    MinToMhPipe,
    CinemaScheduleComponent,
    RippleDirective,
    OrderFilmComponent,
    TrapScrollDirective,
    PriceLegendComponent,
    AdjustFontDirective,
    SafeAreaDirective,
    UrlHandlerDirective,
  ]
})
export class SharedModule { }
