import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit, inject, signal, } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Subject, } from 'rxjs';
import { BackdropComponent } from 'src/app/modules/shared/components/backdrop/backdrop.component';
import { ButtonComponent } from 'src/app/modules/shared/components/button/button.component';
import { TrapScrollDirective } from 'src/app/modules/shared/directives/trap-scroll.directive';
import { CityService } from 'src/app/services/city/city.service';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  selector: 'cstc-footer-mobile',
  templateUrl: './footer-mobile.component.html',
  styleUrls: ['./footer-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    BackdropComponent,
    TrapScrollDirective,
    ButtonComponent,
  ]
})
export class FooterMobileComponent implements OnInit, OnDestroy {
  state = inject(CstcStore);
  cityService = inject(CityService);
  cityList = this.cityService.cityList;
  currentCity = this.cityService.currentCity;
  private destroy$ = new Subject<void>();
  openMenu = signal(false);
  isStandalone = this.state.isStandalone;

  @HostBinding('class') get hostClass() {
    return {
      'isStandalone': this.isStandalone(),
    }
  }

  openHiddenMenu(event: MouseEvent) {
    event.preventDefault();
    this.openMenu.set(true);
  }
  closeHiddenMenu(event: MouseEvent) {
    event.preventDefault();
    this.openMenu.set(false);
  }
  openCityList(event: MouseEvent) {
    event?.preventDefault();
    this.cityService.openCityList();
    this.closeHiddenMenu(event);
  }
  closeCityList(event: MouseEvent) {
    event?.preventDefault();
    this.cityService.closeCityList();
  }
  selectCity(event: MouseEvent, id: number) {
    event.preventDefault();
    this.cityService.setCity(id);
    this.cityService.saveSelectedActiveCity(id);
    this.cityService.closeCityList();
  }

  constructor(private cdr: ChangeDetectorRef) { }
  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
