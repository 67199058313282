@if (loading()===false) {
    <ng-container *ngIf="cinema$|async as cinemaList">
        <article>
            <div class="mobile-title" *ngIf="!isWideScreen()">
                <div class="title">
                    <h1>Кинотеатры</h1>
                    <a href="#" cstc-button="secondary" (click)="openMap($event)">на карте</a>
                </div>
                <div class="hidden-map-wrapper" [ngClass]="{opened: opened}">
                    <div class="hidden-map" *ngIf="currentCityCoords() as center">
                        <div class="ya-map">
                            <!--  [zoom]="12" -->
                            <ya-map [center]="[center.latitude, center.longitude]">
                                <ya-placemark
                                *ngFor="let item of cinemaList"
                                [geometry]="[item.latitude, item.longitude]"
                                (yaclick)="goToCinema(item.uid)"
                                ></ya-placemark>
                            </ya-map>
                        </div>
                    </div>
                </div>
                <div class="cinema-phone-holder" *ngFor="let cinema of cinemaList | slice: 0:1">
                    <a href="#" class="cinema-phone" *ngIf="cinema.phone_support">{{cinema.phone_support}}</a>
                    <p>{{cinema.working_hours}}</p>
                </div>
            </div>
            <div class="cinema-list-wrapper">
                <div class="cinemas-list" [class.minimum]="cinemaList.length <= 2">
                    <cstc-cinema-list-item *ngFor="let cinema of cinemaList" [cinema]="cinema"></cstc-cinema-list-item>
                </div>
                <ng-container *ngIf="isWideScreen()">
                    <aside class="map-wrapper" *ngIf="currentCityCoords() as center">
                        <!-- <div class="ya-map"></div> -->
                        <div class="ya-map">
                            <!--  [zoom]="12" -->
                            <ya-map [center]="[center.latitude, center.longitude]">
                                <ya-placemark
                                *ngFor="let item of cinemaList"
                                [geometry]="[item.latitude, item.longitude]"
                                (yaclick)="goToCinema(item.uid)"
                                ></ya-placemark>
                            </ya-map>
                        </div>
                        <div class="cinema-phone-holder" *ngFor="let cinema of cinemaList | slice: 0:1">
                            <a href="#" class="cinema-phone">{{cinema.phone_support}}</a>
                            <p>{{cinema.working_hours}}</p>
                        </div>
                    </aside>
                </ng-container>
            </div>
        </article>
    </ng-container>
}
@else {
    <cstc-cinema-skeleton/>
}
<cstc-banner type="bottom"></cstc-banner>
