import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Film, VideoSrc } from '../schedule/schedule.model';

@Injectable({
  providedIn: 'root'
})
export class TrailerPopupService {
  trailerUrl?:string;
  coverUrl?: string;
  isOpened$ = new BehaviorSubject(false);
  constructor() { }
  open(event: MouseEvent,item:Film) {
    event.preventDefault();
    const video = this.getTrailerData(item);
    this.trailerUrl = video?.video||'';
    this.coverUrl = video?.thumb||'';
    this.isOpened$.next(true);
  }
  getTrailerData(item:Film) {
    return item.media.trailer||{
      video: item.media.video
    }
  }
  close() {
    this.trailerUrl = '';
    this.coverUrl = '';
    this.isOpened$.next(false);
  }
}
